import { useFormik } from "formik"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
    Button,
    Form,
    Col,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap"
import useApi from "common/ApiMiddlewere"
import { toast } from "react-hot-toast"

function ExpensesModel({ modal, toggle, ExpensesDetails, getTableData }) {
    const [isLoading, setisLoading] = useState(false)
    const { postApi, putApi } = useApi()

    useEffect(() => {
        validation.handleReset()
    }, [modal])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            Date: ExpensesDetails
                ? ExpensesDetails.Date.slice(0, 10)
                : new Date().toISOString().slice(0, 10),
            Name: ExpensesDetails ? ExpensesDetails.Name : "",
            Amount: ExpensesDetails ? ExpensesDetails.Amount : "",
            PaymentMode: ExpensesDetails ? ExpensesDetails.PaymentMode : 1,
            Details: ExpensesDetails ? ExpensesDetails.Details : "",
        },
        validationSchema: Yup.object({
            Date: Yup.string().required("The date is required"),
            Name: Yup.string().required("The name is required"),
            Amount: Yup.string().required("The amount is required"),
            Details: Yup.string().required("The details is required"),
        }),
        onSubmit: values => {
            setisLoading(true)

            values.PaymentMode = parseInt(values.PaymentMode)
            let Api = ExpensesDetails ? putApi : postApi
            let url = ExpensesDetails ? "expenses/update" : "expenses/create"

            if (ExpensesDetails) {
                values.Id = ExpensesDetails.Id
            }
            values.Amount = parseFloat(values.Amount)
            const tostMessage = ExpensesDetails
                ? "Update expenses Successfully"
                : "Add expenses Successfully"


            Api(url, values).then(({ data, status }) => {
                setisLoading(false)
                if (status === 200) {
                    setisLoading(false)
                    toggle()
                    getTableData()
                    validation.handleReset()
                    toast.success(tostMessage, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                } else {
                    setisLoading(false)
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })
        },
    })


    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="small"
                style={{ width: "350px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h4">
                        {ExpensesDetails ? "Update expenses" : "Add expenses"}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <FormGroup className="mb-2">
                                <Label htmlFor="formrow-InputState" className="required">
                                    Name
                                </Label>
                                <Input
                                    type="text"
                                    bsSize="sm"
                                    id="formrow-InputState"
                                    className="form-control"
                                    name="Name"
                                    placeholder="Name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.Name || ""}
                                    invalid={
                                        validation.touched.Name && validation.errors.Name
                                            ? true
                                            : false
                                    }
                                />

                                {validation.touched.Name && validation.errors.Name ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.Name}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <div className="row">
                                    <div className="col-md-6">
                                        <Label htmlFor="validation1Custom01">Date</Label>
                                        <Input
                                            name="Date"
                                            placeholder="date placeholder"
                                            type="date"
                                            // min={new Date().toISOString().slice(0, 10)}
                                            dateFormat="DD/M/YYYY"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.Date}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Label htmlFor="formrow-InputState" className="required">
                                            Amount
                                        </Label>
                                        <Input
                                            type="text"
                                            bsSize="sm"
                                            id="formrow-InputState"
                                            classAmount="form-control"
                                            name="Amount"
                                            placeholder="0.00"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            onKeyPress={handleKeyPress}
                                            value={validation.values.Amount || ""}
                                            invalid={
                                                validation.touched.Amount && validation.errors.Amount
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.Amount && validation.errors.Amount ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.Amount}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                            </FormGroup>
                            <FormGroup className="mb-2">
                                <Label htmlFor="formrow-InputState" className="required">
                                    PaymentMode
                                </Label>
                                <Input
                                    type="select"
                                    bsSize="sm"
                                    id="formrow-InputState"
                                    className="form-control"
                                    name="PaymentMode"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.PaymentMode || ""}
                                    invalid={
                                        validation.touched.PaymentMode &&
                                            validation.errors.PaymentMode
                                            ? true
                                            : false
                                    }
                                >
                                    <option value={1}>Cash</option>
                                    <option value={2}>Bank</option>
                                </Input>
                                {validation.touched.PaymentMode &&
                                    validation.errors.PaymentMode ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.PaymentMode}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>

                            <FormGroup className="mb-2">
                                <Label htmlFor="validationCustom03" className="required">
                                    Details
                                </Label>
                                <Input
                                    name="Details"
                                    placeholder="type your Details here"
                                    type="textarea"
                                    row={2}
                                    className="form-control-sm"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.Details || ""}
                                    invalid={
                                        validation.touched.Details && validation.errors.Details
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.Details && validation.errors.Details ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.Details}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>

                            <div className="text-end">

                                {isLoading ? (
                                    <button type="button" className="btn btn-primary btn-sm">
                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                        Loading
                                    </button>
                                ) : (
                                    <Button
                                        color="primary"
                                        className="btn btn-primary btn-sm "
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </div>
    )
}

export default ExpensesModel
