import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import CategoriesTable from "./CategoryTable"

function CategoryTable() {
  document.title = `Categories | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()
  const { deleteApi, getApi, postApi, putApi } = useApi()
  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState("")
  const [tableData, setTableData] = useState([])
  const [CategoryDetails, setCategoryDetails] = useState({
    Id: "",
    Name: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [screenLoad, setScreenLoad] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [totalRecords, setTotalRecords] = useState(0)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)

  const filterData = textFilter => {
    setSearchText(textFilter == undefined ? '' : textFilter)
  }

  // category list api
  const categoryApi = () => {
    setScreenLoad(true)
    setIsLoading(true)
    getApi("Category/list", searchText).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
        setTotalRecords(data.length)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setScreenLoad(false)
    })
  }

  // category list api useEffect
  useEffect(() => {
    categoryApi()
  }, [searchText])

  // toggle handlers
  const toggle = () => {

    setModal(!modal)
    validation.handleReset()
  }

  // cancle handler
  const handleCancle = () => {
    toggle()
  }

  // Delete category handler
  const handleDelete = categoryId => {
    setScreenLoad(true)
    if (categoryId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this category permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          deleteApi(`Category/delete?Id=${categoryId}`).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== categoryId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("successfully deleted", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Category",
                  text: data,
                })
              }
              setScreenLoad(false)
            }
          )
        } else {
          setScreenLoad(false)
        }
      })
    }
  }


  // Update category handler
  const handleEditCategory = categorydata => {
    setScreenLoad(true)
    if (categorydata) {
      getApi(`Category/details?id=${categorydata.Id}`).then(({ data, status }) => {
        if (status === 200) {
          setCategoryDetails({
            Id: data.Id,
            Name: data.Name,
          });
          setFields(data.LstDynamicControl)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
        setScreenLoad(false)
      })
    }
    setIsEdit(true)
    setModal(!modal)
    setEditData(categorydata)
  }


  // Create category handler
  const handleCategoryClick = () => {
    setFields([])
    setCategoryDetails({
      Id: "",
      Name: "",
    })
    setIsEdit(false)
    toggle()
  }

  // Categories Validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Name: isEdit ? editData.Name : "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("The name is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)

      if (values) {
        isEdit
          ? putApi("Category/update", {
            id: CategoryDetails.Id,
            name: values.Name,
            lstDynamicControl: fields
          }).then(({ data, status }) => {
            setformSumitLoading(false)
            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("successfully updated", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              categoryApi()
              validation.handleReset()
            }
          })
          : postApi("Category/create", {
            name: values.Name,
            lstDynamicControl: fields,
          }).then(({ data, status }) => {
            setformSumitLoading(false)

            if (status == 400) {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            } else {
              toast.success("successfully created", {
                style: {
                  fontSize: "15px",
                },
              })
              toggle()
              categoryApi()
              validation.handleReset()
            }
          })
      }
    },
  })

  const handleCodeChange = e => {
    validation.setFieldTouched("Code")
    validation.validateForm()
  }

  // Category table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Category",
        accessor: "Name",
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEditCategory(row.original)}
                ></i>
                <span className="mytext">Edit</span>
              </li>

              <li className="mytooltip">
                <i
                  id={`deletetooltip${row.original.Id}`}
                  className="fs-6 fas fa-trash-alt btn-delete"
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )




  const [fields, setFields] = useState([]);


  const addField = () => {
    const newFields = [...fields, { FieldName: '', FieldType: "TEXT", DynamicControlId: 0 }];
    setFields(newFields);
  };

  const deleteField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  const handleChange = (index, e) => {
    const newFields = [...fields];
    newFields[index].FieldName = e.target.value;
    setFields(newFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Access all the form details from the 'fields' state and perform the necessary operations
  };


  return (
    <div className="page-content">
      <div className="container-fluid">
        {screenLoad && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <CategoriesTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isCategoryList={true}
              handleCategoryClick={handleCategoryClick}
              customPageSize={10}
              className="custom-header-css"
              filterData={filterData}
              isLoading={isLoading}
              TotalRecords={totalRecords}
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="small"
              style={{ width: "350px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {isEdit ? "Update Category" : "Create Category"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <FormGroup className="mb-2">
                      <Label htmlFor="validationCustom03" className="required">
                        Name
                      </Label>
                      <Input
                        name="Name"
                        placeholder="Category Name"
                        type="text"
                        className="form-control-sm"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.Name || ""}
                        invalid={
                          validation.touched.Name && validation.errors.Name
                            ? true
                            : false
                        }
                      />
                      {validation.touched.Name && validation.errors.Name ? (
                        <FormFeedback type="invalid">
                          {validation.errors.Name}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>

                    {/* <form onSubmit={handleSubmit}> */}
                      {
                        fields.length ? <Label htmlFor="validationCustom03" className="required">
                          Sub category
                        </Label> : null
                      }

                      {fields.map((field, index) => (
                        <div key={index} className="d-flex mb-1">

                          <Input
                            type="text"
                            id="name"
                            name="untyped-input"
                            className="form-control-sm"
                            placeholder="Enter Your Name"
                            value={field.FieldName}
                            onChange={(e) => handleChange(index, e)}
                          />

                          {field.IsNotDelete ?
                            (<button
                              style={{ cursor: "not-allowed", backgroundColor: "#f46a6a94", border: "#f46a6a94", color: "white" }}
                              type="button"
                              className="btn btn-danger btn-sm ms-1 mytooltip"
                            >
                              <i className="fas fa-trash  font-size-12"></i>
                              <span className="mytext text-nowrap">This category is already use</span>
                            </button>)
                            :
                            (<button
                              type="button"
                              className="btn btn-danger btn-sm ms-1 mytooltip"
                              onClick={() => deleteField(index)}
                            >
                              <i className="fas fa-trash  font-size-12"></i>
                              <span className="mytext">Delete</span>
                            </button>)
                          }


                        </div>
                      ))}
                      <button type="button" onClick={addField} className="btn btn-primary btn-sm mt-1">
                        Add
                      </button>
                      {/* <button type="submit">Submit</button> */}
                    {/* </form> */}

                    {/* {(formRows || []).map((formRow, key) => (
                      <>
                        <Row key={key}>
                          <Col md={12} className="mb-3 d-flex">

                            <Input
                              type="text"
                              id="name"
                              name="untyped-input"
                              className="form-control-sm"
                              placeholder="Enter Your Name"
                            />

                            <button
                              type="button"
                              className="btn btn-primary btn-sm ms-1"
                              onClick={() => onAddFormRow(formRow.id)}
                            >
                              <i className="fas fa-plus-circle  font-size-12"></i>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm ms-1"
                              onClick={() => onDeleteFormRow(formRow.id)}
                            >
                              <i className="fas fa-trash  font-size-12"></i>
                            </button>
                          </Col>


                        </Row>

                      </>
                    ))} */}

                    {/* <Button
                      onClick={() => {
                        handleAddRowNested()
                      }}
                      color="success"
                      className="btn btn-sm mt-2"
                    >
                      Add Lable
                    </Button> */}

                    <div className="text-end">
                      {isEdit ? (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm"
                          onClick={handleCancle}
                        >
                          Cancle
                        </Button>
                      ) : (
                        <Button
                          color="secondary"
                          className="btn btn-primary me-2 btn-sm "
                          type="reset"
                        >
                          Reset
                        </Button>
                      )}

                      <>
                        {formSumitLoading ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                            Loading
                          </button>
                        ) : (
                          <Button
                            color="primary"
                            className="btn btn-primary btn-sm "
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                      </>
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
CategoryTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default CategoryTable
