import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    Form,
    Modal,
    ModalBody,
    Input,
    UncontrolledTooltip,
    FormGroup,
    ModalHeader,
    Button,
    Label,
    FormFeedback,
    Row,
    Col,
} from "reactstrap"
import * as Yup from "yup"
import DeleteModel from "common/deleteModel/deleteModel"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useFormik } from "formik"
import ItemsTable from "./ItemsTable"
import { dateFormat } from "pages/Common"
import CreateItemModel from "pages/CreateItemModal"

function Items() {
    document.title = `Items | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()
    const { deleteApi, getApi, postApi, putApi } = useApi()
    // useStates
    const [modal, setModal] = useState(false)
    const [tableData, setTableData] = useState([])
    const [itemEditData, setItemEditData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [screenLoad, setScreenLoad] = useState(false)
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [searchText, setSearchText] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [displayStart, setDisplayStart] = useState(1)
    const [itemId, setItemId] = useState(0)
    const [isError, setIsError] = useState(false)

    const filterData = textFilter => {
        setSearchText(textFilter == undefined ? '' : textFilter)
    }

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }

    // Create Account handler
    const handleItemClick = () => {
        toggle()
        setIsEdit(false)
    }

    // Account list api
    const ItemsApi = () => {
        setScreenLoad(true)
        setIsLoading(true)
        postApi("Items/list", {
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.List)
                setTotalRecords(data.TotalRecords)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
    }

    // Account list api useEffect
    useEffect(() => {
        ItemsApi()
    }, [rowsPerPage, searchText, displayStart])

    // toggle handlers
    const toggle = () => {
        setModal(!modal)
        validation.handleReset()
    }

    // cancle handler
    const handleCancle = () => {
        toggle()
        setIsEdit(false)
    }

    // Delete Account handler
    const handleDelete = ItemId => {
        setScreenLoad(true)
        if (ItemId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this Item permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`Items/delete?id=${ItemId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== ItemId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("successfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Category",
                                    text: data,
                                })
                            }
                            setScreenLoad(false)
                        }
                    )
                } else {
                    setScreenLoad(false)
                }
            })
        }
    }

    const handleEdit = ItemId => {
        setScreenLoad(true)
        setItemId(ItemId)
        getApi(`Items/details?id=${ItemId}`).then(({ data, status }) => {
            if (status === 200) {
                setItemEditData(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setScreenLoad(false)
        })
        setIsEdit(true)
        setModal(!modal)
    }



    // Items Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: isEdit ? itemEditData.Name : "",
            ItemCode: isEdit ? itemEditData.ItemCode : "",
            MRP: isEdit ? itemEditData.MRP : 0,
            CGST: isEdit ? itemEditData.CGST : 0,
            SGST: isEdit ? itemEditData.SGST : 0,
            OpeningStock: isEdit ? itemEditData.OpeningStock : 0,
            IGST: isEdit ? itemEditData.IGST : 0,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("The name is required"),
            ItemCode: Yup.string().required("The item code is required"),
        }),
        onSubmit: values => {

            if (values.CGST && !values.SGST) {
                setIsError(true);
                return false
            }

            if (values.SGST && !values.CGST) {
                setIsError(true)
                return false
            }

            setformSumitLoading(true)
            if (values) {
                setIsError(false)
                setScreenLoad(true)
                isEdit
                    ? putApi("Items/update", {
                        id: itemId,
                        name: values.Name,
                        mrp: parseFloat(values.MRP) || 0,
                        itemCode: values.ItemCode || "",
                        cgst: parseFloat(values.CGST) || 0,
                        sgst: parseFloat(values.SGST) || 0,
                        igst: parseFloat(values.IGST) || 0,
                    }).then(({ data, status }) => {
                        setformSumitLoading(false)
                        if (status == 400) {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        } else {
                            toast.success("successfully updated", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            ItemsApi()
                            validation.handleReset()
                        }
                        setScreenLoad(false)
                    })
                    :

                    postApi("Items/create", {
                        name: values.Name,
                        mrp: parseFloat(values.MRP) || 0,
                        openingStock: parseFloat(values.OpeningStock) || 0,
                        itemCode: values.ItemCode || "",
                        cgst: parseFloat(values.CGST) || 0,
                        sgst: parseFloat(values.SGST) || 0,
                        igst: parseFloat(values.IGST) || 0,
                    }
                    ).then(({ data, status }) => {
                        setformSumitLoading(false)
                        if (status == 200) {
                            toast.success("successfully created", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            ItemsApi()
                            validation.handleReset()
                        } else {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                        setScreenLoad(false)
                    })
            }
        },
    })

    const handleGstPer = (CGST, SGST, IGST) => {
        validation.setFieldValue("CGST", CGST)
        validation.setFieldValue("SGST", SGST)

        if (parseFloat(CGST) > 0 || parseFloat(SGST) > 0) {
            validation.setFieldValue("IGST", '')
        } else {
            validation.setFieldValue("CGST", CGST ? CGST : '')
            validation.setFieldValue("SGST", SGST ? SGST : '')
            validation.setFieldValue("IGST", IGST ? IGST : '')
        }
    }

    const handleIGSTPer = (CGST, SGST, IGST) => {
        validation.setFieldValue("IGST", IGST)
        if (parseFloat(IGST) > 0) {
            validation.setFieldValue("CGST", '')
            validation.setFieldValue("SGST", '')
        }
        else {
            validation.setFieldValue("CGST", CGST ? CGST : '')
            validation.setFieldValue("SGST", SGST ? SGST : '')
            validation.setFieldValue("IGST", IGST ? IGST : '')
        }
    }

    // Category table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Item Code",
                accessor: "ItemCode",
                width: "7%",
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Barcode No.",
                accessor: "BarcodeNo",
                width: "9%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`Opening Stock`}
                    >
                        Op. Stock
                    </div>
                ),
                accessor: "OpeningStock",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original?.OpeningStock}
                        </div>
                    )
                },
                width: "7%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        MRP
                    </div>
                ),
                accessor: "MRP",
                width: "7%",
                textAlign: "right",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.MRP || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        CGST
                    </div>
                ),
                accessor: "CGST",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <span style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.CGST}%
                        </span>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        SGST
                    </div>
                ),
                accessor: "SGST",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <span style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.SGST}%
                        </span>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        IGST
                    </div>
                ),
                accessor: "IGST",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <span style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            {row.original.IGST}%
                        </span>
                    )
                },
            },
            {
                Header: "Created Date",
                accessor: "CreatedOn",
                width: "10%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.CreatedOn == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.CreatedOn.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },

            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit "
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => handleEdit(row.original.Id)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    id={`deletetooltip${row.original.Id}`}
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )

    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                {screenLoad && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <ItemsTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isAccountList={true}
                            handleItemClick={handleItemClick}
                            customPageSize={20}
                            className="custom-header-css"
                            filterData={filterData}
                            isLoading={isLoading}
                            TotalRecords={totalRecords}
                            pagePerSize={pagePerSize}
                        />

                        <CreateItemModel
                            modal={modal}
                            toggle={toggle}
                            isEdit={isEdit}
                            ItemsApi={ItemsApi}
                            flag={"item"}
                            itemEditData={itemEditData}
                            setIsEdit={setIsEdit}
                            itemId={itemId}
                        />

                        {/* <Modal
                            isOpen={modal}
                            toggle={toggle}
                            centered={true}
                            size="small"
                            style={{ width: "450px" }}
                        >
                            <div className="modal-content">
                                <ModalHeader toggle={toggle} tag="h5">

                                    {isEdit ? "Update Items" : " Create Items"}
                                </ModalHeader>
                                <ModalBody>
                                    <Form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                        onReset={e => {
                                            e.preventDefault
                                            validation.handleReset()
                                            return false
                                        }}
                                    >
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03" className="required">
                                                        Name
                                                    </Label>
                                                    <Input
                                                        name="Name"
                                                        placeholder="Name"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Name || ""}
                                                        invalid={
                                                            validation.touched.Name && validation.errors.Name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Name && validation.errors.Name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Item Code
                                                    </Label>
                                                    <Input
                                                        name="ItemCode"
                                                        placeholder="Item Code"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.ItemCode || ""}
                                                        invalid={
                                                            validation.touched.ItemCode &&
                                                                validation.errors.ItemCode
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.ItemCode &&
                                                        validation.errors.ItemCode ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.ItemCode}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                    >
                                                        MRP
                                                    </Label>
                                                    <Input
                                                        name="MRP"
                                                        placeholder="0.00"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onKeyPress={handleKeyPress}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.MRP}
                                                        invalid={
                                                            validation.touched.MRP &&
                                                                validation.errors.MRP
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.MRP &&
                                                        validation.errors.MRP ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.MRP}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom01"
                                                        className="form-label"
                                                    >
                                                        Opening Stock
                                                    </Label>
                                                    <Input
                                                        name="OpeningStock"
                                                        className="form-control-sm"
                                                        placeholder="0.00"
                                                        type="text"
                                                        disabled={isEdit}
                                                        onChange={validation.handleChange}
                                                        onKeyPress={handleKeyPress}
                                                        onBlur={validation.handleBlur}
                                                        onFocus={(e) => { e.target.select() }}
                                                        value={validation.values.OpeningStock}
                                                        invalid={
                                                            validation.touched.OpeningStock &&
                                                                validation.errors.OpeningStock
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.OpeningStock &&
                                                        validation.errors.OpeningStock ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.OpeningStock}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03">
                                                        CGST(%)
                                                    </Label>
                                                    <Input
                                                        name="CGST"
                                                        placeholder="0.00"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onKeyPress={handleKeyPress}
                                                        onChange={e => handleGstPer(e.target.value, validation.values.SGST, validation.values.IGST)}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.CGST}
                                                        invalid={
                                                            isError && validation.values.SGST && !validation.values.CGST || validation.values.CGST > 100
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {isError && validation.values.SGST && !validation.values.CGST || validation.values.CGST > 100 && isError ? (
                                                        <FormFeedback type="invalid">
                                                            {'CGST required'}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03">
                                                        SGST(%)
                                                    </Label>
                                                    <Input
                                                        name="SGST"
                                                        placeholder="0.00"
                                                        type="text"
                                                        onKeyPress={handleKeyPress}
                                                        className="form-control-sm"
                                                        onChange={e => handleGstPer(validation.values.CGST, e.target.value, validation.values.IGST)}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.SGST}
                                                        invalid={
                                                            isError && validation.values.CGST && !validation.values.SGST || validation.values.SGST > 100
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {isError && validation.values.CGST && !validation.values.SGST || validation.values.SGST > 100 && isError ? (
                                                        <FormFeedback type="invalid">
                                                            {"SGST reuired"}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03">
                                                        IGST(%)
                                                    </Label>
                                                    <Input
                                                        name="IGST"
                                                        placeholder="0.00"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onKeyPress={handleKeyPress}
                                                        onChange={e => handleIGSTPer(validation.values.CGST, validation.values.SGST, e.target.value)}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.IGST}
                                                        invalid={
                                                            validation.touched.IGST && validation.errors.IGST || validation.values.IGST > 100
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.IGST && validation.errors.IGST || validation.values.IGST > 100 ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.IGST}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className="text-end">
                                            {isEdit ? (
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary me-2 btn-sm"
                                                    onClick={handleCancle}
                                                >
                                                    Cancle
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="secondary"
                                                    className="btn btn-primary me-2 btn-sm "
                                                    type="reset"
                                                >
                                                    Reset
                                                </Button>
                                            )}

                                            <>
                                                {formSumitLoading ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                        Loading
                                                    </button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="btn btn-primary btn-sm "
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                            </>
                                        </div>
                                    </Form>
                                </ModalBody>
                            </div>
                        </Modal> */}
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
Items.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Items
