import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { financeAmountRequest } from "store/addtocart/action"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import profile from "assets/images/loginpage/loginvector.png"
import logo from "assets/images/logo.svg"
import appConfig from "Api/app.config"
import { routes } from "routes/path"
import Logo from '../../assets/images/logo/logo.png'
import Raja_Rani_Logo1 from "../../assets/images/Raja_Rani/Raja_Rani.png"
import BackgroundImage from "../../assets/images/Raja_Rani/RajaRani.jpg"
import BackgroundImage1 from "../../assets/images/Raja_Rani/RajaRani_Background.png"
import Raja_Rani_Logo_Img from "../../assets/images/Raja_Rani/Raja_Rani_Logo_Img.png"
import useApi from "common/ApiMiddlewere"
import { useDispatch } from "react-redux"

//Import config

const Login = props => {
  document.title = `RajaRani`
  const dispatch = useDispatch();

  const { getApi, postApi, putApi } = useApi()

  const [error, setError] = useState(null)

  const navigate = useNavigate()

  const [loading, setloading] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Username: "",
      password: "",
    },
    validationSchema: Yup.object({
      Username: Yup.string().required("The username is required"),
      password: Yup.string().required(" The password is required"),
    }),
    onSubmit: async values => {
      setloading(true)
      postApi("User/login", {
        username: values.Username,
        password: values.password,
      }).then(async ({ data, status }) => {
        if (status == 200) {
          localStorage.setItem("authUser", JSON.stringify(data))
          dispatch(financeAmountRequest(false));
          localStorage.setItem("ShopName", data.ShopName)
          setloading(false)
          if (data.RoleName == "Salesman") {
            navigate(routes.available)
          } else if (data.RoleName == "Admin") {
            navigate(routes.userList)
          }
          else {
            navigate(routes.dashboard)
          }
        } else {
          setloading(false)
          setError(await data)
        }
        setloading(false)
      })
    },
  })

  return (
    <React.Fragment>


      <div className="background-container">
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${BackgroundImage1})`,
          }}
        >
          {/* <div className="background-overlay"></div> */}
          <Container>
            <Row
              style={{ height: "100vh" }}
              className="justify-content-center align-items-center"
            >
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="" style={{ backgroundColor: "#eef2f3" }}>
                    <Row className="mb-2">
                      <Col xs={7} className="">
                        <div className="p-4 d-flex justify-content-start align-items-center">
                          <div>
                            <h5 className="" style={{ color: "#394867", marginTop: "5px" }}>Welcome Back !</h5>
                            <img src={Raja_Rani_Logo1} width={120} alt="" className="img-fluid" />
                          </div>

                        </div>
                      </Col>
                      <Col className="col-5  d-flex justify-content-end  align-items-end ">
                        <div className="" style={{ marginRight: "12px" }}>
                          <img src={Raja_Rani_Logo_Img} width={100} alt="" className="img-fluid" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/" className="logo-light-element">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <Label className="form-label required">Username</Label>
                          <Input
                            name="Username"
                            className="form-control"
                            placeholder="Enter Username"
                            type="Username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Username || ""}
                            invalid={
                              validation.touched.Username &&
                                validation.errors.Username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.Username &&
                            validation.errors.Username ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Username}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label required">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                                validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                            validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3 d-grid">
                          {loading ? (
                            <button type="button" className="btn " style={{
                              backgroundColor: "#394867 ",
                              color: "#F1F6F9 ",
                              border: "none "
                            }}>
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <button
                              className="btn"
                              type="submit"
                              style={{
                                backgroundColor: "#394867 ",
                                color: "#F1F6F9 ",
                                border: "none "
                              }}


                            >
                              Log In
                            </button>
                          )}
                        </div>

                        <div className="mt-5 text-center" >
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>


    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
