/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
  FormGroup,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { useLocation, useNavigate } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import Compressor from "compressorjs"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import uploadImage from "../../../assets/images/uploadImage.png"
import { routes } from "routes/path"

function UpadateUser() {
  const { getApi, putApi } = useApi()
  const { state } = useLocation()
  const [userData, setuserData] = useState()
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [isLoading, setisLoading] = useState(false)
  const [screeLoad, setscreeLoad] = useState(true)

  // useStates
  const [fileFlag, setfileFlag] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [imageFlag, setImageFlag] = useState(false)
  const [logoFlag, setLogoFlag] = useState(false)
  const [islogoDelete, setIsLogoDelete] = useState(false)
  const [previewLogoUrl, setPreviewLogoUrl] = useState(null)

  // user details api useEffect
  useEffect(() => {
    setscreeLoad(true)
    setisLoading(true)
    if (state) {
      getApi(`User/user-details?id=${state.Id}`).then(({ data, status }) => {
        if (status === 200) {
          setscreeLoad(false)
          setisLoading(false)
          setuserData(data)
          setPreviewUrl(data?.ProfilePic)
          setPreviewLogoUrl(data?.Logo)
        } else {
          setisLoading(false)
          setscreeLoad(false)
        }
      })
    }
  }, [])

  // user details validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullname: userData ? userData.FullName : "",
      shopname: userData ? userData.ShopName : "",
      email: userData ? userData.Email : "",
      contactNo: userData ? userData.ContactNo : "",
      contactNo2: userData ? userData.ContactNo2 : "",
      username: userData ? userData.Username : "",
      GSTNo: userData ? userData.GSTNo : "",
      PanNo: userData ? userData.PAN : "",
      bank: userData ? userData.BankName : "",
      accountNo: userData ? userData.AccountNo : "",
      ifscCode: userData ? userData.IFSC : "",
      address: userData ? userData.Address : "",
      remark: userData ? userData.Remarks : "",
      profilePic: userData ? userData.ProfilePic : "",
      logo: userData ? userData.Logo : "",
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string()
        .min(2, "Full Name should be at least 2 characters")
        .max(50, "Full Name should not exceed 50 characters")
        .required("Full Name is required"),
      shopname: Yup.string()
        .min(2, "Shop Name should be at least 2 characters")
        .max(50, "Shop Name should not exceed 50 characters")
        .required("Shop Name is required"),
      contactNo: Yup.string()
        .matches(/^[0-9]+$/, "Contact Number should contain only digits")
        .min(10, "Contact Number should be at least 10 digits")
        .max(10, "Contact Number should not exceed 10 digits")
        .required("Contact Number is required"),
      username: Yup.string()
        .min(2, "Username should be at least 2 characters")
        .max(50, "Username should not exceed 50 characters")
        .required("Username is required"),
      address: Yup.string()
        .min(2, "Address should be at least 2 characters")
        .max(100, "Address should not exceed 500 characters")
        .required("Address is required"),
      remark: Yup.string().max(
        1000,
        "Remark should not exceed 1000 characters"
      ),
    }),
    onSubmit: values => {
      setisLoading(true)
      putApi("User/update-user", {
        id: state.Id,
        username: values.username,
        fullName: values.fullname,
        contactNo: values.contactNo,
        shopName: values.shopname,
        contactNo2: values.contactNo2,
        gstNo: values.GSTNo,
        pan: values.PanNo,
        bankName: values.bank,
        accountNo: values.accountNo,
        ifsc: values.ifscCode,
        address: values.address,
        email: values.email,
        remarks: values.remark,
        profilePic: imageFlag ? values.profilePic.split(",")[1] : "",
        deleteProfilePic: fileFlag,
        logo: logoFlag ? values.logo.split(",")[1] : "",
        deleteLogo: islogoDelete,
      }).then(({ data, status }) => {
        setisLoading(false)
        if (status === 200) {
          setImageFlag(false)
          setfileFlag(false)
          setIsLogoDelete(false)
          setLogoFlag(false)
          toast.success("User Update Successfully", {
            style: {
              fontSize: "15px",
            },
          })
          setTimeout(() => {
            navigate(routes.userList)
          }, 1000)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })

  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  // profile pic toBase64 convert handle
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  // profile pic select handle & compressor
  const handleDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setImageFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4,
      success: async compressedResult => {
        setPreviewUrl(URL.createObjectURL(compressedResult))
        validation.setFieldValue("profilePic", await toBase64(compressedResult))
      },
    })
  }

  // profile pic display & set
  const handleclick = () => {
    setfileFlag(true)
    validation.setFieldValue("profilePic", null)
    setPreviewUrl(null)
  }


  // logo select handle & compressor
  const handleLogoDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setLogoFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4,
      success: async compressedResult => {
        setPreviewLogoUrl(URL.createObjectURL(compressedResult))
        validation.setFieldValue("logo", await toBase64(compressedResult))
      },
    })
  }

  // profile pic display & set
  const handleLogoClick = () => {
    setIsLogoDelete(true)
    validation.setFieldValue("logo", null)
    setPreviewLogoUrl(null)
  }


  document.title = `User | ${localStorage.getItem("ShopName")}`

  // if (screeLoad) {
  //   return <Loader />
  // }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="row">
                      <Col md="3">
                        <FormGroup>
                          <Dropzone
                            onDrop={handleDrop}
                            accept="image/*"
                            multiple={false}
                            id="profilePic"
                            name="profilePic"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div className="d-flex flex-column align-items-center text-center p-2 py-0">
                                  {previewUrl ? (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle pe-auto"
                                        style={{ cursor: "pointer" }}
                                        width="130px"
                                        height="130px"
                                        src={previewUrl}
                                        {...getRootProps()}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle"
                                        style={{ cursor: "pointer" }}
                                        width="130px"
                                        src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                        {...getRootProps()}
                                      />
                                    </>
                                  )}
                                </div>
                                {previewUrl && (
                                  <div className="d-flex justify-content-center align-item-center ">
                                    <button
                                      onClick={() => handleclick()}
                                      type="button"
                                      className="btn btn-sm btn-soft-danger"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </Dropzone>
                          {validation.touched.profilePic &&
                            validation.errors.profilePic ? (
                            <div className="error">
                              {validation.errors.profilePic}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="fullname" className="required">
                                Full Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                name="fullname"
                                id="fullname"
                                placeholder="Full Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fullname}
                                invalid={
                                  validation.touched.fullname &&
                                    validation.errors.fullname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.fullname &&
                                validation.errors.fullname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fullname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="shopname" className="required">
                                Shop Name
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                disabled
                                name="shopname"
                                id="shopname"
                                placeholder="Shop Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shopname}
                                invalid={
                                  validation.touched.shopname &&
                                    validation.errors.shopname
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.shopname &&
                                validation.errors.shopname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.shopname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>

                          <Col md={3}>
                            <FormGroup>
                              <Label for="contactNo" className="required">
                              Contact No.1
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                name="contactNo"
                                maxLength="10"
                                id="contactNo"
                                placeholder="Contact Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contactNo}
                                onKeyPress={handleKeyPress}
                                invalid={
                                  validation.touched.contactNo &&
                                    validation.errors.contactNo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.contactNo &&
                                validation.errors.contactNo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.contactNo}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="contactNo" className="">
                                Contact No.2
                              </Label>
                              <Input
                                type="text"
                                className="form-control-sm"
                                name="contactNo2"
                                maxLength="10"
                                id="contactNo"
                                placeholder="Contact Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contactNo2}
                                onKeyPress={handleKeyPress}
                                invalid={
                                  validation.touched.contactNo2 &&
                                    validation.errors.contactNo2
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.contactNo2 &&
                                validation.errors.contactNo2 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.contactNo2}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Row>
                            <Col md={3}>
                              <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="form-control-sm"
                                  placeholder="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Label for="username" className="required">
                                  Username
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control-sm"
                                  name="username"
                                  id="username"
                                  placeholder="username"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.username}
                                  invalid={
                                    validation.touched.username &&
                                      validation.errors.username
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.username &&
                                  validation.errors.username ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.username}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Label for="username" className="">
                                  GST No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control-sm"
                                  name="GSTNo"
                                  id="GSTNo"
                                  placeholder="GST Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.GSTNo}
                                  invalid={
                                    validation.touched.GSTNo &&
                                      validation.errors.GSTNo
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.GSTNo &&
                                  validation.errors.GSTNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.GSTNo}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Label for="username" className="">
                                  PAN No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control-sm"
                                  name="PanNo"
                                  id="PanNo"
                                  placeholder="PAN Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.PanNo}
                                  invalid={
                                    validation.touched.PanNo &&
                                      validation.errors.PanNo
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.PanNo &&
                                  validation.errors.PanNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.PanNo}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="email">Bank Name</Label>
                                <Input
                                  type="text"
                                  name="bank"
                                  id="bank"
                                  className="form-control-sm"
                                  placeholder="Bank Name"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.bank}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="username" className="">
                                  Account No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control-sm"
                                  name="accountNo"
                                  id="accountNo"
                                  placeholder="Account Number"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.accountNo}
                                  invalid={
                                    validation.touched.accountNo &&
                                      validation.errors.accountNo
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.accountNo &&
                                  validation.errors.accountNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.accountNo}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="username" className="">
                                  IFSC Code
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control-sm"
                                  name="ifscCode"
                                  id="ifscCode"
                                  placeholder="IFSC Code"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.ifscCode}
                                  invalid={
                                    validation.touched.ifscCode &&
                                      validation.errors.ifscCode
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.ifscCode &&
                                  validation.errors.ifscCode ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.ifscCode}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="address" className="required">
                                  Address
                                </Label>
                                <Input
                                  type="textarea"
                                  className="form-control-sm"
                                  name="address"
                                  id="address"
                                  row={2}
                                  placeholder="Enter your address"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address}
                                  invalid={
                                    validation.touched.address &&
                                      validation.errors.address
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.address &&
                                  validation.errors.address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="remark">Remarks</Label>
                                <Input
                                  type="textarea"
                                  className="form-control-sm"
                                  name="remark"
                                  id="remark"
                                  row={2}
                                  placeholder="Enter your remarks"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.remark}
                                  invalid={
                                    validation.touched.remark &&
                                      validation.errors.remark
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.remark &&
                                  validation.errors.remark ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.remark}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </div>

                    {userData?.RoleName !== "Salesman" ?
                      <Row>
                        <Col md={3}></Col>
                        <Col md={5}>
                          <FormGroup>
                            <Label>Logo</Label>
                            <Dropzone
                              onDrop={handleLogoDrop}
                              onClick={handleLogoDrop}
                              accept="image/*"
                              multiple={false}
                              id="logo"
                              name="logo"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <>
                                  <div className="d-flex">
                                    {previewLogoUrl ? (
                                      <>
                                        <div className="position-relative overflow-hidden product-image">
                                          <input {...getInputProps()} />
                                          <img
                                            style={{
                                              cursor: "pointer",
                                              border: "1px solid #ced4da",
                                              borderRadius: "3px",
                                            }}
                                            width="125px"
                                            height="100px"
                                            src={previewLogoUrl}
                                            {...getRootProps()}
                                          />
                                          <div className="delete-button">
                                            <button
                                              className="btn btn-sm btn-danger mb-1 me-1"
                                              onClick={() => handleLogoClick()}
                                              type="button"
                                              style={{ height: "22px", width: "19px" }}
                                            >
                                              <i className="fas fa-trash-alt" style={{
                                                fontSize: "11px !important", display: "flex", alignContent: "center",
                                                alignItems: "center", justifyContent: "center"
                                              }}></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <input {...getInputProps()} />

                                        <div
                                          style={{
                                            width: "125px",
                                            height: "100px",
                                            border: "1px solid #ced4da",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          <img
                                            className="img-fluid"
                                            style={{}}
                                            src={uploadImage}
                                            {...getRootProps()}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                            </Dropzone>
                          </FormGroup>

                        </Col>
                        <Col md="4" className="text-end" style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                          <Button
                            color="secondary"
                            className="btn btn-primary me-2"
                            type="button"
                            onClick={() => {
                              navigate(routes.userList)
                            }}
                          >
                            Cancle
                          </Button>
                          {isLoading ? (
                            <button type="button" className="btn btn-primary">
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              className="btn btn-primary"
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col md="12" className="text-end ">
                          <Button
                            color="secondary"
                            className="btn btn-primary me-2"
                            type="button"
                            onClick={() => {
                              navigate(routes.userList)
                            }}
                          >
                            Cancle
                          </Button>
                          {isLoading ? (
                            <button type="button" className="btn btn-primary">
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              className="btn btn-primary"
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpadateUser
