import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { connect } from "react-redux"
import { Link, json, useLocation } from "react-router-dom"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/logo.svg"
import logoSm from "../../assets/images/logo/logo.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { dateFormat } from "pages/Common"
import { ToastBar, Toaster, toast } from "react-hot-toast"

const Header = props => {

  const path = useLocation()
  const [flagForExpireDate, setflagForExpireDate] = useState(false)
  const [expireDate, setexpireDate] = useState()
  const [error, seterror] = useState()
  const [internetConnection, setinternetConnection] = useState(true)


  useEffect(() => {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.remove("sidebar-enable")
    }

    function checkExpiration(expirationDate) {
      var currentDate = new Date()
      var remainingDays = Math.floor(
        (expirationDate - currentDate) / (1000 * 60 * 60 * 24)
      )

      if (remainingDays >= 1 && remainingDays <= 15) {

        return true
        // Perform your desired action here
      }
      return false
    }

    // Example usage:
    let { ProductExpiry } = JSON.parse(localStorage.getItem("authUser"))
    var expirationDate = new Date(ProductExpiry)
    // let a =

    if (checkExpiration(expirationDate)) {
      setflagForExpireDate(true)
      setexpireDate(ProductExpiry)
      const pageContentElements = document.getElementsByClassName("page-content");
      // document.getElementsByClassName("page-content")[0].className =
      //   "page-content-warning"
      if (pageContentElements.length > 0) {
        pageContentElements[0].className = "page-content-warning";
      }
    }
  }, [path]);





  useEffect(() => {

    const handleOnlineStatusChange = () => {
      setinternetConnection(true)
    };

    const handleOfflineStatusChange = () => {
      setinternetConnection(false)

    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOfflineStatusChange);

    const originalFetch = window.fetch;

    window.fetch = function (...args) {
      const [url, options] = args;

      return originalFetch.apply(this, args)
        .then(response => {
          seterror(false)
          return response;
        })
        .catch(error => {
          // document.getElementsByClassName("page-content")[0].className =
          //   "page-content-warning"
          seterror(true)
        });
    };
    // Cleanup: Restore the original fetch method
    return () => {
      window.fetch = originalFetch;
    };
  }, [error]);



  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>

      {
        internetConnection || !error ? <Toaster
          position="top-center"
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
            style: {
              // marginTop:"40px",
              background: "#363636",
              color: "#fff",
            },
            // Default options for specific types
            success: {
              duration: 5000,
              theme: {
                primary: "green",
                secondary: "black",
              },
            },
          }}
        >
          {t => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <i
                      className="mdi mdi-close"
                      onClick={() => toast.dismiss(t.id)}
                      style={{
                        cursor: "pointer",
                        color: "",
                        fontSize: "20px",
                        marginTop: "2px",
                      }}
                    />
                    // <button className="btn btn-rounded btn-danger" onClick={() => toast.dismiss(t.id)}>X</button>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster> : null
      }

      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm px-3">
                  <img src={logoSm} alt="" height="32" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>



        {flagForExpireDate ? (
          <div className="d-flex align-items-center">
            <div
              className="alert alert-danger alert-font-size"
              style={{
                marginBottom: "0px",
                padding: "5px 12px",
                backgroundColor: "#fc0b0e",
                border: "none",
                borderRadius: "0px",
                width: "100%",
                color: "#fff",
                fontWeight: "500",
              }}
              role="alert"
            >
              • Your plan is scheduled to expire on {dateFormat(expireDate, "dd-MM-yyyy")}.
            </div>
          </div>
        ) : null}

        {!internetConnection || error ? (
          <div className="d-flex align-items-center">
            <div
              className="alert alert-danger alert-font-size"
              style={{
                marginBottom: "0px",
                padding: "5px 12px",
                backgroundColor: "#556ee6",
                border: "none",
                borderRadius: "0px",
                width: "100%",
                color: "#fff",
                fontWeight: "500",
              }}
              role="alert"
            >
              {/* {isConnected && error ? "Please kindly contact the administrator for further assistance" : "Cheack internet connectivity"} */}
              {!internetConnection ? "You are offline, Check your internet connection" : "Kindly contact the administrator for further assistance"}
            </div>
          </div>
        ) : null}
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
