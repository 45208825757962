import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import useApi from "common/ApiMiddlewere";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { dateFormat } from "pages/Common";

const CommonModel = ({
    modal,
    toggle,
    BookingandStatus,
    flag,
}) => {
    const { postApi, putApi } = useApi()
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [billAmountData, setBillAmountData] = useState()
    const [discountAmount, setDiscountAmount] = useState(0)
    const [flagForAllDelivery, setflagForAllDelivery] = useState(false)
    const [isRefund, setIsRefund] = useState(false)
    const [validationFlag, setValidationFlag] = useState(false)
    const [paymentMethod, setpaymentMethod] = useState("1")

    // subscription Validations
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: billAmountData ? billAmountData.NetAmount : 0,
            discount: 0,
            advance: billAmountData ? billAmountData.Advance : 0,
            deposit: 0,
            enterAmount: 0,
            advancedMinusRefund: 0,
            month: 6,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: values => {
            if (((flag == "booking" || flag == "delivery") && BookingandStatus.statusId == "2") ? validation.values.deposit < 0 : validation.values.deposit > billAmountData?.Deposit) {
                console.log("yes")
                return false
            }
            if (validation.values.deposit < 0 || validation.values.advance < 0) {
                console.log("yes")
                return false
            }

            if (flag == "cancel" && (validation.values.advance < validation.values.enterAmount)) {
                console.log("yes")
                return false
            }

            if (flag != "cancel" && flag != "cancelItem" && flag != "settle" && flag != "cancelHold" && ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0)) {
                console.log(validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount)
                console.log("yes")
                return false
            }

            if (flag == "settle" && !isRefund && ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) && (validation.values.enterAmount < 0 || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0)) && (validationFlag === false)) {
                console.log("yes")
                return false
            }

            if (flag != "cancel" && flag != "cancelItem" && flag != "settle" && flag != "cancelHold" && (validation.values.enterAmount < 0 || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) && (validationFlag == false))) {
                console.log("yes")
                return false
            }

            if ((flag == "cancelHold") || (flag == "cancelItem") && validation.values.advance - validation.values.enterAmount < 0) {
                console.log("yes")
                return false
            }

            if (isDiscountInvalid()) {
                console.log("yes")
                return false
            }

            if (isRefund && (validation.values.advance - validation.values.enterAmount < 0 ? true : false) && (validationFlag == false)) {
                console.log("yes")
                return false
            }

            if (flag != "cancel" && flag != "cancelItem" && flag != "settle" && flag != "cancelHold" && ((validationFlag == false) &&
                (validation.values.enterAmount < 0 || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0)))) {
                console.log("yes")
                return false
            }

            if (isRefund && (validation.values.advance - validation.values.enterAmount < 0 ? true : false) && (validationFlag == false)) {
                console.log("yes")
                return false
            }

            setformSumitLoading(true)
            if (flag == "settle") {
                putApi("Booking/update-advance", {
                    bookingId: BookingandStatus.bookingId,
                    amount: values.enterAmount || 0,
                    isRefund: (flag == "settle" && isRefund) ? true : false,
                    deposit: values.deposit || 0,
                    status: parseInt(BookingandStatus.statusId),
                    expireDate: null,
                    discount: (parseInt(validation.values.discount ? validation.values.discount : 0) || 0),
                    paymentMode: parseInt(paymentMethod),
                }).then(({ data, status }) => {
                    if (status == 200) {
                        toast.success("Amount change sucessfully", {
                            style: {
                                fontSize: "15px",
                            },
                        })
                        toggle()
                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                    setformSumitLoading(false)
                })
            } else {
                let obj = {
                    bookingId: ((flag == "booking" || (flag == "delivery" && flagForAllDelivery)) || (flag == "cancel" || flag == "cancelHold")) ? BookingandStatus.bookingId : (flag == "cancelItem") ? 0 : 0,
                    bookingItemId: ((flag == "delivery" && flagForAllDelivery)) ? 0 : (flag == "cancelItem") ? BookingandStatus.productId : BookingandStatus.productId,
                    BookingStatus: parseInt(BookingandStatus.statusId)
                }

                putApi("Booking/update-advance", {
                    bookingId: BookingandStatus.bookingId,
                    amount: values.enterAmount || 0,
                    isRefund: (flag == "cancel" || flag == "cancelItem" || flag == "cancelHold") ? true : false,
                    deposit: values.deposit || 0,
                    discount: (parseInt(validation.values.discount ? validation.values.discount : 0) || 0),
                    bookingStatus: parseInt(BookingandStatus.statusId),
                    expireDate: flag == "cancelHold" ? dateFormat(
                        ExpiredDate?.split("T")[0],
                        "yyyy-MM-dd"
                    ) : null,
                    paymentMode: parseInt(paymentMethod),
                }).then(({ data, status }) => {
                    if (status === 200) {
                        putApi("Booking/change-Status", obj).then(({ data, status }) => {
                            if (status === 200) {
                                toast.success("Status change sucessfully", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })

                                toggle()
                            } else {
                                toast.error(data, {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            }
                            setformSumitLoading(false)
                        })

                    } else {
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                    setformSumitLoading(false)
                })


            }
        },
    })

    const sixMonthsLater = new Date();
    sixMonthsLater.setMonth(sixMonthsLater.getMonth() + validation.values.month);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const ExpiredDate = sixMonthsLater.toLocaleDateString(undefined, options);

    useEffect(() => {
        if (BookingandStatus.bookingId) {
            validation.handleReset()
            postApi("Booking/bill-amounts", {
                bookingId: BookingandStatus.bookingId
            }).then(({ data, status }) => {
                if (status === 200) {
                    setBillAmountData(data)
                    setDiscountAmount(data.Discount)
                    setpaymentMethod(data?.PaymentMode == 2 ? "2" : "1");
                }
            });
        }

    }, [flag, BookingandStatus])

    const handleDiscountChange = value => {
        validation.setFieldValue("discount", value)
        setValidationFlag(true)
    }

    const handleEnterAmountChange = value => {
        validation.setFieldValue("enterAmount", value)
        setValidationFlag(false)
    }

    const { discount, amount, advance, enterAmount, deposit } = validation.values;

    const formattedDiscount = (discountAmount + parseInt(discount ? discount : 0)).toLocaleString('en-IN', { currency: 'INR' }) + ".00"

    const DepositAmount = (billAmountData?.Deposit - parseInt(deposit ? deposit : 0)).toLocaleString('en-IN', { currency: 'INR' }) + ".00"

    const isDiscountInvalid = () => {
        const remainingAmount = amount - advance - enterAmount - discount;
        return validationFlag && (discount < 0 || remainingAmount < 0);
    };

    const discountErrorMessage = () => {
        const remainingAmount = amount - advance - enterAmount - discount;
        return remainingAmount < 0 ? "The discount does not exceed the bill amount" : "Discount should not be less than zero";
    };

    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">
                        Bill No: {billAmountData?.BillNo}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            Bill Amount
                                        </Label>
                                        <Input
                                            name="amount"
                                            disabled
                                            placeholder="0.00"
                                            type="text"
                                            className="form-control-sm"
                                            value={validation.values.amount}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">Total Discount: <span style={{ color: "green", fontSize: "13px" }}><span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>₹</span> {formattedDiscount}</span> </Label>
                                        <Input
                                            name="discount"
                                            placeholder="Discount"
                                            type="number"
                                            className="form-control-sm"
                                            onChange={(e) => {
                                                handleDiscountChange(e.target.value);
                                            }}
                                            onKeyPress={handleKeyPress}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.discount}
                                            onFocus={(e) => { e.target.select(); }}
                                            invalid={isDiscountInvalid()}
                                        />
                                        {isDiscountInvalid() && (
                                            <FormFeedback type="invalid">
                                                {discountErrorMessage()}
                                            </FormFeedback>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    {((flag == "booking" || flag == "delivery") && BookingandStatus.statusId == "2") ?
                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03">Deposite</Label>
                                            <Input
                                                name="deposit"
                                                placeholder="0"
                                                type="text"
                                                className="form-control-sm"
                                                value={validation.values.deposit}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                onKeyPress={handleKeyPress}
                                                onFocus={(e) => { e.target.select() }}
                                                invalid={validation.values.deposit < 0}
                                            />
                                            {validation.values.deposit < 0 ? (
                                                <FormFeedback type="invalid">
                                                    Deposit should not be less than zero
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup> :

                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03">Deposite: <span style={{ color: "green", fontSize: "13px" }}><span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>₹</span> {DepositAmount}</span> </Label>
                                            <Input
                                                name="deposit"
                                                placeholder="0"
                                                type="text"
                                                className="form-control-sm"
                                                onKeyPress={handleKeyPress}
                                                value={validation.values.deposit}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                onFocus={(e) => { e.target.select() }}
                                                invalid={validation.values.deposit > billAmountData?.Deposit}
                                            />
                                            {validation.values.deposit > billAmountData?.Deposit ? (
                                                <FormFeedback type="invalid">
                                                    Deposit should not be grater than {billAmountData?.Deposit}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">Advance</Label>
                                        <Input
                                            name="advance"
                                            placeholder="0.00"
                                            type="text"
                                            disabled
                                            className="form-control-sm"
                                            value={validation.values.advance}
                                            invalid={validation.values.advance < 0}
                                        />
                                    </FormGroup>
                                </Col>

                                {flag == "booking" || flag == "delivery" ?
                                    (
                                        <Col md={4}>
                                            <FormGroup className="mb-2">
                                                <Label htmlFor="validationCustom03">
                                                    Receive
                                                </Label>
                                                <Input
                                                    name="enterAmount"
                                                    placeholder="Receive Amount"
                                                    type="number"
                                                    className="form-control-sm"
                                                    // onChange={validation.handleChange}
                                                    onKeyPress={e => {
                                                        if (e.key === '-' || e.key === 'e') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onChange={e => {
                                                        handleEnterAmountChange(e.target.value)
                                                    }}

                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.enterAmount}
                                                    onFocus={(e) => { e.target.select() }}
                                                    invalid={(validation.values.enterAmount < 0 || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) && (validationFlag == false))}

                                                />
                                                {(validation.values.enterAmount < 0 || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0)) && (validationFlag == false) ? (
                                                    <FormFeedback type="invalid">
                                                        {((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) ? "Payble Amount Not grater than Bill Amount" : "Receive should not be less than zero"}
                                                    </FormFeedback>
                                                ) : null}

                                            </FormGroup>
                                        </Col>
                                    )
                                    : (flag == "settle") ? (
                                        <Col md={4}>
                                            <Label htmlFor="validationCustom03"> <div className=" d-flex gap-2">
                                                <input
                                                    type="radio"
                                                    value={false}
                                                    id="customRadioInline1"
                                                    className="form-check-input"
                                                    checked={isRefund == false}
                                                    onClick={() => { setIsRefund(false) }}
                                                />
                                                <Label className="form-check-label font-size-13" style={{ marginRight: "7px" }} htmlFor="customRadioInline1">
                                                    Receive
                                                </Label>

                                                <input
                                                    type="radio"
                                                    value={true}
                                                    id="customRadioInline2"
                                                    className="form-check-input"
                                                    checked={isRefund == true}
                                                    onClick={() => { setIsRefund(true) }}
                                                />
                                                <Label className="form-check-label font-size-13" htmlFor="customRadioInline2">
                                                    Refund
                                                </Label>
                                            </div></Label>

                                            {isRefund ?
                                                <div>
                                                    <Input
                                                        name="enterAmount"
                                                        placeholder="Refund"
                                                        type="number"
                                                        className="form-control-sm"
                                                        // onChange={validation.handleChange}
                                                        onKeyPress={e => {
                                                            if (e.key === '-' || e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            handleEnterAmountChange(e.target.value)
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.enterAmount}
                                                        onFocus={(e) => { e.target.select() }}
                                                        invalid={
                                                            (validation.values.advance - validation.values.enterAmount < 0 ? true : false) && (validationFlag == false)
                                                        }
                                                    />
                                                    {(validation.values.advance - validation.values.enterAmount < 0) && (validationFlag === false) ? <>
                                                        <FormFeedback type="invalid">
                                                            Advance must be greater than or equal refund
                                                        </FormFeedback>

                                                    </> : null}
                                                </div>
                                                :
                                                <div>
                                                    <Input
                                                        name="enterAmount"
                                                        placeholder="Receive Amount"
                                                        type="number"
                                                        className="form-control-sm"
                                                        // onChange={validation.handleChange}
                                                        onKeyPress={e => {
                                                            if (e.key === '-' || e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            handleEnterAmountChange(e.target.value)
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.enterAmount}
                                                        onFocus={(e) => { e.target.select() }}
                                                        invalid={(validationFlag == false) &&
                                                            (validation.values.enterAmount < 0 || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0))}

                                                    />



                                                    {(validation.values.enterAmount < 0 || ((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0)) && (validationFlag === false) ? (
                                                        <FormFeedback type="invalid">
                                                            {((validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount) < 0) ? "Payble Amount Not greater than Bill Amount" : "Receive should not be less than zero"}
                                                        </FormFeedback>
                                                    ) : null}

                                                </div>
                                            }
                                        </Col>
                                    ) :

                                        (
                                            <Col md={4}>
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="validationCustom03">Refund</Label>
                                                    <Input
                                                        name="enterAmount"
                                                        placeholder="Refund"
                                                        type="number"
                                                        className="form-control-sm"
                                                        // onChange={validation.handleChange}
                                                        onKeyPress={e => {
                                                            if (e.key === '-' || e.key === 'e') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={e => {
                                                            handleEnterAmountChange(e.target.value)
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.enterAmount}
                                                        onFocus={(e) => { e.target.select() }}
                                                        invalid={(validationFlag == false) &&
                                                            (validation.values.advance - validation.values.enterAmount < 0 ? true : false)
                                                        }
                                                    />
                                                    {validation.values.advance - validation.values.enterAmount < 0 ? (
                                                        <FormFeedback type="invalid">
                                                            Advance must be greater than or equal refund
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        )}
                                {(flag == "booking" || flag == "delivery") || (flag == "settle" && !isRefund) ? (
                                    <Col md={4}>
                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03"> Total Receive Amount</Label>
                                            <Input
                                                name="advancedMinusRefund"
                                                disabled
                                                type="number"
                                                placeholder="0.00"
                                                className="form-control-sm"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={parseInt(validation.values.advance) + parseInt(validation.values.enterAmount)}

                                            />

                                            <span style={{ color: "green", fontSize: "12px" }}>
                                                Pending Amount: <span style={{ fontFamily: "monospace", fontSize: "14px", paddingRight: "1px", color: "green" }}>₹</span> {(validation.values.amount - validation.values.advance - validation.values.enterAmount - validation.values.discount).toLocaleString('en-IN', { currency: 'INR' }) + ".00"}
                                            </span>
                                        </FormGroup>
                                    </Col>) : (
                                    <Col md={4}>
                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03">Total Payble Amount</Label>
                                            <Input
                                                name="advancedMinusRefund"
                                                placeholder="Advance - Refund"
                                                type="number"
                                                className="form-control-sm"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.advance - validation.values.enterAmount < 0 ? validation.values.advance : validation.values.advance - validation.values.enterAmount}
                                            />

                                        </FormGroup>
                                    </Col>)}
                            </Row>
                            {flag == "cancelHold" &&
                                <Row>
                                    <Col md={4}>
                                        <FormGroup className="mb-2">
                                            <Label htmlFor="validationCustom03"> Expire Month</Label>
                                            <Input
                                                name="month"
                                                placeholder="Month"
                                                type="number"
                                                className="form-control-sm"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.month}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                            <div className="d-flex justify-content-end align-items-center gap-2">
                                {
                                    flag == "delivery" ? <>
                                        <input
                                            id="checkbox2"
                                            type="checkbox"
                                            className="mb-4"
                                            defaultChecked={flagForAllDelivery}
                                            onChange={e => setflagForAllDelivery(e.target.checked)}
                                        />
                                        <label htmlFor="checkbox2" className="pt-1"  >
                                            If you change the status of this item, the status of all the product inside it will be changed.
                                        </label></> : null
                                } </div>
                            <Row>
                                <Col md={formSumitLoading ? 9 : 10} className="pe-0">
                                    <div className="mb-2 mt-1">
                                        <div className=" d-flex justify-content-end  gap-2">
                                            <input
                                                type="radio"
                                                value="1"
                                                id="cash"
                                                name="mode"
                                                className="form-check-input"
                                                checked={paymentMethod === "1"}
                                                // onChange={(e) => setpaymentMethod(e.target.checked ? "1" : "2")}
                                                onClick={() => { setpaymentMethod("1") }}
                                            />
                                            <Label className="form-check-label font-size-13" htmlFor="cash">
                                                Cash
                                            </Label>

                                            <input
                                                type="radio"
                                                value="2"
                                                id="bank"
                                                name="mode"
                                                className="form-check-input"
                                                checked={paymentMethod === "2"}
                                                // onChange={(e) => setpaymentMethod(e.target.checked ? "2" : "1")}
                                                onClick={() => { setpaymentMethod("2") }}
                                            />
                                            <Label className="form-check-label font-size-13" htmlFor="bank">
                                                Bank
                                            </Label>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={formSumitLoading ? 3 : 2}>
                                    <div className="d-flex justify-content-end align-items-center gap-2">
                                        {/* {
                                            flag == "delivery" ? <>
                                                <input
                                                    id="checkbox2"
                                                    type="checkbox"
                                                    className="mb-2"
                                                    defaultChecked={flagForAllDelivery}
                                                    onChange={e => setflagForAllDelivery(e.target.checked)}
                                                />
                                                <label htmlFor="checkbox2" className="pt-3"  >
                                                    If you change the status of this item, the status of all the product inside it will be changed.
                                                </label></> : null
                                        } */}

                                        {formSumitLoading ? (
                                            <button type="button" className="btn btn-primary btn-sm">
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                Loading
                                            </button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                className="btn btn-primary btn-sm "
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </div>
            </Modal >
        </div >
    )
}


export default CommonModel;
