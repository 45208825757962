/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Container,
  FormFeedback,
  CardBody,
} from "reactstrap"
import Select from "react-select";
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { useRef } from "react"
import SubCategoriesTable from "./SubCategoriesTable";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";


const colourStyles = {
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
      color: isFocused ? "#fff" : "#495057",
      cursor: isDisabled ? "not-allowed" : "default",
      padding: "3px 10px",
    }
  },
  control: (styles, { isDisabled }) => {
    const updatedStyles = {
      ...styles,
      border: "1px solid  #ced4da",
      cursor: isDisabled ? "not-allowed" : "default",
      backgroundColor: isDisabled ? "#e8e9ebd1" : "default",
      minHeight: "28px",
      padding: "3px 10px",
      fontSize: "12px",
    }
    return updatedStyles
  },
}

function SubCategories() {
  const { getApi, postApi } = useApi()
  let { pathname } = useLocation()
  // useStates
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [date, setdate] = useState([new Date(), new Date()])
  const [screeLoad, setscreeLoad] = useState(false)
  const [categoryDataList, setCategoryDataList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [formSumitLoading2, setformSumitLoading2] = useState(false)
  const [categoryDataName, setCategoryDataName] = useState(null)
  const [categoryDataId, setCategoryDataId] = useState('')

  useEffect(() => {
    setscreeLoad(true)
    getApi("Category/parentCategory-lookup").then(({ data, status }) => {
      if (status === 200) {
        setCategories(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setscreeLoad(false)
    })
  }, [])

  function handleSelectGroup(selectedId) {
    setCategoryDataName(selectedId.lable);
    setCategoryDataId(selectedId.value)
  }

  // avalability validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: 0,
      subCategory: 0,
      subCategoryData: 0,
    },
    validationSchema: Yup.object({
      category: Yup.number().min(1, "The category is required"),
      subCategory: Yup.number().min(1, "The sub category is required"),
    }),
    onSubmit: values => {
      values.subCategoryData = categoryDataId
      if (values) {
        if (date[1] == undefined) {
          toast("Please Select date", {
            icon: "⚠️",
            style: {
              fontSize: "15px",
            },
          })
        } else {
          setformSumitLoading2(true)
          setIsLoading(true)
          postApi("Report/subCategory-report", {
            fromDate: date[0] ? dateFormat(
              date[0],
              "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            toDate: date[1] ? dateFormat(
              date[1],
              "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            subCategoryId: parseInt(values.subCategory),
            data: values.subCategoryData,
          }).then(({ data, status }) => {
            if (status == 200) {
              setCategoryDataList(data)
              setCategoryDataId('')
            } else {
              toast.error(data, {
                style: {
                  fontSize: "15px",
                },
              })
            }
            setformSumitLoading2(false)
            setIsLoading(false)
          })
        }

      }
    },
  })

  useEffect(() => {
    setscreeLoad(true)
    getApi(`Category/subCategory-lookup?categoryId=${validation.values.category}`).then(({ data, status }) => {
      if (status === 200) {
        setSubCategories(data)
        setCategoryDataList([])
        setCategoryDataName('')
        setCategoryData('')
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setscreeLoad(false)
    })
    validation.setFieldValue("subCategory", 0)
  }, [validation.values.category])

  useEffect(() => {
    if (validation.values.subCategory > 0) {
      setscreeLoad(true)
      getApi(`Category/subCategory-data-lookup?subCategoryId=${validation.values.subCategory}`).then(({ data, status }) => {
        if (status === 200) {
          const categoryData = data.map(item => ({
            label: item.DisplayName,
            value: item.DisplayName,
            id: item.Id
          }));
          categoryData.unshift({
            label: "Select",
            value: "",
            id: 0
          });
          setCategoryDataName('')
          setCategoryData(categoryData);
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
        setscreeLoad(false)
      })
    }

  }, [validation.values.subCategory])

  // table header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Data",
        accessor: "Data",
      },
      {
        Header: "Date",
        accessor: "DateRange",
        width: "10%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DateRange == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DateRange.split("T")[0],
                "dd/MM/yyyy"
              )}
          </p>
        ),
      },
      {
        Header: "Count",
        accessor: "Count",
        width: "5%",
      },
    ],
    []
  )


  document.title = `Sub Categories | ${localStorage.getItem("ShopName")}`
  const flatpickrRef = useRef(null);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <Breadcrumbs
            title={breadcrumbs[pathname].title}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
          />
          <Card>
            <CardBody>
              <Row>
                <Col sm="12">
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row style={{ marginTop: "5px" }}>
                      <Col md={2} className="mb-2">
                        <label
                          htmlFor="name"
                          className="required form-label"
                        >
                          Categories
                        </label>
                        <Input
                          type="select"
                          id="validationCustom02"
                          bsSize="sm"
                          className="form-control-sm"
                          name="category"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category}
                          invalid={
                            validation.touched.category &&
                              validation.errors.category
                              ? true
                              : false
                          }
                        >
                          <option value={0}>Select Category</option>
                          {categories.map((item, index) => {
                            return (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            )
                          })}
                        </Input>
                        {validation.touched.category &&
                          validation.errors.category ? (
                          <FormFeedback type="invalid">
                            {validation.errors.category}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} className="mb-2">
                        <label
                          htmlFor="name"
                          className="required form-label"
                        >
                          Sub Categories
                        </label>
                        <Input
                          type="select"
                          id="validationCustom02"
                          disabled={validation.values.category == 0}
                          bsSize="sm"
                          className="form-control-sm"
                          name="subCategory"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.subCategory}
                          invalid={
                            validation.touched.subCategory &&
                              validation.errors.subCategory
                              ? true
                              : false
                          }
                        >
                          <option value={0}>Select Sub Category</option>
                          {subCategories.map((item, index) => {
                            return (
                              <option key={index} value={item.Id}>
                                {item.DisplayName}
                              </option>
                            )
                          })}
                        </Input>
                        {validation.touched.subCategory &&
                          validation.errors.subCategory ? (
                          <FormFeedback type="invalid">
                            {validation.errors.subCategory}
                          </FormFeedback>
                        ) : null}
                      </Col>
                      <Col md={2} className="mb-2">
                        <label
                          htmlFor="name"
                          className="form-label"
                        >
                          Category Data
                        </label>
                        <Select
                          unstyled
                          styles={colourStyles}
                          isDisabled={validation.values.subCategory == 0 || validation.values.category == 0}
                          value={categoryDataName}
                          onChange={(e) => {
                            handleSelectGroup(e);
                          }}
                          options={categoryData}
                          className="select2-selection"
                        />
                      </Col>
                      <Col md={3} className="table-header" >
                        <div className="mb-2 d-flex" style={{ marginTop: "21px" }}>
                          <Flatpickr
                            ref={flatpickrRef}
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              mode: "range",
                              dateFormat: "d-m-Y"
                            }}
                            style={{ height: "29px" }}
                            value={date}
                            onChange={(e) => { setdate(e) }}

                          />
                          <i
                            className="fas fa-calendar-alt calander-icon"
                            style={{ cursor: 'pointer', marginTop: '18px' }}
                            onClick={() => {
                              flatpickrRef.current.flatpickr.open();
                            }}
                          ></i>
                        </div>
                      </Col>
                      <Col md={2}
                        className="mb-2 available_button check_button Button_aline"
                      >
                        <>
                          {formSumitLoading2 ? (
                            <button
                              type="button"
                              className="btn btn-primary  btn-sm"
                              style={{ width: "80%", marginTop: "3px" }}
                            >
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              className="btn btn-primary  btn-sm"
                              style={{ width: "80%", marginTop: "3px" }}
                              type="submit"
                            >
                              Search
                            </Button>
                          )}
                        </>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>

              <SubCategoriesTable
                columns={columns}
                data={categoryDataList}
                isGlobalFilter={true}
                isCategoryList={true}
                customPageSize={10}
                isLoading={isLoading}
                className="custom-header-css"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SubCategories
