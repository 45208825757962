import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup, Label } from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { useEffect } from "react"

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search…"}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

const SettingDataTable = ({
    columns,
    data,
    TotalRecords,
    isGlobalFilter,
    isJobListGlobalFilter,
    isCreateProduct,
    isAddUserList,
    handleUserClick,
    customPageSize,
    className,
    isCategoryList,
    handleCategoryClick,
    isCreateBooking,
    handleBookingClicks,
    isColorList,
    handleColorClick,
    isSubscriptionList,
    handleSubscriptionClick,
    filterData,
    pagePerSize,
    isLoading,
    RoleName,
    handleBackupDatebase,
    vendorLookUp, vendeID, setVendeID,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: 3000,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )
    const handlesort = column => {
    }


    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }


    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    const [isMobile, setisMobile] = useState(window.innerWidth < 924)

    if (pagePerSize) {
        useEffect(() => {
            pagePerSize(pageSize)
        }, [pageSize, state.globalFilter])
    }

    return (
        <Fragment>
            {RoleName.RoleName == "Admin" &&
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={2} style={{ marginTop: "22px" }}>
                                {isGlobalFilter && (
                                    <GlobalFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={state.globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                        isJobListGlobalFilter={isJobListGlobalFilter}
                                    />
                                )}
                            </Col>
                            <Col md={4}>
                                <Label htmlFor="validationCustom02">User</Label>
                                <Input
                                    type="select"
                                    id="validationCustom02"
                                    className="form-control"
                                    name="user"
                                    onChange={e => {
                                        setVendeID(e.target.value)
                                    }}
                                    value={vendeID}
                                >
                                    <option value={0}>Global Setting</option>
                                    {vendorLookUp &&
                                        vendorLookUp.map((item, index) => {
                                            return (
                                                <option key={index} value={item.Key}>
                                                    {item.Value}
                                                </option>
                                            )
                                        })}
                                </Input>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                // <div className="row">
                //     <div className="col-md-12 mb-sm-2">
                //         <div className="row">
                //             <div className={`${isMobile ? `col-md-7` : `col-md-3`} mb-sm-2 mb-md-0`}>
                // {isGlobalFilter && (
                //     <GlobalFilter
                //         preGlobalFilteredRows={preGlobalFilteredRows}
                //         globalFilter={state.globalFilter}
                //         setGlobalFilter={setGlobalFilter}
                //         isJobListGlobalFilter={isJobListGlobalFilter}
                //     />
                // )}
                //             </div>
                //         </div>
                //     </div>
                // </div>
            }


            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        onClick={() => {
                                            handlesort(column)
                                        }}
                                        className={` ${column.Header == 'Allow Vendor' && RoleName.RoleName == "Vendor" ? 'column-display' : ""} `}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className={`text-nowrap ${(cell.column.Header == 'Allow Vendor' && RoleName.RoleName == "Vendor") ? 'column-display' : ""} `}
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? ("Loading...") : ("No Record Found")}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>

        </Fragment>
    )
}

SettingDataTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default SettingDataTable