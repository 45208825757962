// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "../../../components/Common/TableContainer"
import { useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
    Input,
    FormGroup,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap"
import DeleteModel from "common/deleteModel/deleteModel"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import Loader from "common/Loader"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import SalesTable from "./SalesTable"
import SaleProductsModel from "./SaleProductsModel"
import appConfig from "Api/app.config"

function SalesList
    () {
    let { pathname } = useLocation()
    const { deleteApi, postApi, putApi, getApi } = useApi()
    document.title = `Sales
   | ${localStorage.getItem("ShopName")}`
    const navigate = useNavigate()

    // useStates
    const [modal, setModal] = useState(false)
    const [tableData, setTableData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [isLoading, setIsLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [displayStart, setDisplayStart] = useState(1)
    const [TotalRecords, setTotalRecords] = useState(0)
    const [productViewId, setProductViewId] = useState("")
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [dateDisble, setdateDisble] = useState(false)
    const [date, setdate] = useState([new Date(), new Date()])

    // filterData for Product
    const filterData = textFilter => {
    }

    // pagination
    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setRowsPerPage(pageSize)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    }

    const salesProductList = () => {
        setIsLoading(true)
        postApi("Sales/list", {
            fromDate: !dateDisble
                ? null
                : date[0]
                    ? dateFormat(date[0], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            toDate: !dateDisble
                ? null
                : date[1]
                    ? dateFormat(date[1], "yyyy-MM-dd")
                    : new Date().toISOString().slice(0, 10),
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setTableData(data.Saleslist)
                setTotalRecords(data.TotalRecords)
                setIsLoading(false)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
                setIsLoading(false)
            }
        })
    }

    const toggle = () => {
        setModal(!modal)
    }

    const handleProductView = bookingViewId => {
        if (bookingViewId) {
            setProductViewId(bookingViewId)
            toggle()
        }
    }

    useEffect(() => {
        if (date[1] != undefined) {
            salesProductList()
        }
    }, [dateDisble, date, rowsPerPage, searchText, displayStart])

    // product delete handler
    const handleDelete = productSaleId => {
        setIsLoading(true)
        if (productSaleId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to delete this sale item permanently? ",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`Sales/delete?id=${parseInt(productSaleId)}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== productSaleId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("Product sucessfully deleted", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Product",
                                    text: data,
                                })
                            }
                            setIsLoading(false)
                        }
                    )
                } else {
                    setIsLoading(false)
                }
            })
        }
    }

    const handleEdit = saleId => {
        if (saleId) {
            navigate(routes.updateSale, {
                state: {
                    saleId: saleId,
                },
            })
        }
    }

    const handlePrint = async (id) => {
        setIsLoading(true)
        getApi(`Report/sale-invoice?saleId=${id}`).then(({ data, status }) => {
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url);

            newWindow.addEventListener('load', () => {
                newWindow.print();
            });
            setIsLoading(false)
        })
    };

    const columns = useMemo(
        () => [
            {
                Header: "Bill No",
                accessor: "BillNo",
                width: "6%",
            },
            {
                Header: "Date",
                accessor: "Date",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original?.Date.split("T")[0] == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original?.Date, "dd/MM/yyyy")}
                    </span>
                ),
            },
            {
                Header: "Name",
                accessor: "Name",
                Cell: ({ row }) => (
                    <span>
                        {row.original.Name ? row.original.Name : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Contact No",
                accessor: "ContactNo",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.ContactNo ? row.original.ContactNo : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Total Qty",
                accessor: "TotalQty",
                width: "6%",
                Cell: ({ row }) => (
                    <div
                        className="text-center"
                    // onClick={() => {
                    //     handleProductView(row.original.Id)
                    // }}
                    >
                        {row.original?.TotalQty}
                    </div>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Bill Amt.
                    </div>
                ),
                accessor: "NetAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.NetAmount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Discount
                    </div>
                ),
                accessor: "Discount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Discount || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        GST Amt.
                    </div>
                ),
                accessor: "GSTAmount",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.GSTAmount || 0)}

                        </div>
                    )
                },
            },
            // {
            //     Header: "Details",
            //     accessor: "Remarks",
            //     width: "6%",
            //     Cell: ({ row }) => (
            //         <p
            //             className="mb-0"
            //             data-toggle="tooltip"
            //             data-placement="bottom"
            //             title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
            //         >
            //             {row.original.Remarks.length > 15
            //                 ? `${row.original.Remarks.substr(0, 15)}...`
            //                 : row.original.Remarks == ""
            //                     ? "N/A"
            //                     : row.original.Remarks}
            //         </p>
            //     ),
            // },

            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "9%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            {/* <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-download "
                                    id={`deletetooltip${row.original.Id}`}
                                    style={{ color: "#f39900" }}
                                    onClick={() => handleDownload(row.original.Id, row.original.BillNo)}
                                ></i>
                                <span className="mytext">Download</span>
                            </li> */}
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-print "
                                    style={{ color: "#90499c", cursor: "pointer" }}
                                    id={`printtooltip${row.original.Id}`}
                                    onClick={() =>
                                        handlePrint(row.original.Id)}
                                ></i>
                                <span className="mytext">Print</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit "
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => handleEdit(row.original.Id)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.Id}`}
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData, TotalRecords]
    )
    // redirect create product handler
    const handleUserClicks = () => {
        navigate(routes.createSale)
    }

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <SalesTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCreateProduct={true}
                            handleUserClick={handleUserClicks}
                            customPageSize={10}
                            className="custom-header-css"
                            filterData={filterData}
                            pagePerSize={pagePerSize}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            dateDisble={dateDisble}
                            setdateDisble={setdateDisble}
                            date={date}
                            setdate={setdate}
                        />

                        <Modal isOpen={modal} toggle={toggle} centered={true} size="auto">
                            <div className="modal-content">
                                <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                                    Products
                                </ModalHeader>
                                <ModalBody>
                                    <SaleProductsModel
                                        productViewId={productViewId}
                                        modal={modal}
                                        setModal={setModal}
                                    />
                                </ModalBody>
                            </div>
                        </Modal>

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
SalesList
    .propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default SalesList

