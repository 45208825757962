import React, { Fragment, useState } from "react"
import PropTypes from "prop-types";
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "../../../components/Common/GlobalSearchFilter";

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        // <React.Fragment>
        //     <Col md={4}>
        //         <div className="">
        //             <div className="">
        //                     <input
        //                         onChange={e => {
        //                             setValue(e.target.value);
        //                             onChange(e.target.value);
        //                         }}
        //                         id="search-bar-0"
        //                         type="text"
        //                         className="form-control"
        //                         placeholder={`${count} records...`}
        //                         value={value || ""}
        //                     />
        //                 <i className="bx bx-search-alt search-icon"></i>
        //             </div>
        //         </div>

        //     </Col>
        //     {isJobListGlobalFilter && (
        //         <JobListGlobalFilter />
        //     )}

        // </React.Fragment>
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search…"}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    );
}

const InquiriesTable = ({
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    isAddOptions,
    isAddUserList,
    handleOrderClicks,
    handleUserClick,
    handleCustomerClick,
    isAddCustList,
    customPageSize,
    className,
    customPageSizeOptions,
    TotalRecords,

}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
    };

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value));
    };

    const handlesort = column => {
    }

    const [isMobile, setisMobile] = useState(window.innerWidth < 924)

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-9 mb-sm-2">
                    <div className="row">
                        <div className={`${isMobile ? `col-md-3` : `col-md-2`} mb-2`}>
                            <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={`${isMobile ? `col-md-7` : `col-md-10`} mb-sm-2 mb-md-0`}>
                            {isGlobalFilter && (
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    isJobListGlobalFilter={isJobListGlobalFilter}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        onClick={() => {
                                            handlesort(column)
                                        }}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className="text-nowrap"
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}>
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {("No Record Found")}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>

            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        {/* <Button
                            color="primary"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {"<<"}
                        </Button> */}
                        <Button
                            color="primary"
                            onClick={previousPage}
                            disabled={!canPreviousPage}
                        >
                            {"<<"}
                        </Button>
                    </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                    Page 
                    <strong>
                        {TotalRecords == 0 ? 1 : pageIndex + 1} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
                    </strong> | Total <strong>{TotalRecords}</strong>
                </Col>
                {/* <Col className="col-md-auto">
                    <Input
                        type="number"
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex + 1}
                        onChange={onChangeInInput}
                    />
                </Col> */}

                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                            {">>"}
                        </Button>
                        {/* <Button
                            color="primary"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {">>"}
                        </Button> */}
                    </div>
                </Col>
            </Row>
        </Fragment>
    );
};

InquiriesTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default InquiriesTable;