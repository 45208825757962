import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody, Input } from "reactstrap"
import GetObjectsByValues from "_mock/Static_data"
import ReturnTable from "./ReturnTable"
import { dateFormat } from "pages/Common"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import CommonModel from "pages/Transiction/CommonModel"
import { exportDataExcel } from "pages/ExportData"

function Return() {
  const { state } = useLocation();
  let { pathname } = useLocation()
  const { postApi, putApi } = useApi()
  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [displayStart, setDisplayStart] = useState(1)
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [dateDisble, setdateDisble] = useState(true)
  const [date, setdate] = useState(state == null ? [new Date(), new Date()] : [new Date(state.fromData), new Date(state.todate)])
  const [statusCancel, setStatusCancel] = useState(false)
  const [bookingId, setBookingId] = useState()
  const [modal, setModal] = useState(false)

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }

  // return api useEffect
  useEffect(() => {
    if (date[1] != undefined) {
      returnApi()
    }
  }, [date, rowsPerPage, searchText, displayStart, dateDisble, statusCancel])

  // returnList api
  const returnApi = () => {
    setIsLoading(true)
    postApi("Report/return-report", {
      fromDate: !dateDisble ? null : date[0] ? dateFormat(
        date[0],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      toDate: !dateDisble ? null : date[1] ? dateFormat(
        date[1],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.ReturnReportList)
        console.log(data.ReturnReportList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  function writeDataToExcel() {
    setIsLoading(true)
    if (!dateDisble) {
      setIsLoading(false)
      toast("Please Select date", {
        icon: "⚠️",
        style: {
          fontSize: "15px",
        },
      })
    } else {
      let header = ["Status", "Bill No.", "Name", "Product Code", "Product Name", "Contact No.", "Bill Amt.", "Advance", "Pending Amt.", "Delivery Date", "Return Date", "Remarks",];
      const mainTitle = "Return Report";
      const widthObj = [
        {
          Key: "A",
          Value: 12,
        },
        {
          Key: "B",
          Value: 15,
        },
        {
          Key: "C",
          Value: 20,
        },
        {
          Key: "D",
          Value: 15,
        },
        {
          Key: "E",
          Value: 20,
        },
        {
          Key: "F",
          Value: 12,
        },
        {
          Key: "G",
          Value: 10,
        },
        {
          Key: "H",
          Value: 10,
        },
        {
          Key: "I",
          Value: 17,
        },
        {
          Key: "J",
          Value: 20,
        },
        {
          Key: "K",
          Value: 20,
        },
        {
          Key: "L",
          Value: 25,
        },
      ]

      const extention = "Return_Report.xlsx"
      postApi("Report/return-report", {
        fromDate: !dateDisble ? null : date[0] ? dateFormat(
          date[0],
          "yyyy-MM-dd"
        ) : new Date().toISOString().slice(0, 10),
        toDate: !dateDisble ? null : date[1] ? dateFormat(
          date[1],
          "yyyy-MM-dd"
        ) : new Date().toISOString().slice(0, 10),
        displayStart: 1,
        pageSize: 10000000,
        searchText: searchText,
        sortColumn: "",
        sortOrder: "",
      }).then(({ data, status }) => {
        if (status === 200) {
          setIsLoading(false)
          setTotalRecords(data.TotalRecords)
          let excelSheetData = []
          for (let index = 0; index < data.ReturnReportList.length; index++) {
            let dataObj = {
              Status: data.ReturnReportList[index].Status == 2 ? "Delivered" : "Return",
              BillNo: data.ReturnReportList[index].BillNo,
              Name: data.ReturnReportList[index].Name,
              ProductCode: data.ReturnReportList[index].ProductCode,
              ProductName: data.ReturnReportList[index].ProductName,
              ContactNo: data.ReturnReportList[index].ContactNo1,
              BillAmount: data.ReturnReportList[index].BillAmount,
              Advance: data.ReturnReportList[index].Advance,
              PendingAmount: data.ReturnReportList[index].BillAmount - data.ReturnReportList[index].Advance,
              DeliveryDate: dateFormat(data.ReturnReportList[index].DeliveryDate.split("T")[0],
                "dd/MM/yyyy") + (` ${data.ReturnReportList[index].DeliveryTimeSlot == null ? "" : `(${data.ReturnReportList[index].DeliveryTimeSlot}) `
                  }`),
              ReturnDate: dateFormat(data.ReturnReportList[index].ReturnDate.split("T")[0],
                "dd/MM/yyyy") + (` ${data.ReturnReportList[index].ReturnTimeSlot == null ? "" : `(${data.ReturnReportList[index].ReturnTimeSlot}) `
                  }`),
              Remarks: data.ReturnReportList[index].Remarks
            }
            excelSheetData.push(dataObj)
          }
          var fields = Object.keys(excelSheetData[0]);
          var sheetData = excelSheetData.map(function (row) {
            return fields.map(function (fieldName) {
              return row[fieldName];
            });
          });
          sheetData.unshift(header);
          exportDataExcel(sheetData, mainTitle, widthObj, extention, 0, 0, 0, 0, 0, 0)

        } else {
          setIsLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }

  // handlers
  const toggle = () => {
    setModal(!modal)
  }

  const bookingItemChangeStatus = (productId, statusId) => {
    const bookingId = ""
    putApi("Booking/change-Status", {
      bookingId: bookingId == "" ? 0 : bookingId,
      BookingItemId: productId == "" ? 0 : productId,
      BookingStatus: parseInt(statusId),
    }).then(({ data, status }) => {
      if (status === 200) {
        returnApi()
        toast.success("Status change sucessfully", {
          style: {
            fontSize: "15px",
          },
        })
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  /// Status Change Code ///
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", productId: "" })
  const [DeliveryModelFlag, setDeliveryModelFlag] = useState(false)

  const toggleDelivery = () => {
    if (DeliveryModelFlag) {
      setStatusCancel(true)
    }
    else {
      setStatusCancel(false)
    }
    setDeliveryModelFlag(!DeliveryModelFlag)
  }

  const handleProductStatus = (productId, statusId, bookingId) => {
    // setIsLoading(true)
    if (statusId == 3) {
      // setIsLoading(true)
      toggleDelivery()
      setBookingIdandStatusId({ bookingId: bookingId, statusId: statusId, productId: productId })
    }
    else if ((productId, statusId)) {
      bookingItemChangeStatus(productId, statusId)
    }

  }

  /// Booking Detail View
  const handleViewBookingDetails = (bookingId) => {
    setBookingId(bookingId)
    toggle()
  }

  // header and accessor
  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "Action",
        // width: "6%",
        Cell: ({ row }) => {

          const { Status } = row.original;
          let obj;

          if (Status === 2) {
            obj = GetObjectsByValues(2, 3);
          } else if (Status === 3) {
            obj = GetObjectsByValues(3);
          }
          return <div
            className="textWrap"
            style={{
              height: "8px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Input
              type="select"
              onChange={e =>
                handleProductStatus(row.original.BookingItemId, e.target.value, row.original.Id)
              }
              disabled={row.original.Status == 3}
              defaultValue={row.original.Status}
              bsSize="sm"
              className=" form-control"
            >
              {obj.map((item, index) => {
                return (
                  <option key={index} value={item.Value}>
                    {item.DisplayName}
                  </option>
                )
              })}
            </Input>
          </div>
        },
      },
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "5%",
        Cell: ({ row }) => (
          <>
            {row.original.TotalProduct == 0 ? (
              <div>{row.original.BillNo}</div>
            ) : (
              <div
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleViewBookingDetails(row.original.Id)
                }}
              >
                {row.original.BillNo}
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Product Code",
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: "Product Name",
        accessor: "ProductName",
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "7%",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Bill Amt.
          </div>
        ),
        accessor: "BillAmount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.BillAmount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Advance
          </div>
        ),
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Advance || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Pending Amt.
          </div>
        ),
        accessor: "PendingAmount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.BillAmount - row?.original?.Advance || 0)}
            </div>
          )
        },
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
          >
            {row?.original?.Remarks?.length > 30
              ? `${row?.original?.Remarks.substr(0, 30)}...`
              : row?.original?.Remarks == ""
                ? "N/A"
                : row?.original?.Remarks == null
                  ? "N/A"
                  : row?.original?.Remarks}
          </p>
        ),
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    [tableData]
  )

  document.title = `Return | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody style={{ paddingTop: "10px" }}>
            <ReturnTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              writeDataToExcel={writeDataToExcel}
              dateDisble={dateDisble}
              setdateDisble={setdateDisble}
              date={date}
              setdate={setdate}
            />
          </CardBody>

          {/* <DepositeModel
            modal={modal}
            toggle={toggle}
            BookingId={bookingId}
            statusId={3}
            setIsLoading={setIsLoading}
          /> */}

          <InvoiceDetalis
            toggle={toggle}
            modal={modal}
            bookingId={bookingId}
          />

          <CommonModel
            modal={DeliveryModelFlag}
            toggle={toggleDelivery}
            BookingandStatus={BookingIdandStatusId}
            flag={"delivery"}
          />

        </Card>
      </div>
    </div>
  )
}
Return.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Return
