import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  Input,
  UncontrolledTooltip,
  FormGroup,
  ModalHeader,
  Button,
  Label,
  FormFeedback,
  Row,
  Col,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import SettingDataTable from "./SettingTable"

function SettingTable() {
  const { getApi, putApi, postApi } = useApi()
  document.title = `Settings | ${localStorage.getItem("ShopName")}`
  let { pathname } = useLocation()

  // useStates
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editData, setEditData] = useState("")
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [settingId, setSettingId] = useState("")
  const [vendorLookUp, setvendorLookUp] = useState([])
  const [bookingInvoice, setBookingInvoice] = useState([])
  const [vendeID, setVendeID] = useState(JSON.parse(localStorage.getItem("authUser")).RoleName == "Admin" ? 0 : JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id)
  const [formSumitLoading, setformSumitLoading] = useState(false)
  const [RoleName, setRoleName] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  )

  // setting list api
  const settingApi = () => {
    setIsLoading(true)
    getApi(`Setting/list?userId=${vendeID}`).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  useEffect(() => {
    if (RoleName.RoleName == "Admin") {
      getApi("User/vendor-lookup").then(({ data, status }) => {
        if (status === 200) {
          setvendorLookUp(data)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }, [])

  // setting list api useEffect
  useEffect(() => {
    settingApi()
  }, [vendeID])

  // handle cancel
  const handleCancle = () => {
    toggle()
  }

  // Update setting handler
  const handleEditSetting = settingData => {
    if (settingData) {
      if (settingData?.ValueType == "SELECT") {
        getApi("Accounts/booking-invoice-template-lookup").then(({ data, status }) => {
          if (status === 200) {
            setBookingInvoice(data)
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
      setSettingId(settingData.Id)
      setIsEdit(true)
      setModal(!modal)
      setEditData(settingData)

    }
  }

  // setting switch handler
  const handleSwitch = settingId => {
    if (settingId) {
      getApi(`Setting/active?id=${settingId}`).then(({ data, status }) => {
        if (status === 200) {
          toast.success("Sucessfully change", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }
  // setting switch handler
  const handleSwitchAllowVendor = settingId => {
    if (settingId) {
      getApi(`Setting/change-vendor-access?id=${settingId}`).then(({ data, status }) => {
        if (status === 200) {
          toast.success("Sucessfully change", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }

  const handleBackupDatebase = () => {
    setIsLoading(true)
    postApi("setting/backup-database", '').then(({ data, status }) => {
      if (status == 200) {
        toast.success("Backup successfully", {
          style: {
            fontSize: "15px",
          },
        })
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setIsLoading(false)
    })
  }

  // setting Validations
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      value: isEdit ? editData?.Value : "",
    },
    validationSchema: Yup.object({
      value: Yup.string().required("The Value is required"),
    }),
    onSubmit: values => {
      setformSumitLoading(true)
      if (values) {
        toggle()
        putApi("Setting/update", {
          id: settingId,
          value: values.value,
        }).then(({ data, status }) => {
          setformSumitLoading(false)
          if (status == 200) {
            toast.success("Sucessfully updated", {
              style: {
                fontSize: "15px",
              },
            })
            settingApi()
            validation.handleReset()
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
            validation.handleReset()
          }
        })
      }
    },
  })

  // popup box handler
  const toggle = () => {
    setModal(!modal)
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "DisplayName",
        width: "8%",
      },
      {
        Header: "Key",
        accessor: "Key",
        width: "8%",
      },
      {
        Header: "Value",
        accessor: "Value",
        Cell: ({ row }) => (
          <div className="text-wrap">
            {row.original.Value == "1" && row.original.ValueType == "BOOL"
              ? "Yes"
              : row.original.Value == "0" && row.original.ValueType == "BOOL"
                ? "No"
                : row.original.Value}
          </div>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Active
          </div>
        ),
        width: "5%",
        accessor: "IsActive",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              defaultChecked={row.original.IsActive}
              onClick={e => {
                handleSwitch(row.original.Id)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: "Allow Vendor",
        width: "5%",
        accessor: "AllowVendorAccess",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              defaultChecked={row.original.AllowVendorAccess
              }
              onClick={e => {
                handleSwitchAllowVendor(row.original.Id)
              }}
            />
          </FormGroup>
        ),
      },

      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "7%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className=" fs-6 fas fa-edit btn-edit"
                  id={`edittooltip${row.original.Id}`}
                  onClick={() => handleEditSetting(row.original)}
                ></i>
                <span className="mytext">Edit</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <SettingDataTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
              RoleName={RoleName}
              setVendeID={setVendeID}
              vendeID={vendeID}
              vendorLookUp={vendorLookUp}
              handleBackupDatebase={handleBackupDatebase}
            />

            <Modal
              isOpen={modal}
              toggle={toggle}
              centered={true}
              size="small"
              style={{ width: "550px" }}
            >
              <div className="modal-content">
                <ModalHeader toggle={toggle} tag="h5">
                  {"Update Setting"}
                </ModalHeader>
                <ModalBody>
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    {editData.ValueType == "BOOL" ? (
                      <FormGroup>
                        <Input
                          name="value"
                          type="checkbox"
                          id="checkboxValue"
                          style={{ marginRight: "5px" }}
                          checked={validation.values.value === "1"}
                          onChange={event =>
                            validation.setFieldValue(
                              "value",
                              event.target.checked ? "1" : "0"
                            )
                          }
                          onBlur={validation.handleBlur}
                          value={validation.values.value || ""}
                          invalid={
                            validation.touched.value && validation.errors.value
                              ? true
                              : false
                          }
                        />
                        <Label for="checkboxValue" check>{editData.DisplayName}</Label>
                      </FormGroup>
                    ) : editData.ValueType == "SELECT" ? (
                      <FormGroup>
                        <Label>{editData.DisplayName}</Label>
                        <Input
                          name="value"
                          className="form-control-sm"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.value || ""}
                          invalid={
                            validation.touched.value && validation.errors.value
                              ? true
                              : false
                          }
                        >
                          {bookingInvoice.map((item, index) => {
                            return (
                              <option key={index} value={item.Value}>
                                {item.Value}
                              </option>
                            )
                          })}
                          {/* <option value={"1"}>
                            English
                          </option>
                          <option value={"2"}>
                            Gujarati
                          </option> */}

                        </Input>

                      </FormGroup>
                    ) : (
                      <FormGroup className="mb-2">
                        <Label htmlFor="validationCustom03">
                          {editData.DisplayName}
                        </Label>
                        <Input
                          name="value"
                          placeholder=""
                          type="textarea"
                          rows="7"
                          className="form-control-sm"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.value || ""}
                          invalid={
                            validation.touched.value && validation.errors.value
                              ? true
                              : false
                          }
                        />
                        {validation.touched.value && validation.errors.value ? (
                          <FormFeedback type="invalid">
                            {validation.errors.value}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                    )}
                    <div className="text-end">
                      <Button
                        color="secondary"
                        className="btn btn-primary me-2 btn-sm "
                        onClick={handleCancle}
                      >
                        Cancle
                      </Button>
                      {formSumitLoading ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                        >
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                          Loading
                        </button>
                      ) : (
                        <Button
                          color="primary"
                          className="btn btn-primary btn-sm "
                          type="submit"
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Form>
                </ModalBody>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

SettingTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default SettingTable
