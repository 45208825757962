/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    Button,
    Container,
    FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap"
import { useFormik } from "formik"
import Select from "react-select";
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import TableListProduct from "../TableListBooking"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import jsPDF from "jspdf"
import { font } from "pages/Reports/Booking/font"
import { InvoiceHtml } from "pages/Reports/Booking/invoicePDF"
import Breadcrumbs from "components/Common/Breadcrumb"
import breadcrumbs from "common/Breadcrumbs"
import { routes } from "routes/path"
import TableList from "components/Common/TableList"
import appConfig from "Api/app.config"
import gstPercentage from "common/GetGSTper"
import { dateFormat } from "pages/Common"


const colourStyles = {
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
            color: isFocused ? "#fff" : "#495057",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: "3px 10px",
        }
    },
    control: (styles, { isDisabled }) => {
        const updatedStyles = {
            ...styles,
            border: "1px solid  #ced4da",
            cursor: isDisabled ? "not-allowed" : "default",
            backgroundColor: isDisabled ? "#e8e9ebd1" : "default",
            minHeight: "28px",
            padding: "3px 10px",
            fontSize: "12px",
        }
        return updatedStyles
    },
}

const colourStyles1 = {
    option: (styles, { isDisabled, isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#556ee6" : "#f8f9fa",
            color: isFocused ? "#fff" : "#495057",
            cursor: isDisabled ? "not-allowed" : "default",
            padding: "3px 10px",
        }
    },
    control: (styles, { isDisabled }) => {
        const updatedStyles = {
            ...styles,
            border: "1px solid #f46a6a",
            minHeight: "28px",
            padding: "3px 10px",
            fontSize: "12px",
        }
        return updatedStyles
    },
}

function CreateCreditNote() {
    document.title = `Create Credit Note | ${localStorage.getItem("ShopName")}`
    const { getApi, postApi } = useApi()
    const navigate = useNavigate()
    let { pathname } = useLocation() // get path name
    // useStates
    const [modal, setModal] = useState(false)
    const [screeLoad, setscreeLoad] = useState(true)
    const [totalTaxableRent, setTotalTaxableRent] = useState(0)
    const [remarkProductId, setRemarkProductId] = useState(0)
    const [fields, setFields] = useState([]);
    const [productNote, setProductNote] = useState("")
    const [paymentMethod, setpaymentMethod] = useState("1")
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [CGSTPer, setCGSTPer] = useState(0)
    const [SGSTPer, setSGSTPer] = useState(0)
    const [totalCGSTAmount, setTotalCGSTAmount] = useState(0)
    const [totalSGSTAmount, setTotalSGSTAmount] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [netAmount, setNetAmount] = useState(0)
    const [isError, setIsError] = useState(false)
    // table state management
    const [tableData, setTableData] = useState([])
    const [rentList, setRentList] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [cancelBills, setCancelBills] = useState([])
    const [selectedBill, setSelectedBill] = useState(null)

    // APIs --> BillNo.-lookup, setting-by-keys
    useEffect(() => {
        setscreeLoad(true)
        if (JSON.parse(localStorage.getItem("authUser")).RoleName != "Salesman") {
            postApi("Setting/setting-by-keys", {
                Key: ["GST_PERCENTAGE"],
                userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
            }).then(({ data, status }) => {
                setscreeLoad(false)
                if (status === 200) {
                    setCGSTPer(parseFloat(data[0]?.Value.split("|")[0] || 0))
                    setSGSTPer(parseFloat(data[0]?.Value.split("|")[1] || 0))
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })
        }

        getApi("CreditNote/cancel-bill-lookup").then(({ data, status }) => {
            if (status === 200) {
                const itemData = data.map(item => ({
                    label: item.Value,
                    value: item.Value,
                    Id: item.Key,
                }));
                itemData.unshift({
                    label: "-- Select --",
                    value: "",
                    Id: 0,
                });
                setCancelBills(itemData)
                setSelectedBill(itemData[0])
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })
        bookingCalculation(null)
    }, [])

    const CancelBookingData = async () => {
        if (selectedBill?.Id > 0) {
            try {
                setscreeLoad(true);
                let endpointURL = `CreditNote/cancel-booking-data-cn?bookingId=${selectedBill.Id}`;
                const response = await getApi(endpointURL)
                let { data, status } = response
                setscreeLoad(false)
                if (status === 200) {
                    validation.setFieldValue("Name", data[0]?.Name || "");
                    validation.setFieldValue("ContactNo", data[0]?.ContactNo || "");
                    validation.setFieldValue("Address", data[0]?.Address || "");
                    data.forEach(function (item, index) {
                        item.CGSTPer = item.CGSTPer  // parseFloat(localStorage.getItem("GST")?.split("|")[0] || 0);
                        item.SGSTPer = item.SGSTPer //  parseFloat(localStorage.getItem("GST")?.split("|")[1] || 0);
                        bookingItemCalculation(item);
                    });
                    setRentList(data);
                    setTableData(data);
                    setpaymentMethod(data?.PaymentMode == 2 ? "2" : "1");
                    bookingCalculation(data);
                }
                else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }

    }

    // booking details
    useEffect(() => {
        CancelBookingData()
    }, [selectedBill]);

    const bookingItemCalculation = (itemData) => {
        itemData.Rent = (itemData.NetRent * 100) / (100 + itemData.CGSTPer + itemData.SGSTPer);
        itemData.CGSTAmount = ((itemData.Rent * itemData.CGSTPer) / 100).toFixed(2);
        itemData.SGSTAmount = ((itemData.Rent * itemData.SGSTPer) / 100).toFixed(2);
    }

    // modal toggles
    const toggle = () => {
        setModal(!modal)
    }

    // Booking Validations
    const validation = useFormik({
        enableReinitialize: false,
        initialValues: {
            id: 0,
            BillNo: "",
            date: new Date().toISOString().slice(0, 10),
            Name: "",
            remark: "",
            Rent: 0,
            Advance: '',
            ContactNo: "",
            Address: "",
        },
        validationSchema: Yup.object({
            date: Yup.string().required("The date is required"),
            Name: Yup.string().required("The name is required"),
            ContactNo: Yup.string().required("The contactNo is required"),
            Address: Yup.string().required("The address is required"),
        }),
        onSubmit: values => {
            if ((Object.keys(selectedBill).length === 0) || (selectedBill?.Id === 0)
            ) {
                setIsError(true);
                return;
            }

            if (Math.round(netAmount) < 0) {
                toast("Net Amt. should not be nagative.", {
                    icon: "⚠️",
                    style: {
                        fontSize: "15px",
                    },
                })
                return false
            }
            let cancelItem = []
            rentList.map(item => {
                cancelItem.push(
                    {
                        id: 0,
                        itemId: item.ProductId,
                        isProduct: true,
                        qty: item.Qty,
                        price: parseFloat(item.Rent),
                        cgstPer: item.CGSTPer,
                        sgstPer: item.SGSTPer,
                        igstPer: 0,
                        cgstAmount: parseFloat(item.CGSTAmount),
                        sgstAmount: parseFloat(item.SGSTAmount),
                        igstAmount: 0,
                        totalPrice: parseFloat(item.NetRent),
                        totalAmount: parseFloat(item.NetRent),
                        remarks: ''
                    })
            })
            postApi("CreditNote/create-credit-note", {
                date: values.date,
                refId: rentList[0].BookingId,
                name: values.Name,
                contactNo: values.ContactNo,
                address: values.Address,
                totalQty: totalQty,   // TODO
                taxableAmount: parseFloat(totalTaxableRent),
                cgstAmount: parseFloat(totalCGSTAmount),
                sgstAmount: parseFloat(totalSGSTAmount),
                igstAmount: 0,
                netAmount: parseFloat(netAmount),
                paymentMode: parseFloat(paymentMethod),
                remarks: values.remark,
                refType: 1,
                creditNoteItems: cancelItem,
            }).then(({ data, status }) => {
                if (status === 200) {
                    navigate(routes.CreditNote)
                    toast.success("Credit note successfully create", {
                        style: {
                            fontSize: "15px",
                        },
                    })
                } else {
                    toast.error(data, {
                        style: {
                            fontSize: "15px",
                        },
                    })
                }
            })
        },
    })

    // calculation total Amt.
    function bookingCalculation(data) {
        const totalTablerent = data
            ? data.reduce((sum, item) => sum + parseFloat(item.Rent), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.Rent), 0)
        setTotalTaxableRent(totalTablerent.toFixed(2))

        const totalCGSTAmt = data ? data.reduce((sum, item) => sum + parseFloat(item.CGSTAmount), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.CGSTAmount), 0)
        setTotalCGSTAmount(totalCGSTAmt.toFixed(2))

        const totalSGSTAmt = data ? data.reduce((sum, item) => sum + parseFloat(item.SGSTAmount), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.SGSTAmount), 0)
        setTotalSGSTAmount(totalSGSTAmt.toFixed(2))
        setNetAmount((totalTablerent + totalCGSTAmt + totalSGSTAmt))

        const totalQty = data ? data.reduce((sum, item) => sum + parseFloat(item.Qty), 0)
            : tableData.reduce((sum, item) => sum + parseFloat(item.Qty), 0)
        setTotalQty(totalQty)
    }

    // Delete Booking handler
    const deleteHandle = (Id) => {
        let updatedArray = []
        updatedArray = tableData
            .map(obj => (obj.ProductId !== Id ? obj : null))
            .filter(Boolean)
        setTableData(updatedArray)
        setRentList(updatedArray)
        bookingCalculation(updatedArray)
    }

    // change rent in perticular product
    const handleChangeNetRent = (ProductId, netRent, salesmanId) => {
        rentList.forEach(function (value) {
            if (value.ProductId === ProductId) {
                // value.Rent = parseFloat(rent || 0)
                value.Rent = ((netRent * 100) / (100 + value.CGSTPer + value.SGSTPer)).toFixed(2);
                value.NetRent = parseFloat(netRent || 0)
                value.SalesmanId = parseInt(salesmanId)
                value.CGSTAmount = ((value.Rent * value.CGSTPer) / 100).toFixed(2);
                value.SGSTAmount = ((value.Rent * value.SGSTPer) / 100).toFixed(2);
            }
        });
        bookingCalculation(rentList)
    }

    const handleChangeSubCategories = (index, e) => {
        const newFields = [...fields];
        newFields[index].Value = e.target.value;
        setFields(newFields);
    };

    // perticular product remarks add
    const handleRemarkAdd = () => {
        rentList.map(item => {
            if (item.ProductId == remarkProductId) {
                item.Remarks = productNote
                item.LstDynamicControl = fields
            }
        })
        toggle()
    }

    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    // multiple Booking Table
    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Code
                    </div>
                ),
                accessor: "ProductCode",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div
                            style={{
                                textAlign: "left",
                            }}
                        >
                            {row?.original?.ProductCode}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        Name
                    </div>
                ),
                accessor: "ProductName",
                Cell: ({ row }) => {
                    return <div
                        style={{
                            textAlign: "left",
                        }}>{row.original.ProductName}</div>
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "right",
                        }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "8%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                maximumFractionDigits: 2,
                            }).format(row.original?.Rent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        textAlign: "start"
                    }}>
                        Net Rent
                    </div>
                ),
                accessor: "TotalPrice",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div className="table-display">
                            <i className="mdi mdi-currency-inr"></i>
                            <Input
                                className="form-control-sm table-cell"
                                placeholder="0.00"
                                type="text"
                                onFocus={(e) => { e.target.select() }}
                                defaultValue={row.original.NetRent}
                                onKeyPress={handleKeyPress}
                                onChange={e =>
                                    handleChangeNetRent(row.original.ProductId
                                        , e.target.value, row.original.SalesmanId)
                                }
                            />
                        </div>
                    )
                },
            },
            {
                // Header: "CGST",
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        CGST({CGSTPer}%)
                    </div>
                ),
                accessor: "CGSTPer",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i> {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original.CGSTAmount || 0)
                            }
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        SGST({SGSTPer}%)
                    </div>
                ),
                accessor: "SGSTPer",
                width: "5%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i> {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original.SGSTAmount || 0)
                            }
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}>
                        Total Amt.
                    </div>
                ),
                accessor: "NetRent",
                width: "10%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}>
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row.original.NetRent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-2 mb-0 d-flex justify-content-center">
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.ProductId}`}
                                    onClick={() =>
                                        deleteHandle(row.original.ProductId)
                                    }
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData, CGSTPer, SGSTPer]
    )

    // bill select control automatically fills  all input
    const autoSelect = (Item, name) => {
        setSelectedBill(Item)
        setIsError(false)
        if (name == "Bill") {
        }
    }

    // handle Save and Print event
    const handleSaveprint = async id => {
        setscreeLoad(true)
        toast.success("Booking successfully create", {
            style: {
                fontSize: "15px",
            },
        })
        getApi(`Report/booking-invoice?bookingId=${id}`).then(({ data, status }) => {
            const byteCharacters = atob(data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url);

            newWindow.addEventListener('load', () => {
                newWindow.print();
            });
            setscreeLoad(false)
            navigate(routes.bookingList)
        })
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    {screeLoad && (
                        <div className="loading-overlay is-active">
                            <span className="fas fa-spinner fa-3x fa-spin"></span>
                        </div>
                    )}
                    <Breadcrumbs
                        parent={breadcrumbs[pathname].parent}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                        parentLink={breadcrumbs[pathname].parentLink}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={e => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                        onReset={e => {
                                            e.preventDefault
                                            validation.handleReset()
                                            return false
                                        }}
                                    >
                                        <Row className="mb-2">
                                            <Col md={2} style={{ marginTop: "0px" }} className="pe-0">
                                                <Label
                                                    htmlFor="name"
                                                    className="required"
                                                >
                                                    Bill No.
                                                </Label>
                                                <Select
                                                    unstyled
                                                    styles={isError ? colourStyles1 : colourStyles}
                                                    isDisabled={isEdit}
                                                    value={selectedBill}
                                                    // onChange={(e) => {
                                                    //     handleSelectGroup(e);
                                                    // }}
                                                    onChange={e => {
                                                        autoSelect(e, "Bill")
                                                        // handleTypeBarcode(e)
                                                    }}
                                                    onKeyDown={e => {
                                                        if (e.key === 'Enter') {
                                                            handleTypeBarcode(e.target.value)
                                                        }
                                                    }}
                                                    options={cancelBills}
                                                    className="select2-selection "
                                                />
                                                {isError ? (
                                                    <div className="name-required">
                                                        {"The bill number is required"}
                                                    </div>
                                                ) : null}
                                            </Col>

                                            <Col md={2} className='mb-2 pe-0'>
                                                <Label
                                                    htmlFor="name"
                                                    className="required form-label"
                                                >
                                                    Date
                                                </Label>
                                                <Input
                                                    name="date"
                                                    type="date"
                                                    dateFormat="DD/M/YYYY"
                                                    className="form-control-sm"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.date || ""}
                                                    invalid={
                                                        validation.touched.date &&
                                                            validation.errors.date
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {validation.touched.date &&
                                                    validation.errors.date ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.date}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>

                                            <Col md="6" className="pe-0">
                                                <div className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Name
                                                    </Label>
                                                    <Input
                                                        name="Name"
                                                        placeholder=" Name"
                                                        type="text"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Name || ""}
                                                        invalid={
                                                            validation.touched.Name && validation.errors.Name
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Name && validation.errors.Name ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Name}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md="2">
                                                <div className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Contact No.
                                                    </Label>
                                                    <Input
                                                        name="ContactNo"
                                                        placeholder="Contact No."
                                                        type="text"
                                                        maxLength="10"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.ContactNo || ""}
                                                        onKeyPress={handleKeyPress}
                                                        invalid={
                                                            validation.touched.ContactNo &&
                                                                validation.errors.ContactNo
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.ContactNo &&
                                                        validation.errors.ContactNo ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.ContactNo}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="8">
                                                <div className="mb-2">
                                                    <Label
                                                        htmlFor="validationCustom03"
                                                        className="required"
                                                    >
                                                        Address
                                                    </Label>
                                                    <Input
                                                        name="Address"
                                                        placeholder="Address"
                                                        type="textarea"
                                                        className="form-control-sm"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.Address || ""}
                                                        invalid={
                                                            validation.touched.Address &&
                                                                validation.errors.Address
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {validation.touched.Address &&
                                                        validation.errors.Address ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.Address}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className="mt-2 mb-2">
                                            <Col md="12" className="text-end ">
                                                <TableListProduct
                                                    columns={columns}
                                                    data={tableData}
                                                    isGlobalFilter={false}
                                                    isCreateBooking={false}
                                                    customPageSize={10}
                                                    allowSalesmanDisplay={false}
                                                    className="custom-header-css"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col md={9}>
                                                <Row>
                                                    <Col md={7}>
                                                        <div className="mb-2">
                                                            <Label htmlFor="validationCustom05">Remark</Label>
                                                            <Input
                                                                name="remark"
                                                                rows="2"
                                                                placeholder="Type your remark here"
                                                                type="textarea"
                                                                className="form-control"
                                                                value={validation.values.remark || ""}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                            />
                                                        </div>
                                                    </Col>
                                                    {/* <Col md={5} style={{ fontSize: "14px", textAlign: "-webkit-right" }}>
                                                        <table className="">
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts">
                                                                    <Label>
                                                                        CGST Amt.
                                                                    </Label>
                                                                </th>
                                                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(totalCGSTAmount)}
                                                                </th>
                                                            </tr>
                                                            <tr style={{ textAlign: "end" }}>
                                                                <th className="gst-amounts"> <Label>
                                                                    SGST Amt.
                                                                </Label></th>
                                                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                                                    {new Intl.NumberFormat('en-IN', {
                                                                        currency: 'INR',
                                                                        minimumFractionDigits: 2,
                                                                    }).format(totalSGSTAmount)}
                                                                </th>
                                                            </tr>
                                                        </table>
                                                    </Col> */}

                                                </Row>
                                            </Col>
                                            <Col md={3} style={{ fontSize: "14px", textAlign: "-webkit-right" }} >
                                                <table className="text-end">
                                                    <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts">
                                                            <Label>
                                                                Taxable Amt.
                                                            </Label>
                                                        </th>
                                                        <th className="gst-amounts2">
                                                            <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(totalTaxableRent)}
                                                        </th>
                                                    </tr>
                                                    {/* <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts">
                                                            <Label>
                                                                Taxes(+)
                                                            </Label>
                                                        </th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(totalTexes)}
                                                        </th>
                                                    </tr> */}
                                                    <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts">
                                                            <Label>
                                                                CGST Amt.(+)
                                                            </Label>
                                                        </th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(totalCGSTAmount)}
                                                        </th>
                                                    </tr>
                                                    <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts"> <Label>
                                                            SGST Amt.(+)
                                                        </Label></th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(totalSGSTAmount)}
                                                        </th>
                                                    </tr>
                                                    {/* <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts"> <Label>
                                                            IGST Amt.(+)
                                                        </Label></th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(0)}
                                                        </th>
                                                    </tr> */}
                                                    {/* <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts">
                                                            <Label>
                                                                Discount(-)
                                                            </Label></th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(discount)}
                                                        </th>
                                                    </tr> */}
                                                    <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts">
                                                            <Label>
                                                                Net Amt.
                                                            </Label></th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(Math.round(netAmount))}
                                                        </th>
                                                    </tr>
                                                    {/* <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts">
                                                            <Label>
                                                                Advance(-)
                                                            </Label></th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(Math.round(advance))}
                                                        </th>
                                                    </tr> */}
                                                    {/* <tr style={{ textAlign: "end" }}>
                                                        <th className="gst-amounts">
                                                            <Label>
                                                                Payable Amt.
                                                            </Label></th>
                                                        <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                                                            {new Intl.NumberFormat('en-IN', {
                                                                currency: 'INR',
                                                                minimumFractionDigits: 2,
                                                            }).format(Math.round(netAmount) - advance)}
                                                        </th>
                                                    </tr> */}
                                                </table>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" className="d-flex justify-content-end">
                                                <Row>
                                                    <div md={12} style={{ alignItems: "center", display: "flex", gap: "15px" }} className="d-flex justify-content-end text-center">
                                                        <div className="mt-3">
                                                            <div className=" d-flex gap-2">
                                                                <input
                                                                    type="radio"
                                                                    value="1"
                                                                    id="cash"
                                                                    name="mode"
                                                                    className="form-check-input"
                                                                    checked={paymentMethod === "1"}
                                                                    // onChange={(e) => setpaymentMethod(e.target.checked ? "1" : "2")}
                                                                    onClick={() => { setpaymentMethod("1") }}
                                                                />
                                                                <Label className="form-check-label font-size-13" htmlFor="cash">
                                                                    Cash
                                                                </Label>

                                                                <input
                                                                    type="radio"
                                                                    value="2"
                                                                    id="bank"
                                                                    name="mode"
                                                                    className="form-check-input"
                                                                    checked={paymentMethod === "2"}
                                                                    // onChange={(e) => setpaymentMethod(e.target.checked ? "2" : "1")}
                                                                    onClick={() => { setpaymentMethod("2") }}
                                                                />
                                                                <Label className="form-check-label font-size-13" htmlFor="bank">
                                                                    Bank
                                                                </Label>
                                                            </div>
                                                        </div>
                                                        <div className="" style={{ marginTop: "20px" }}>
                                                            <Button
                                                                color="secondary"
                                                                className="btn btn-primary me-2"
                                                                type="button"
                                                                onClick={() => {
                                                                    {
                                                                        navigate(routes.CreditNote)
                                                                    }
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                        <div className="" style={{ marginTop: "20px" }}>
                                                            <Button
                                                                color="primary"
                                                                className="btn btn-primary me-2"
                                                                type="submit"
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>

                                    <Modal
                                        isOpen={modal}
                                        toggle={toggle}
                                        centered={true}
                                        size="small"
                                        style={{ width: isMobile ? "300px" : "500px" }}
                                    >
                                        <div className="modal-content">
                                            <ModalHeader
                                                className="text-centre"
                                                toggle={toggle}
                                                tag="h5"
                                            >
                                                Product Note
                                            </ModalHeader>
                                            <ModalBody>
                                                <Row className="pe-0">
                                                    {fields.map(({ FieldName, Id, Value }, index) => (
                                                        <Col md={6} key={index}>
                                                            <div className="mb-2">
                                                                <Label>{FieldName}</Label>
                                                                <Input
                                                                    type="text"
                                                                    id="name"
                                                                    name="untyped-input"
                                                                    className="form-control-sm"
                                                                    placeholder={FieldName}
                                                                    value={Value}
                                                                    onChange={(e) => handleChangeSubCategories(index, e)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Row>
                                                    <div className="mb-2">
                                                        <Label htmlFor="validationCustom05">Remark</Label>
                                                        <Input
                                                            autoFocus
                                                            name="productRemark"
                                                            rows="2"
                                                            placeholder="Type your remark here"
                                                            type="textarea"
                                                            className="form-control"
                                                            defaultValue={productNote}
                                                            onChange={e => setProductNote(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="text-end">
                                                        <button
                                                            color="primary"
                                                            className="btn btn-primary btn-sm "
                                                            type="button"
                                                            onClick={() => handleRemarkAdd()}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </Row>
                                            </ModalBody>
                                        </div>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateCreditNote
