import React, { Fragment } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup, Label } from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import { useState } from "react"

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
    value,
    setValue,
}) {
    const count = preGlobalFilteredRows.length
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search…"}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

const WashingProductTable = ({
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    className,
    pagePerSize,
    isLoading,
    TotalRecords,
    categories
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            manualPagination: true,
            initialState: {
                // pageIndex: 0,
                pageSize: 20,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )
    const [pageIndex, setpageIndex] = useState(1)
    const [prevValue, setPrevValue] = useState();

    const handlesort = column => { }

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    const [value, setValue] = React.useState('')
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [isIpad, setIsIpad] = useState(window.innerWidth < 924)
    const [categoryId, setCategoryId] = useState(0)


    useEffect(() => {
        pagePerSize(pageSize, pageIndex, value, categoryId)
        setPrevValue(value);
    }, [pageSize, value, pageIndex, categoryId])

    useEffect(() => {
        if (value !== prevValue) {
            setpageIndex(1);
        }
    }, [value]);

    useEffect(() => {
        setpageIndex(1);
    }, [categoryId]);

    useEffect(() => {
        setpageIndex(1); // Reset pageIndex to 1 when value changes
    }, [pageSize]);


    return (
        <Fragment>
            <Row >
                <Col md="10" >
                    <Row>
                        <Col md={2} className="table-header">
                            <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col md={isIpad ? "5" : "2"} className="table-header">
                            {isGlobalFilter && (
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    isJobListGlobalFilter={isJobListGlobalFilter}
                                    value={value}
                                    setValue={setValue}
                                />
                            )}
                        </Col>
                        <Col md="2"
                            className={`${isMobile ? 'retireProductStyle' : ''} d-flex`}
                        >
                            <FormGroup >
                                <select
                                    className="form-select"
                                    value={categoryId}
                                    onChange={e => setCategoryId(e.target.value)}
                                >
                                    {categories.map((item, index) => {
                                        return (
                                            <option key={index} value={item.Key}>
                                                {item.Value}
                                            </option>
                                        )
                                    })}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="table-responsive react-table">
                <Table bordered hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        key={column.id}
                                        onClick={() => {
                                            handlesort(column)
                                        }}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                width: column.width,
                                            },
                                        })}
                                    >
                                        <div className={`mb-0`}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        className="text-nowrap"
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? "Loading..." : "No Record Found"}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>

            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            color="primary"
                            onClick={() => setpageIndex(pageIndex - 1)}
                            disabled={pageIndex == 1}
                        >
                            {"<<"}
                        </Button>
                    </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                    Page
                    <strong>
                        {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
                    </strong> | Total <strong>{TotalRecords}</strong>
                </Col>
                {/* <Col className="col-md-auto">
          <Input
            type="text"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex}
            onChange={onChangeInInput}
            value={pageIndex}
            disabled
          />
        </Col> */}

                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            color="primary"
                            onClick={() => setpageIndex(pageIndex + 1)}
                            disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
                        >
                            {">>"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Fragment >

    )
}

WashingProductTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default WashingProductTable
