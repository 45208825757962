// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import toast from "react-hot-toast"
//import components
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import TableList from "components/Common/TableList"


function SaleProductsModel({ productViewId, modal, setModal }) {
    // useState
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { getApi } = useApi()

    // useEffect product List
    useEffect(() => {
        setIsLoading(true)
        if (productViewId) {
            getApi(`Sales/item-list?salesBillId=${String(productViewId)}`).then(
                ({ data, status }) => {
                    if (status === 200) {
                        setIsLoading(false)
                        setTableData(data.SaleItems)
                    } else {
                        setIsLoading(false)
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                }
            )
        }
    }, [])



    // product view table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Product Code",
                accessor: "ProductCode",
                width: "5%",
            },
            {
                Header: "Product Name",
                accessor: "ProductName",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Amount
                    </div>
                ),
                accessor: "Price",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Price || 0)}
                        </div>
                    )
                },
            },
        ],
        [tableData]
    )

    return (
        <div>
            <div>
                <TableList
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={true}
                    isCategoryList={true}
                    customPageSize={10}
                    isLoading={isLoading}
                    className="custom-header-css"
                />
            </div>
        </div>
    )
}
SaleProductsModel.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default SaleProductsModel
