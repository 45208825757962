import React from "react";

const getIconClass = (key) => {
    switch (key) {
        case "TodaysBooking":
            return "far fa-calendar-check font-size-24";
        case "TodaysDelivery":
            return "fas fa-shuttle-van font-size-24";
        case "TodaysReturn":
            return "fas fa-car-alt font-size-24";
        case "TotalProductinCart":
            return "fas fa-shopping-cart font-size-22";
        case "TodaysInquiry":
            return "mdi mdi-clipboard-edit-outline font-size-24 !important pt-1.5";
        case "PendingInquiry":
            return "mdi mdi-timer-sand font-size-24";
        case "TotalUsers":
            return "fas fa-users font-size-24";
        case "SubscriptionExpiredInXDays":
            return "fas fa-bell font-size-24";
        default:
            return "";
    }
};

const IconComponent = ({ report }) => {
    const iconClass = getIconClass(report.Key);

    return <i className={iconClass}></i>;
};

export default IconComponent;
