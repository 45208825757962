import React, { Fragment, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
  usePagination,
} from "react-table"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  Label,
  FormGroup,
  InputGroup,
} from "reactstrap"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import GetObjectsByValues from "_mock/Static_data"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
  value,
  setValue,
}) {
  const count = preGlobalFilteredRows.length
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col>
        <div className="search-box d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"Search…"}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const DepositTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  customPageSize,
  className,
  dateValue,
  pagePerSize,
  isLoading,
  statusValue,
  TotalRecords,
  TotalReturn,
  TotalReceive,
  date, setdate,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        // pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const [pageIndex, setpageIndex] = useState(1)
  const [value, setValue] = React.useState()
  const [prevValue, setPrevValue] = useState();

  const checkbox = useRef();
  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value)
    setPrevValue(value);
  }, [pageSize, value, pageIndex])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1); // Reset pageIndex to 1 when value changes
    }
  }, [value]);

  const handlesort = column => {
  }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [statusChange, setStatusChange] = useState(0)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [isIpad, setIsIpad] = useState(window.innerWidth < 924)


  if (statusValue) {
    statusValue(statusChange)
  }

  const margin = {
    marginTop: isMobile ? "8px" : "",
  }

  useEffect(() => {
    setpageIndex(1); // Reset pageIndex to 1 when value changes
  }, [pageSize]);

  const handleClick = () => {

    setdateDisble(!dateDisble);

  }
  const flatpickrRef = useRef(null);

  return (
    <Fragment>
      <Row className="">
        <Col md="12">
          <Row>
            <Col md={isIpad ? "3" : "2"} className="table-header" style={margin}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={isIpad ? "3" : "2"} className="table-header" style={margin}>
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
            <Col md={3} className="table-header" >
              <div className=" d-flex" style={margin}>
                <Flatpickr
                  ref={flatpickrRef}
                  className="form-control d-block"
                  placeholder="dd M,yyyy"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y"
                  }}
                  value={date}
                  onChange={(e) => { setdate(e) }}

                />
                <i
                  className="fas fa-calendar-alt calander-icon"
                  style={{ cursor: 'pointer', marginTop: '' }}
                  onClick={() => {
                    flatpickrRef.current.flatpickr.open();
                  }}
                ></i>
              </div>
            </Col>
            <Col md={5} style={{ display: "flex", justifyContent: "end", marginTop: isMobile ? "8px" : "", }}>
              <div className="d-flex" style={{ justifyContent: "end" }}>
                <div>
                  {isGlobalFilter && (
                    <div>
                      <div style={{ color: "#0F6E25", fontSize: "15px", textAlign: "right" }}>
                        Total Receive:  <span><i className="mdi mdi-currency-inr"></i>
                          {new Intl.NumberFormat('en-IN', {
                            currency: 'INR',
                            minimumFractionDigits: 2,
                          }).format(TotalReceive || 0)}
                        </span>  </div>
                      <div style={{ color: "#0F6E25", fontSize: "15px", textAlign: "right" }}>
                        Total Return:   <span><i className="mdi mdi-currency-inr"></i>
                          {new Intl.NumberFormat('en-IN', {
                            currency: 'INR',
                            minimumFractionDigits: 2,
                          }).format(TotalReturn || 0)}
                        </span> </div>

                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered  {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        const { Status } = cell.row.original;
                        return (
                          <td
                            key={cell.id}
                            className={`${cell.column.Header == "Status" && (Status == 1 ? "booked-status-booking" : Status == 2 ? "deliverd-status-booking" : Status == 3 ? "return-status-booking" : Status == 4 ? "cancle-status-booking" : Status == 5 ? "hold-status-booking" : "")} text-nowrap`}

                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex - 1)}
              disabled={pageIndex == 1}
            >
              {"<<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page
          <strong>
            {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
          </strong> | Total <strong>{TotalRecords}</strong>
        </Col>
        {/* <Col className="col-md-auto">
          <Input
            type="text"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex}
            onChange={onChangeInInput}
            value={pageIndex}
            disabled
          />
        </Col> */}

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => setpageIndex(pageIndex + 1)}
              disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment >
  )
}

DepositTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default DepositTable
