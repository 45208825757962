import { saveAs } from 'file-saver';
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export function exportDataExcel(sheetData, mainTitle, widthObj, extention, TotalAdvance, totalTaxableAmt, totalCGSTAmt, totalSGSTAmt, totalIGSTAmt, TotalAmount) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });

        sheet1.cell("A1").value(mainTitle);
        const titleRange = sheet1.range(`A1:${endColumn}1`);
        titleRange.merged(true);
        titleRange.value(mainTitle);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });

        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })
        if (TotalAmount != 0) {
            const totalAmountRow = sheetData.length + 2;
            sheet1.cell(`C${totalAmountRow}`).value("Total").style({
                bold: true,
            });

            // sheet1.cell(`D${totalAmountRow}`).value(TotalAmount);
            sheet1.cell(`D${totalAmountRow}`).value(TotalAdvance).style({
                bold: true,
            });

            sheet1.cell(`E${totalAmountRow}`).value(totalTaxableAmt).style({
                bold: true,
            });

            sheet1.cell(`F${totalAmountRow}`).value(totalCGSTAmt || 0).style({
                bold: true,
            });

            sheet1.cell(`G${totalAmountRow}`).value(totalSGSTAmt || 0).style({
                bold: true,
            });
            sheet1.cell(`H${totalAmountRow}`).value(totalIGSTAmt || 0).style({
                bold: true,
            });
            sheet1.cell(`I${totalAmountRow}`).value(TotalAmount || 0).style({
                bold: true,
            });
        }

        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}

export function IncomeExpenseExportDataExcel(sheetData, mainTitle1, mainTitle2, widthObj, extention, totalIncome, totalExpense) {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
        const sheet1 = workbook.sheet(0);
        const totalColumns = sheetData[0].length;
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);

        sheet1.cell("A2").value(sheetData);
        sheet1.range("A2:" + endColumn + "1").style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            border: true,
            fontSize: 12,
            fill: "BDBBBB",
            borderColor: "DDDBDB",
        });
        sheet1.range("F1:F2").style({
            bold: false,
            border: true,
            fill: {
                type: "pattern",
                patternType: "solid",
                fgColor: "ffffff",
            }
        });

        sheet1.cell("A1").value(mainTitle1);
        const titleRange = sheet1.range(`A1:E1`);
        titleRange.merged(true);
        titleRange.value(mainTitle1);
        titleRange.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });
        sheet1.cell("A1").value(mainTitle2);
        const titleRange1 = sheet1.range(`G1:K1`);
        titleRange1.merged(true);
        titleRange1.value(mainTitle2);
        titleRange1.style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
            bold: true,
            fontSize: 12,
            fill: "E1DFDF"
        });

        widthObj.map(item => {
            sheet1.column(item.Key).width(item.Value);
        })
        const TotalIncome = sheetData.length + 2;
        sheet1.cell(`D${TotalIncome}`).value("Total Income").style({
            bold: true,
        });
        sheet1.cell(`E${TotalIncome}`).value(totalIncome).style({
            bold: true,
            horizontalAlignment: "right"
        });
        const TotalExpenses = sheetData.length + 2;
        sheet1.cell(`J${TotalExpenses}`).value("Total Expense").style({
            bold: true,
        });
        sheet1.cell(`K${TotalExpenses}`).value(totalExpense).style({
            bold: true,
            horizontalAlignment: "right"
        });


        return workbook.outputAsync().then((res) => {
            saveAs(res, extention);
        });
    });


}