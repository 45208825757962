export const routes = {
  index: "/",
  dashboard: "/dashboard",
  userList: "/user",
  userCreate: "/create-user",
  userUpdate: "/update-user",
  inquiries: "/inquiries",
  size: "/size",
  color: "/color",
  timeslot: "/timeslot",
  plan: "/plan",
  available: "/generate-bill",
  demo: "/demo",
  plans: "/plans",
  decryptData: "/decryptData",
  productList: "/products",
  createProduct: "/create-product",
  updateproduct: "/update-product",
  bookingList: "/booking",
  HoldBillList: "/hold-bills",
  createbooking: "/create-booking",
  updatebooking: "/update-booking",
  reportBooking: "/report-booking",
  delivery: "/delivery",
  return: "/return",
  feedback: "/feedback",
  finance: "/finance",
  setting: "/setting",
  invoice: "/invoice",
  subscription: "/subscription",
  categories: "/categories",
  profile: "/user-profile",
  viewBooking: "/view-booking",
  importProduct: "/import-product",
  cartlist: "/cart",
  productHistory: "/product-history",
  salesman: "/salesman-report",
  bookedOrdersReport: "/bookedOrder-report",
  expenses: "/expense",
  income: "/income",
  incomeExpense: "/income-expense",
  decrypt: "/decrypt",
  createSale: "/create-sale",
  updateSale: "/update-sale",
  salesList: "/sales",
  catalogue: "/product-catalogue",
  tutorial: "/tutorial",
  tutorialForVendor: "/tutorials",
  reminder: "/reminder",
  releseNote: "/whats-new",
  pendingBills: "/pending-bills",
  subCategories: "/sub-categories",
  deposit: "/deposit",
  accounts: "/accounts",
  items: "/items",
  journalVouchers: "/journal-Vouchers",
  purchaseList: "/purchase",
  createPurchase: "/create-purchase",
  UpdatePurchase: "/update-purchase",
  CreditNote: "/credit-note",
  CreateCreditNote: "/create-creditNote",
  InquiryData: "/inquiry-data",
  Washing: "/washing",
  dbBackup: "/db-backup",
}
