import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import DeleteModel from "common/deleteModel/deleteModel"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLocation, useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader,
    UncontrolledTooltip,
} from "reactstrap"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../common/Breadcrumbs"
import { routes } from "routes/path"
import TutorialTable from "./TutorialTable"
import TutorialModel from "./TutorialModel"

function Tutorial() {

    const { deleteApi, postApi } = useApi()
    let { pathname } = useLocation()

    const [tableData, setTableData] = useState([])
    const [tutorialData, setTutorialData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [modal, setModal] = useState(false)
    const [tutorialId, setTutorialId] = useState()
    const [IsUpdate, setIsUpdate] = useState(false)

    const getTutorialData = () => {
        setIsLoading(true)
        postApi("Tutorials/list", '').then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    useEffect(() => {
        getTutorialData()
    }, [])

    const toggle = () => {
        setModal(!modal)
    }

    const handleAddTutorial = () => {
        setIsUpdate(false)
        setTutorialData()
        toggle();
    }
    const handleEditTutorial = (TutorialData) => {
        setTutorialData(TutorialData)
        setIsUpdate(true)
        toggle();
    }

    const handleUrlClick = (url) => {
        window.open(url, '_blank'); // Open the URL in a new tab
    };

    const confirmDelete = id => {
        DeleteModel.confirmDeleteInquiry({
            title: "Are you sure? ",
            text: "You want to delete this inquiry permanently? ",
            isMobile,
        })
            .then(response => {
                setIsLoading(true)
                if (response == true) {
                    deleteApi(`Tutorials/delete?id=${id}`).then(({ data, status }) => {
                        if (status === 200) {
                            const updatedArray = tableData
                                .map(obj => (obj.Id !== id ? obj : null))
                                .filter(Boolean)
                            setTableData(updatedArray)
                            toast.success("Sucessfully deleted", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            setIsLoading(false)
                        } else {
                            setIsLoading(false)
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                    })
                } else {
                    setIsLoading(false)
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const columns = useMemo(
        () => [
            {
                Header: "Title",
                accessor: "Title",
            },
            {
                Header: "Url",
                accessor: "Url",
                width: "10%",
                Cell: ({ row }) => (
                    <p
                        className="mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Url}`}
                    >
                        <a
                            className="text-primary"
                            onClick={() => handleUrlClick(row.original.Url)}
                            href="#"
                        >
                            {row.original.Url.length > 30
                                ? `${row.original.Url.substr(0, 30)}...`
                                : row.original.Url == ""
                                    ? "N/A"
                                    : row.original.Url}
                        </a>
                    </p>
                ),

            },
            {
                Header: "Language",
                accessor: "Language",
                width: "7%",
                Cell: ({ row }) => (
                    <p
                        className="mb-0">
                        {row.original.Language == 1
                            ? "English"
                            : row.original.Language == 2
                                ? "Gujarati"
                                : row.original.Language == 3 ? "Hindi" : "N/A"}
                    </p>
                ),
            },
            {
                Header: "Order",
                accessor: "Order",
                width: "4%",
            },
            {
                Header: "Discription",
                accessor: "Discription",
                width: "15%",
                Cell: ({ row }) => (
                    <p
                        className=" mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Discription == null ? '' : row.original.Discription}`}
                    >
                        {row.original.Discription.length > 25
                            ? `${row.original.Discription.substr(0, 25)}...`
                            : row.original.Discription == ""
                                ? "N/A"
                                : row.original.Discription}
                    </p>
                ),
            },
            {
                Header: "Create On",
                accessor: "CreatedOn",
                width: "7%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.CreatedOn == null
                            ? "N/A"
                            : dateFormat(row.original.CreatedOn.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Action
                    </div>
                ),
                width: "6%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className=" fs-6 fas fa-edit btn-edit"
                                    id={`edittooltip${row.original.Id}`}
                                    onClick={() => handleEditTutorial(row.original)}
                                ></i>
                                <span className="mytext">Edit</span>
                            </li>
                            <li className="mytooltip">
                                <i
                                    className="fs-6 fas fa-trash-alt btn-delete"
                                    id={`deletetooltip${row.original.Id}`}
                                    onClick={() => confirmDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Delete</span>
                            </li>

                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )

    document.title = `Tutorial | ${localStorage.getItem("ShopName")}`

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <TutorialTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            handleAddTutorial={handleAddTutorial}
                            customPageSize={10}
                            isCreateTutorial={true}
                            isLoading={isLoading}
                            className="custom-header-css"
                        />
                    </CardBody>
                </Card>

                <TutorialModel
                    modal={modal}
                    toggle={toggle}
                    getTutorialData={getTutorialData}
                    tutorialData={tutorialData}
                    IsUpdate={IsUpdate}
                />

            </div>
        </div>
    )
}
Tutorial.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default Tutorial
