/* eslint-disable react/no-unknown-property */
import React, { useState } from "react"
import Compressor from "compressorjs"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Container,
  Input,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import { useLocation, useNavigate } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import uploadImage from "../../../assets/images/uploadImage.png"
import breadcrumbs from "../../../common/Breadcrumbs"
import { routes } from "routes/path"
import { useEffect } from "react"

function Adduser() {
  const { state } = useLocation()
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [isLoading, setisLoading] = useState(false)
  const { postApi, getApi } = useApi()
  const [shopName, setshopName] = useState("")
  const [RoleId, setRoleId] = useState(JSON.parse(localStorage.getItem("authUser")).RoleId)
  const [userControls, setUserControls] = useState([])
  const [parentId, setParentId] = useState(0)
  const [fileFlag, setfileFlag] = useState(false)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [logoFlag, setLogoFlag] = useState(false)
  const [previewLogoUrl, setPreviewLogoUrl] = useState(null)

  useEffect(() => {
    setshopName(JSON.parse(localStorage.getItem("authUser")).RoleId != 1 ? JSON.parse(localStorage.getItem("authUser")).ShopName : "")

    getApi("User/vendor-lookup").then(({ data, status }) => {
      if (status === 200) {
        setUserControls(data)
      }
    })
  }, [])

  // get user detail using parentId
  useEffect(() => {
    if (parentId > 0) {
      getApi(`User/user-details?id=${parentId}`).then(({ data, status }) => {
        if (status === 200) {
          validation.setFieldValue("fullName", data?.FullName || "");
          validation.setFieldValue("shopName", data?.ShopName || "");
          validation.setFieldValue("email", data?.Email || "");
          validation.setFieldValue("billYear", new Date(new Date(data?.BillYear).setDate(new Date(data?.BillYear).getDate())).toISOString().slice(0, 10),)
          validation.setFieldValue("address", data?.Address || "");
          validation.setFieldValue("remark", data?.Remarks || "");
          validation.setFieldValue("username", "");
          validation.setFieldValue("password", "");
          validation.setFieldValue("GSTNo", data?.GSTNo);
          validation.setFieldValue("PANNo", data?.PAN);
          validation.setFieldValue("bank", data?.BankName);
          validation.setFieldValue("accountNo", data?.AccountNo);
          validation.setFieldValue("ifscCode", data?.IFSC);
          setPreviewUrl(data?.ProfilePic)
          setPreviewLogoUrl(data?.Logo)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    } else {
      validation.handleReset()
      setPreviewUrl(null)
      setPreviewLogoUrl(null)
    }
  }, [parentId])

  // get parent id in select control(vendor lookup)
  const handleParentId = (parentId) => {
    setParentId(parentId)
  }

  // create user datail validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: state ? state?.inquiryData?.Name : "",
      username: "",
      shopName: state ? state?.inquiryData?.ShopName : shopName,
      contactNo: state ? state?.inquiryData?.ContactNo : "",
      contactNo2: '',
      address: state ? state?.inquiryData?.Address : "",
      password: "",
      bank: "",
      GSTNo: "",
      PANNo: "",
      accountNo: "",
      ifscCode: "",
      remark: "",
      email: "",
      profilePic: null,
      billYear: new Date().toISOString().slice(0, 10),
      logo: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("The full name is required"),
      username: Yup.string().required("The username is required"),
      shopName: Yup.string().required("The shop name is required"),
      contactNo: Yup.string()
        .required("The contact number is required.")
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number"),
      address: Yup.string().required("The address is required"),
      password: Yup.string().required("The password is required"),
      ...(RoleId == 1 && {
        billYear: Yup.string().required("The date is required"),
      })

    }),
    onSubmit: values => {
      setisLoading(true)
      postApi("User/create-user", {
        username: values.username,
        fullName: values.fullName,
        email: values.email,
        contactNo: values.contactNo,
        shopName: values.shopName,
        password: values.password,
        contactNo2: values.contactNo2,
        gstNo: values.GSTNo,
        pan: values.PANNo,
        bankName: values.bank,
        accountNo: values.accountNo,
        ifsc: values.ifscCode,
        address: values.address,
        profilePic: fileFlag ? values.profilePic.split(",")[1] : "",
        remarks: values.remark,
        billYear: values.billYear == "" ? null : values.billYear,
        logo: logoFlag ? values.logo.split(",")[1] : "",
        parentUserId: parseInt(parentId) || 0
      }).then(({ data, status }) => {
        if (status === 200) {
          setisLoading(false)
          toast.success("User Create Successfully", {
            style: {
              fontSize: "15px",
            },
          })
          navigate(routes.userList)
        } else {
          setisLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    },
  })

  // profile pic convert toBase64 handle
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  // profile pic select handle
  const handleDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setfileFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
      success: async compressedResult => {
        setPreviewUrl(URL.createObjectURL(compressedResult))
        validation.setFieldValue("profilePic", await toBase64(compressedResult))
      },
    })
  }

  // profile[ic display & set
  const handleclick = () => {
    validation.setFieldValue("profilePic", null)
    setPreviewUrl(null)
  }


  // logo select handle & compressor
  const handleLogoDrop = async acceptedFiles => {
    const selectedFile = acceptedFiles[0]
    setLogoFlag(true)
    new Compressor(selectedFile, {
      quality: 0.4,
      success: async compressedResult => {
        setPreviewLogoUrl(URL.createObjectURL(compressedResult))
        validation.setFieldValue("logo", await toBase64(compressedResult))
      },
    })
  }

  // profile pic display & set
  const handleLogoClick = () => {
    validation.setFieldValue("logo", null)
    setPreviewLogoUrl(null)
  }

  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  document.title = `User | ${localStorage.getItem("ShopName")}`

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Dropzone
                            onDrop={handleDrop}
                            accept="image/*"
                            multiple={false}
                            id="profilePic"
                            name="profilePic"
                          >
                            {({ getRootProps, getInputProps }) => (
                              <>
                                <div className="d-flex flex-column align-items-center text-center p-2 py-0">
                                  {previewUrl ? (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle pe-auto"
                                        style={{ cursor: "pointer" }}
                                        width="150px"
                                        height="150px"
                                        src={previewUrl}
                                        {...getRootProps()}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <input {...getInputProps()} />
                                      <img
                                        className="rounded-circle"
                                        style={{ cursor: "pointer" }}
                                        width="150px"
                                        src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                        {...getRootProps()}
                                      />
                                    </>
                                  )}
                                </div>
                                {previewUrl && (
                                  <div className="d-flex justify-content-center align-item-center ">
                                    <button
                                      onClick={() => handleclick()}
                                      type="button"
                                      className="btn btn-sm btn-soft-danger"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </Dropzone>
                          {validation.touched.profilePic &&
                            validation.errors.profilePic ? (
                            <div className="error">
                              {validation.errors.profilePic}
                            </div>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="9">
                        <Row>
                          {/* {RoleId == 1 &&
                            <Col md={3}>
                              <FormGroup className="mb-2">
                                <Label
                                  htmlFor="validationCustom03"
                                  className=""
                                >
                                  Parent User
                                </Label>
                                <Input
                                  type="select"
                                  bsSize="sm"
                                  id="validationCustom01"
                                  className="form-control-sm"
                                  name="parentUserId"
                                  // onChange={validation.handleChange}
                                  onChange={e => {
                                    handleParentId(e.target.value)
                                  }}
                                  onBlur={validation.handleBlur}
                                  value={parentId}
                                >
                                  <option>Select User</option>
                                  {userControls.map((item, index) => {
                                    return (
                                      <option key={index} value={item.Key}>
                                        {item.Value}
                                      </option>
                                    )
                                  })}
                                </Input>
                              </FormGroup>
                            </Col>} */}

                          <Col md={4}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom03"
                                className="required"
                              >
                                Shop Name
                              </Label>
                              <Input
                                name="shopName"
                                disabled={shopName}
                                placeholder="Shop Name"
                                type="text"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.shopName || shopName}
                                invalid={
                                  validation.touched.shopName &&
                                    validation.errors.shopName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.shopName &&
                                validation.errors.shopName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.shopName}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={5}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom01"
                                className="required"
                              >
                                Full Name
                              </Label>
                              <Input
                                name="fullName"
                                placeholder="Full Name"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom01"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fullName || ""}
                                invalid={
                                  validation.touched.fullName &&
                                    validation.errors.fullName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.fullName &&
                                validation.errors.fullName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fullName}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          {RoleId == 1 &&
                            <Col md={3}>
                              <label
                                htmlFor="name"
                                className="required form-label"
                              >
                                Bill Year
                              </label>
                              <Input
                                name="billYear"
                                type="date"
                                // min={fromDate}
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.billYear || ""}
                                invalid={
                                  validation.touched.billYear &&
                                    validation.errors.billYear
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.billYear &&
                                validation.errors.billYear ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.billYear}
                                </FormFeedback>
                              ) : null}
                            </Col>
                          }
                        </Row>
                        <Row>
                          <Col md={3}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom01"
                                className="required"
                              >
                                Contact No.1
                              </Label>
                              <Input
                                name="contactNo"
                                placeholder="Phone Number"
                                maxLength="10"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom01"
                                onKeyPress={handleKeyPress}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contactNo || ""}
                                invalid={
                                  validation.touched.contactNo &&
                                    validation.errors.contactNo
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.contactNo &&
                                validation.errors.contactNo ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.contactNo}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom01"
                                className=""
                              >
                                Contact No.2
                              </Label>
                              <Input
                                name="contactNo2"
                                placeholder="Phone Number"
                                maxLength="10"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom01"
                                onKeyPress={handleKeyPress}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contactNo2 || ""}
                                invalid={
                                  validation.touched.contactNo2 &&
                                    validation.errors.contactNo2
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.contactNo2 &&
                                validation.errors.contactNo2 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.contactNo2}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className="required"
                              >
                                Username
                              </Label>
                              <Input
                                name="username"
                                placeholder="username"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <div className="mb-2">
                              <Label className="form-label required">
                                Password
                              </Label>
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type="password"
                                className="form-control-sm"
                                placeholder="password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  placeholder="email address"
                                  type="email"
                                  className="form-control-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                      validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                  validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <Label className="form-label">GST No.</Label>
                                <Input
                                  name="GSTNo"
                                  placeholder="GST Number"
                                  type="text"
                                  className="form-control-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.GSTNo || ""}
                                  invalid={
                                    validation.touched.GSTNo &&
                                      validation.errors.GSTNo
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.GSTNo &&
                                  validation.errors.GSTNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.GSTNo}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <Label className="form-label">PAN No.</Label>
                                <Input
                                  name="PANNo"
                                  placeholder="PAN Number"
                                  type="text"
                                  className="form-control-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.PANNo || ""}
                                  invalid={
                                    validation.touched.PANNo &&
                                      validation.errors.PANNo
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.PANNo &&
                                  validation.errors.PANNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.PANNo}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <div className="mb-3">
                                <Label className="form-label">Bank Name</Label>
                                <Input
                                  name="bank"
                                  placeholder="Bank Name"
                                  type="bank"
                                  className="form-control-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.bank || ""}
                                  invalid={
                                    validation.touched.bank &&
                                      validation.errors.bank
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.bank &&
                                  validation.errors.bank ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.bank}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <Label className="form-label">Account No.</Label>
                                <Input
                                  name="accountNo"
                                  placeholder="Account Number"
                                  type="text"
                                  className="form-control-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.accountNo || ""}
                                  invalid={
                                    validation.touched.accountNo &&
                                      validation.errors.accountNo
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.accountNo &&
                                  validation.errors.accountNo ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.accountNo}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="mb-2">
                                <Label className="form-label">IFSC Code</Label>
                                <Input
                                  name="ifscCode"
                                  placeholder="IFSC Code"
                                  type="text"
                                  className="form-control-sm"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.ifscCode || ""}
                                  invalid={
                                    validation.touched.ifscCode &&
                                      validation.errors.ifscCode
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.ifscCode &&
                                  validation.errors.ifscCode ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.ifscCode}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-2">
                                <Label
                                  htmlFor="validationCustom04"
                                  className="required"
                                >
                                  Address
                                </Label>
                                <Input
                                  name="address"
                                  rows="2"
                                  placeholder="Type your address here"
                                  type="textarea"
                                  className="form-control-sm"
                                  id="validationCustom04"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.address || ""}
                                  invalid={
                                    validation.touched.address &&
                                      validation.errors.address
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.address &&
                                  validation.errors.address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="remark">Remarks</Label>
                                <Input
                                  type="textarea"
                                  className="form-control-sm"
                                  name="remark"
                                  id="remark"
                                  row={2}
                                  placeholder="Enter your remarks"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.remark}
                                  invalid={
                                    validation.touched.remark &&
                                      validation.errors.remark
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.remark &&
                                  validation.errors.remark ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.remark}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </Row>
                    {RoleId == 1 ?
                      <Row>
                        <Col md={3}></Col>
                        <Col md={5}>
                          <FormGroup>
                            <Label>Logo</Label>
                            <Dropzone
                              onDrop={handleLogoDrop}
                              onClick={handleLogoDrop}
                              accept="image/*"
                              multiple={false}
                              id="logo"
                              name="logo"
                            >
                              {({ getRootProps, getInputProps }) => (
                                <>
                                  <div className="d-flex">
                                    {previewLogoUrl ? (
                                      <>
                                        <div className="position-relative overflow-hidden product-image">
                                          <input {...getInputProps()} />
                                          <img
                                            style={{
                                              cursor: "pointer",
                                              border: "1px solid #ced4da",
                                              borderRadius: "3px",
                                            }}
                                            width="125px"
                                            height="100px"
                                            src={previewLogoUrl}
                                            {...getRootProps()}
                                          />
                                          <div className="delete-button">
                                            <button
                                              className="btn btn-sm btn-danger mb-1 me-1"
                                              onClick={() => handleLogoClick()}
                                              type="button"
                                              style={{ height: "22px", width: "19px" }}
                                            >
                                              <i className="fas fa-trash-alt" style={{
                                                fontSize: "11px !important", display: "flex", alignContent: "center",
                                                alignItems: "center", justifyContent: "center"
                                              }}></i>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <input {...getInputProps()} />

                                        <div
                                          style={{
                                            width: "125px",
                                            height: "100px",
                                            border: "1px solid #ced4da",
                                            cursor: "pointer",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            borderRadius: "3px",
                                          }}
                                        >
                                          <img
                                            className="img-fluid"
                                            style={{}}
                                            src={uploadImage}
                                            {...getRootProps()}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </>
                              )}
                            </Dropzone>
                          </FormGroup>

                        </Col>
                        <Col md="4" className="text-end" style={{ display: "flex", alignItems: "end", justifyContent: "end" }}>
                          <Button
                            color="secondary"
                            className="btn btn-primary me-2"
                            type="reset"
                          >
                            Reset
                          </Button>
                          {isLoading ? (
                            <button type="button" className="btn btn-primary">
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              className="btn btn-primary"
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col md="12" className="text-end ">
                          <Button
                            color="secondary"
                            className="btn btn-primary me-2"
                            type="reset"
                          >
                            Reset
                          </Button>
                          {isLoading ? (
                            <button type="button" className="btn btn-primary">
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                              Loading
                            </button>
                          ) : (
                            <Button
                              color="primary"
                              className="btn btn-primary"
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </Col>
                      </Row>}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default Adduser
