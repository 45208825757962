import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup, Label } from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "components/Common/GlobalSearchFilter"
import { useEffect, useState } from "react"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
  filterData,
  value,
  setValue,
}) {
  const count = preGlobalFilteredRows.length
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
    filterData(value)
  }, 200)

  return (
    <React.Fragment>
      <Col md={12}>
        <div className="search-box w-full d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={"billno..."}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}


const BookedOrdersTable = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  totalAmount,
  totalPayableAmt,
  totalBank,
  totalCash,
  TotalGST,
  className,
  filterData,
  pagePerSize,
  isLoading,
  TotalRecords,
  financeAmountShowHide,
  TotalAdvance,
  setdate, date,
  writeDataToExcel,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      manualPagination: true,
      initialState: {
        // pageIndex: 0,
        pageSize: 20,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useExpanded,
    usePagination
  )
  const [pageIndex, setpageIndex] = useState(1)

  const handlesort = column => { }

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }

  const [value, setValue] = React.useState()
  const [prevValue, setPrevValue] = useState();

  useEffect(() => {
    pagePerSize(pageSize, pageIndex, value)
    setPrevValue(value)
  }, [pageSize, value, pageIndex])

  useEffect(() => {
    if (value !== prevValue) {
      setpageIndex(1); // Reset pageIndex to 1 when value changes
    }
  }, [value]);

  useEffect(() => {
    setpageIndex(1); // Reset pageIndex to 1 when value changes
  }, [pageSize]);

  const [isMobile, setisMobile] = useState(window.innerWidth < 924)
  const [isIpad, setIpad] = useState(window.innerWidth < 924)

  const margin = {
    marginTop: isMobile ? "5px" : "17px",
  }

  const flatpickrRef = useRef(null);

  return (
    <Fragment>
      <Row className="">
        <Col className="table-header" md={isMobile ? 12 : 3} >
          <Row>
            <Col md={isMobile ? 12 : 6} style={margin} >
              <select
                className="form-select form-control"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={isMobile ? 12 : 6} style={{ marginTop: isMobile ? "12px" : "17px" }}>
              {isGlobalFilter && (
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  isJobListGlobalFilter={isJobListGlobalFilter}
                  filterData={filterData}
                  value={value}
                  setValue={setValue}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col md={3} className="table-header" >
          <div className=" d-flex" style={margin}>
            <Flatpickr
              ref={flatpickrRef}
              className="form-control d-block"
              placeholder="dd M,yyyy"
              options={{
                mode: "range",
                dateFormat: "d-m-Y"
              }}
              value={date}
              onChange={(e) => { setdate(e) }}

            />
            <i
              className="fas fa-calendar-alt calander-icon"
              style={{ cursor: 'pointer', marginTop: '17px' }}
              onClick={() => {
                flatpickrRef.current.flatpickr.open();
              }}
            ></i>
          </div>
        </Col>
        <Col md={isIpad ? 12 : 2} style={{ marginTop: isIpad ? "3px" : "17px", marginBottom: isIpad ? "10px" : "" }}>
          <div className="d-flex" style={{ justifyContent: "start", gap: "15px", marginLeft: isIpad ? "6px" : "0px" }}>
            {/* <div>
              {data.length > 0 ? (<button
                type="button"
                className="btn btn-pdf  btn-label1"
                onClick={handleDownloadPDF}
              >
                PDF <i className="far fa-file-pdf label-icon1"></i>
              </button>) :
                (<button
                  type="button"
                  className="btn btn-pdf  btn-label1"
                  style={{ cursor: "not-allowed" }}
                >
                  PDF <i className="far fa-file-pdf label-icon1"></i>
                </button>)
              }
            </div> */}
            <div>
              {data.length > 0 ? (<button
                type="button"
                className="btn btn-excel  btn-label"
                onClick={writeDataToExcel}
              >
                Excel <i className="far fa-file-excel label-icon"></i>
              </button>) : (<button
                type="button"
                className="btn  btn-excel  btn-label"
                style={{ cursor: "not-allowed" }}
              >
                Excel <i className="far fa-file-excel label-icon"></i>
              </button>)}
            </div>
          </div>
        </Col>
        {/* 
        <Col md={isIpad ? 12 : 4} style={{ display: "flex", justifyContent: "end", marginTop: isIpad ? "3px" : "", marginBottom: isIpad ? "10px" : "" }}>
          <div className="d-flex" style={{ justifyContent: isIpad ? "start" : "end", textAlign: isIpad ? "left" : "right", gap: "13px", marginLeft: isIpad ? "6px" : "0px" }}>
            <div>
              <div style={{ color: "#0F6E25", fontSize: "15px" }}>
                Total Amount:  {financeAmountShowHide ? <span><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(totalAmount || 0)}
                </span> : "*****"} <br />
                Total Advance:  {financeAmountShowHide ? <span><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(TotalAdvance || 0)}
                </span> : "*****"}<br />
                Total Discount: {financeAmountShowHide ? <span><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(TotalDiscount || 0)}
                </span> : "*****"}

              </div>
            </div>
          </div>
        </Col> */}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    onClick={() => {
                      handlesort(column)
                    }}
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                    })}
                  >
                    <div className={`mb-0`}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {page.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            className="text-nowrap"
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.minWidth,
                                maxWidth: cell.maxWidth,
                                width: cell.width,
                              },
                            })}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
            >
              <tr>
                <td colSpan={headerGroups[0].headers.length}>
                  {isLoading ? "Loading..." : "No Record Found"}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>

      <Row>
        <Col md={9} >
          <Row className="justify-content-md-end justify-content-end align-items-center mt-2">
            <Col md={4} className="pe-0">
              <div>
                Total Amt.:  {financeAmountShowHide ? <span style={{ fontWeight: "600" }} ><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(totalAmount || 0)}
                </span> : "*****"}
              </div>
            </Col>
            <Col md={4} className="pe-0">
              <div>
                Total Advance:  {financeAmountShowHide ? <span style={{ fontWeight: "600" }} ><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(TotalAdvance || 0)}
                </span> : "*****"}
              </div>
            </Col>
            <Col md={4} className="pe-0">
              <div>
                Total Pending Amt.:  {financeAmountShowHide ? <span style={{ fontWeight: "600" }} ><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(totalPayableAmt || 0)}
                </span> : "*****"}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-end justify-content-end align-items-center mt-2">
            <Col md={4} className="pe-0">
              <div>
                Cash Amt.:  {financeAmountShowHide ? <span style={{ fontWeight: "600" }} ><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(totalCash || 0)}
                </span> : "*****"}
              </div>
            </Col>
            <Col md={4} className="pe-0">
              <div>
                Bank Amt.:  {financeAmountShowHide ? <span style={{ fontWeight: "600" }} ><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(totalBank || 0)}
                </span> : "*****"}
              </div>
            </Col>
            <Col md={4} className="pe-0">
              <div>
                Total GST Amt.:  {financeAmountShowHide ? <span style={{ fontWeight: "600" }} ><i className="mdi mdi-currency-inr"></i>
                  {new Intl.NumberFormat('en-IN', {
                    currency: 'INR',
                    minimumFractionDigits: 2,
                  }).format(Math.round(TotalGST) || 0)}
                </span> : "*****"}
              </div>
            </Col>
          </Row>
        </Col>

        <Col md={3}>
          <Row className="justify-content-md-end justify-content-center align-items-center">
            <div style={{ display: "flex", gap: "15px", justifyContent: "end", alignContent: "center" }}>
              <div>
                <Button
                  color="primary"
                  onClick={() => setpageIndex(pageIndex - 1)}
                  disabled={pageIndex == 1}
                >
                  {"<<"}
                </Button>
              </div>
              <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                Page
                <strong>
                  {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
                </strong> | Total <strong>{TotalRecords}</strong>
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={() => setpageIndex(pageIndex + 1)}
                  disabled={TotalRecords == 0 ? true : pageIndex == Math.ceil(TotalRecords / pageSize)}
                >
                  {">>"}
                </Button>
              </div>
            </div>
          </Row>
        </Col>

      </Row>
    </Fragment>
  )
}

BookedOrdersTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default BookedOrdersTable
