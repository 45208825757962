// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import toast from "react-hot-toast"
//import components
import TableList from "../../../components/Common/TableList"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"


function DepositProductView({ productViewId }) {
    // useState
    const [tableData, setTableData] = useState([])
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [statusCancel, setStatusCancel] = useState(false)
    const { getApi, putApi } = useApi()

    // useEffect product List
    useEffect(() => {
        setIsLoading(true)
        setStatusCancel(false)
        if (productViewId) {
            getApi(`Booking/booking-item-list?id=${String(productViewId)}`).then(
                ({ data, status }) => {
                    if (status === 200) {
                        setIsLoading(false)
                        setTableData(data)
                    } else {
                        setIsLoading(false)
                        toast.error(data, {
                            style: {
                                fontSize: "15px",
                            },
                        })
                    }
                }
            )
        }
    }, [statusCancel])


    // product view table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Code",
                accessor: "ProductCode",
                width: "5%",
            },
            {
                Header: "Name",
                accessor: "ProductName",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Rent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Delivery",
                accessor: "DeliveryDate,DeliveryTimeSlot",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.DeliveryDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.DeliveryDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                        {row.original.DeliveryTimeSlot ? (
                            <span> {` (${row.original.DeliveryTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Return",
                accessor: "ReturnDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ReturnDate == null
                            ? "N/A"
                            : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
                        {row.original.ReturnTimeSlot ? (
                            <span> {` (${row.original.ReturnTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Remarks",
                accessor: "Remarks",
                width: "7%",
                Cell: ({ row }) => (
                    <p
                        className="mb-0"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
                    >
                        {row.original?.Remarks?.length > 15
                            ? `${row.original.Remarks.substr(0, 15)}...`
                            : row.original.Remarks == ""
                                ? "N/A"
                                : row.original.Remarks}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Status
                    </div>
                ),
                accessor: "Status",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <span>
                            {row.original.Status == 1 ? "Booked" : row.original.Status == 2 ? "Delivered" : row.original.Status == 3 ? "Return" : row.original.Status == 4 ? "Cancel" : row.original.Status == 5 ? "Hold" : "Settled"}
                        </span>
                    )
                },
            },
        ],
        [tableData]
    )

    return (
        <div>
            <div>
                <TableList
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={true}
                    isCategoryList={true}
                    customPageSize={10}
                    isLoading={isLoading}
                    className="custom-header-css"
                />

            </div>
        </div>
    )
}
DepositProductView.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default DepositProductView
