import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody, Input } from "reactstrap"
import DeliveryTable from "./DeliveryTable"
import { exportDataExcel } from "pages/ExportData"
import { dateFormat } from "pages/Common"
import GetObjectsByValues from "_mock/Static_data"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import CommonModel from "pages/Transiction/CommonModel"

function Delivery() {
  const { postApi, putApi } = useApi()
  let { pathname } = useLocation()
  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [bookingId, setBookingId] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [displayStart, setDisplayStart] = useState(1)
  const [TotalRecords, setTotalRecords] = useState(0)
  const [statusCancel, setStatusCancel] = useState(false)
  const [modal, setModal] = useState(false)
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", productId: "" })
  const [DeliveryModelFlag, setDeliveryModelFlag] = useState(false)
  const { state } = useLocation();
  const [dateDisble, setdateDisble] = useState(true)
  const [date, setdate] = useState(state == null ? [new Date(), new Date()] : [new Date(state.fromData), new Date(state.todate)])

  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }

  // delivery list api useEffect
  useEffect(() => {
    setStatusCancel(false)
    if (date[1] != undefined) {
      deliveryApi()
    }
  }, [date, rowsPerPage, searchText, displayStart, dateDisble, statusCancel])

  // delivery list api
  const deliveryApi = () => {
    setIsLoading(true)
    postApi("Report/delivery-report", {
      fromDate: !dateDisble ? null : date[0] ? dateFormat(
        date[0],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      toDate: !dateDisble ? null : date[1] ? dateFormat(
        date[1],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      fromExport: false,
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.DeliveryReportList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  const handleDownloadPDF = async () => {
    setIsLoading(true)
    if (!dateDisble) {
      setIsLoading(false)
      toast("Please Select date", {
        icon: "⚠️",
        style: {
          fontSize: "15px",
        },
      })
    } else {
      postApi("Report/export-delivery-report", {
        fromDate: date[0] ? dateFormat(
          date[0],
          "yyyy-MM-dd"
        ) : new Date().toISOString().slice(0, 10),
        toDate: date[1] ? dateFormat(
          date[1],
          "yyyy-MM-dd"
        ) : new Date().toISOString().slice(0, 10),
        searchText: searchText.trim(),
      }).then(async ({ data, status }) => {
        if (status === 200) {
          const byteCharacters = atob(data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Delivery_Report.pdf`;

          link.click();

          window.URL.revokeObjectURL(link.href);
          setIsLoading(false)
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }

  function writeDataToExcel() {
    setIsLoading(true)
    if (!dateDisble) {
      setIsLoading(false)
      toast("Please Select date", {
        icon: "⚠️",
        style: {
          fontSize: "15px",
        },
      })
    } else {
      let header = ["Bill No.", "Product Name", "Code", "Name", "Contact No.", "Bill Amt.", "Advance", "Pending Amt.", "Delivery Date", "Return Date", "Remarks",];
      const mainTitle = "Delivery Report";
      const widthObj = [
        {
          Key: "A",
          Value: 15,
        },
        {
          Key: "B",
          Value: 20,
        },
        {
          Key: "C",
          Value: 10,
        },
        {
          Key: "D",
          Value: 15,
        },
        {
          Key: "E",
          Value: 13,
        },
        {
          Key: "F",
          Value: 10,
        },
        {
          Key: "G",
          Value: 10,
        },
        {
          Key: "H",
          Value: 16,
        },
        {
          Key: "I",
          Value: 18,
        },
        {
          Key: "J",
          Value: 18,
        },
        {
          Key: "K",
          Value: 20,
        },

      ]

      const extention = "Delivery_Report.xlsx"
      postApi("Report/delivery-report", {
        fromDate: !dateDisble ? null : date[0] ? dateFormat(
          date[0],
          "yyyy-MM-dd"
        ) : new Date().toISOString().slice(0, 10),
        toDate: !dateDisble ? null : date[1] ? dateFormat(
          date[1],
          "yyyy-MM-dd"
        ) : new Date().toISOString().slice(0, 10),
        displayStart: 1,
        pageSize: 10000000,
        searchText: searchText,
        sortColumn: "",
        sortOrder: "",
      }).then(({ data, status }) => {
        if (status === 200) {
          setIsLoading(false)
          setTotalRecords(data.TotalRecords)
          let excelSheetData = []
          for (let index = 0; index < data.DeliveryReportList.length; index++) {
            let dataObj = {
              BillNo: data.DeliveryReportList[index].BillNo,
              ProductName: data.DeliveryReportList[index].ProductName,
              ProductCode: data.DeliveryReportList[index].ProductCode,
              Name: data.DeliveryReportList[index].Name,
              ContactNo: data.DeliveryReportList[index].ContactNo1,
              BillAmount: data.DeliveryReportList[index].BillAmount,
              Advance: data.DeliveryReportList[index].Advance,
              PendingAmount: data.DeliveryReportList[index].PendingAmount,
              DeliveryDate: dateFormat(data.DeliveryReportList[index].DeliveryDate.split("T")[0],
                "dd/MM/yyyy") + (` ${data.DeliveryReportList[index].DeliveryTimeSlot == null ? "" : `(${data.DeliveryReportList[index].DeliveryTimeSlot}) `
                  }`),
              ReturnDate: dateFormat(data.DeliveryReportList[index].ReturnDate.split("T")[0],
                "dd/MM/yyyy") + (` ${data.DeliveryReportList[index].ReturnTimeSlot == null ? "" : `(${data.DeliveryReportList[index].ReturnTimeSlot}) `
                  }`),
              Remarks: data.DeliveryReportList[index].Remarks
            }
            excelSheetData.push(dataObj)
          }
          var fields = Object.keys(excelSheetData[0]);
          var sheetData = excelSheetData.map(function (row) {
            return fields.map(function (fieldName) {
              return row[fieldName];
            });
          });
          sheetData.unshift(header);
          exportDataExcel(sheetData, mainTitle, widthObj, extention, 0, 0, 0, 0, 0, 0)

        } else {
          setIsLoading(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }
  }

  const bookingItemChangeStatus = (productId, statusId) => {
    const bookingId = ""
    putApi("Booking/change-Status", {
      bookingId: bookingId == "" ? 0 : bookingId,
      BookingItemId: productId == "" ? 0 : productId,
      BookingStatus: parseInt(statusId),
    }).then(({ data, status }) => {
      if (status === 200) {
        deliveryApi()
        toast.success("Status change sucessfully", {
          style: {
            fontSize: "15px",
          },
        })
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  const toggleDelivery = () => {
    if (DeliveryModelFlag) {
      setStatusCancel(true)
    }
    else {
      setStatusCancel(false)
    }
    setDeliveryModelFlag(!DeliveryModelFlag)
  }

  const handleProductStatus = (productId, statusId, bookingId) => {
    if (statusId == 2) {
      toggleDelivery()
      setBookingIdandStatusId({ bookingId, productId, statusId })
    }
    else if ((productId, statusId)) {
      bookingItemChangeStatus(productId, statusId)
    }

  }

  // handlers
  const toggle = () => {
    setModal(!modal)
  }

  const handleViewBookingDetails = (bookingId) => {
    toggle()
    setBookingId(bookingId)
  }

  // header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "Action",
        // width: "6%",
        Cell: ({ row }) => {

          const { Status } = row.original;
          let obj;

          if (Status === 1) {
            obj = GetObjectsByValues(1, 2);
          } else {
            obj = GetObjectsByValues(2);
          }

          return <div
            className="textWrap"
            style={{
              height: "8px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Input
              type="select"
              onChange={e =>
                handleProductStatus(row.original.BookingItemId, e.target.value, row.original.Id)
              }
              disabled={row.original.Status == 2}
              defaultValue={row.original.Status}
              bsSize="sm"
              className=" form-control"
            >
              {obj.map((item, index) => {
                return (
                  <option key={index} value={item.Value}>
                    {item.DisplayName}
                  </option>
                )
              })}
            </Input>
          </div>
        },
      },
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "5%",
        Cell: ({ row }) => (
          <>
            {row.original.TotalProduct == 0 ? (
              <div>{row.original.BillNo}</div>
            ) : (
              <div
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleViewBookingDetails(row.original.Id)
                }}
              >
                {row.original.BillNo}
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Name",
        accessor: "Name",
        // enableRowSpan: false
      },
      {
        Header: "Product Code",
        accessor: "ProductCode",
        width: "5%",
      },
      {
        Header: "Product Name",
        accessor: "ProductName",
      },
      {
        Header: "Customer No.",
        accessor: "ContactNo1",
        width: "8%",
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Bill Amt.
          </div>
        ),
        accessor: "BillAmount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.BillAmount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Advance
          </div>
        ),
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Advance || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Pending Amt.
          </div>
        ),
        accessor: "PendingAmount",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.PendingAmount || 0)}
            </div>
          )
        },
      },
      {
        Header: "Remarks",
        accessor: "Remarks",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            // style={{width:"120px"}}
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Remarks == null ? '' : row.original.Remarks}`}
          >
            {row.original.Remarks?.length > 30
              ? `${row.original.Remarks.substr(0, 30)}...`
              : row.original.Remarks == ""
                ? "N/A"
                : row.original.Remarks == null
                  ? "N/A"
                  : row.original.Remarks}
          </p>
        ),
      },
      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </p>
        ),
      },
    ],
    [tableData]
  )

  document.title = `Deliveries | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody style={{ paddingTop: "10px" }}>
            <DeliveryTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              handleDownloadPDF={handleDownloadPDF}
              writeDataToExcel={writeDataToExcel}
              TotalRecords={TotalRecords}
              dateDisble={dateDisble}
              setdateDisble={setdateDisble}
              date={date}
              setdate={setdate}
            />
            {/* <DepositeModel
              modal={modal}
              toggle={toggle}
              BookingId={bookingId}
              statusId={2}
              setIsLoading={setIsLoading}
            /> */}
            <CommonModel
              modal={DeliveryModelFlag}
              toggle={toggleDelivery}
              BookingandStatus={BookingIdandStatusId}
              flag={"delivery"}
            />

            <InvoiceDetalis
              toggle={toggle}
              modal={modal}
              bookingId={bookingId}
            />

          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Delivery.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Delivery
