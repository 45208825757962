import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
} from "reactstrap"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import useApi from "common/ApiMiddlewere"
import InquiriesTable from "./InquiriesTable"


function InquiriesList() {
    document.title = `Inquiry Data | ${localStorage.getItem("ShopName")}`
    const { getApi } = useApi()
    let { pathname } = useLocation()

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [TotalRecords, setTotalRecords] = useState(0)

    // booking list API useEffect
    useEffect(() => {
        setIsLoading(true)
        getApi("Cart/customer-inquiry-list").then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data)
                setTotalRecords(data.length)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }, [])

    // Booking table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Customer Name",
                accessor: "Name",
            },
            {
                Header: "Contact No.",
                accessor: "ContactNo",
                width: "9%",
            },
            {
                Header: "Product Code",
                accessor: "ProductCode",
                width: "6%",
            },
            {
                Header: "Delivery Date",
                accessor: "DeliveryDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.DeliveryDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.DeliveryDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                        {row.original.DeliveryTimeSlot ? (
                            <span> {` (${row.original.DeliveryTimeSlot})`}</span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Return Date",
                accessor: "ReturnDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ReturnDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
                        {row.original.ReturnTimeSlot ? (
                            <span>
                                <span> {` (${row.original.ReturnTimeSlot})`}</span>
                            </span>
                        ) : (
                            ""
                        )}
                    </p>
                ),
            },
            {
                Header: "Create Date",
                accessor: "CreatedOn",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.CreatedOn == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original.CreatedOn.split("T")[0], "dd/MM/yyyy")}
                    </p>
                ),
            },
        ],
        [tableData]
    )

    return (
        <div className="page-content background">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>

                        <InquiriesTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={20}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            className="custom-header-css"
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
InquiriesList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default InquiriesList