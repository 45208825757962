// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Input,
  FormGroup,
} from "reactstrap"
import DeleteModel from "common/deleteModel/deleteModel"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import ProductTable from "./ProductTable"
import { exportDataExcel } from "pages/ExportData"

function Products() {
  let { pathname } = useLocation()
  const { deleteApi, postApi, putApi, getApi } = useApi()
  document.title = `Products | ${localStorage.getItem("ShopName")}`
  const navigate = useNavigate()
  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [TotalRecords, setTotalRecords] = useState(0)
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [categories, setCategories] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [categoryId, setCategoryId] = useState(0)
  const [isRetire, setIsRetire] = useState(false)
  const [isInStock, setIsInStock] = useState(false)
  const [isWashing, setIsWashing] = useState(false)
  const [displayStart, setDisplayStart] = useState(1)

  // filterData for Product
  const filterData = textFilter => {
    setSearchText(textFilter)
  }

  useEffect(() => {
    setIsLoading(true)
    getApi("Category/category-lookup").then(({ data, status }) => {
      if (status === 200) {
        data.unshift({
          Value: "All Category",
          Key: 0
        });
        setCategories(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setIsLoading(false)
    })
  }, [])

  // pagination
  const pagePerSize = (pageSize, pagePerIndex, serchValue, isRetire, isInStock, categoryId, isWashing) => {
    setIsLoading(true)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
    setCategoryId(categoryId)
    setIsRetire(isRetire)
    setIsInStock(isInStock)
    setIsWashing(isWashing)
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
  }

  const ProductsListApi = () => {
    setIsLoading(true)
    postApi("product/list", {
      DisplayStart: displayStart,
      PageSize: rowsPerPage,
      SearchText: searchText,
      CategoryId: parseInt(categoryId),
      SortColumn: "",
      SortOrder: "",
      IsRetire: isRetire,
      IsNotInStock: isInStock,
      IsWashing: isWashing,
    }).then(({ data, status }) => {
      if (status === 200) {
        setTableData(data.ProductList)
        setTotalRecords(data.TotalRecords)
        setIsLoading(false)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    ProductsListApi()
  }, [displayStart, rowsPerPage, searchText, categoryId, isRetire, isInStock, isWashing])


  // product delete handler
  const handleDelete = productId => {
    setIsLoading(true)
    if (productId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to delete this product permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          deleteApi(`product/delete?id=${productId}`).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== productId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("Product sucessfully deleted", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                ErrorModel.errormodel({
                  title: "Verify Product",
                  text: data,
                })
              }
              setIsLoading(false)
            }
          )
        } else {
          setIsLoading(false)
        }
      })
    }
  }

  // product edit Id handler
  const editRow = productId => {
    if (productId) {
      getApi(`Product/details?id=${productId}`).then(
        ({ data, status }) => {
          if (status === 200) {
            navigate(routes.updateproduct, {
              state: {
                productId,
                data,
              },
            })
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        }
      )

    }
  }

  // Retire Product
  const handleProductRetire = (productId, isRetire, e) => {
    setIsLoading(true)
    if (productId) {
      DeleteModel.confirmDeleteInquiry({
        title: "Are you sure? ",
        text: "You want to retire this product permanently? ",
        isMobile,
      }).then(response => {
        if (response == true) {
          putApi("Product/retire-product", { productId: productId }).then(
            ({ data, status }) => {
              if (status === 200) {
                const updatedArray = tableData
                  .map(obj => (obj.Id !== productId ? obj : null))
                  .filter(Boolean)
                setTableData(updatedArray)
                toast.success("Product sucessfully Changed", {
                  style: {
                    fontSize: "15px",
                  },
                })
              } else {
                $("#isRetire_" + productId + "").prop("checked", isRetire)
                ErrorModel.errormodel({
                  title: "Verify Product",
                  text: data,
                })
              }
              setIsLoading(false)
            }
          )
        } else {
          $("#isRetire_" + productId + "").prop("checked", isRetire)
          setIsLoading(false)
        }
      })
    }

  }

  // product active handler
  const handleSwitch = (id, IsActive, e) => {
    if (id) {
      putApi("Product/active", { id: String(id) }).then(({ data, status }) => {
        e.preventDefault()
        if (status === 200) {
          toast.success("Product sucessfully changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          $("#IsActive_" + id + "").prop("checked", IsActive)
          ErrorModel.errormodel({
            title: "Verify Product",
            text: data,
          })
        }
      })
    }
  }

  const handleIsWashing = (id, IsActive, e) => {
    if (id) {
      putApi("Product/washing", { id: String(id) }).then(({ data, status }) => {
        e.preventDefault()
        if (status === 200) {
          const updatedArray = tableData
            .map(obj => (obj.Id !== id ? obj : null))
            .filter(Boolean)
          setTableData(updatedArray)
          toast.success("Product sucessfully Changed", {
            style: {
              fontSize: "15px",
            },
          })
        } else {
          $("#IsActive_" + id + "").prop("checked", IsActive)
          ErrorModel.errormodel({
            title: "Verify Product",
            text: data,
          })
        }
      })
    }
  }

  // table headers & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Code",
        accessor: "Code",
        width: "8%",
      },
      {
        Header: "Name",
        accessor: "Name",
        Cell: ({ row }) => (
          <span>
            {row.original.Name ? row.original.Name : "N/A"}
          </span>
        ),
      },
      {
        Header: "Category",
        accessor: "CategoryName",
        width: "6%",
        Cell: ({ row }) => (
          <span>
            {row.original.CategoryName ? row.original.CategoryName : "N/A"}
          </span>
        ),
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Rent || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            MRP
          </div>
        ),
        accessor: "MRP",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.MRP || 0)}
            </div>
          )
        },
      },
      {
        Header: "Color",
        accessor: "ColorName",
        width: "6%",
        Cell: ({ row }) => (
          <span>
            {row.original.ColorName ? row.original.ColorName : "N/A"}
          </span>
        ),
      },
      {
        Header: "Size",
        accessor: "SizeName",
        width: "5%",
        Cell: ({ row }) => (
          <span>{row.original.SizeName ? row.original.SizeName : "N/A"}</span>
        ),
      },
      {
        Header: "Date",
        accessor: "CreatedOn",
        width: "8%",
        Cell: ({ row }) => (
          <span>
            {row.original.CreatedOn.split("T")[0] == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.CreatedOn, "dd/MM/yyyy")}
          </span>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Retire
          </div>
        ),
        width: "7%",
        accessor: "IsRetire",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`isRetire_${row.original.Id}`}
              // disabled={row.original.IsRetire == true}
              defaultChecked={row.original.IsRetire}
              onClick={e => {
                handleProductRetire(row.original.Id, row.original.IsRetire, e)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Washing
          </div>
        ),
        width: "7%",
        accessor: "IsWashing",
        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`isWashing${row.original.Id}`}
              disabled={row.original.IsRetire == true}
              defaultChecked={row.original.IsWashing}
              onClick={e => {
                handleIsWashing(row.original.Id, row.original.IsWashing, e)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Is Active
          </div>
        ),
        width: "7%",
        accessor: "IsActive",

        Cell: ({ row }) => (
          <FormGroup switch className="d-flex justify-content-center">
            <Input
              type="switch"
              id={`IsActive_${row.original.Id}`}
              disabled={row.original.IsRetire == true}
              defaultChecked={row.original.IsActive}
              onClick={e => {
                handleSwitch(row.original.Id, row.original.IsActive, e)
              }}
            />
          </FormGroup>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              {
                row.original.IsRetire == true ? (
                  <>
                    <li className="mytooltip">
                      <i
                        className=" fs-6 fas fa-edit"
                        style={{ cursor: "not-allowed", color: "#808080b3" }}
                        id={`edittooltip${row.original.Id}`}
                      ></i>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="mytooltip">
                      <i
                        className=" fs-6 fas fa-edit btn-edit"
                        id={`edittooltip${row.original.Id}`}
                        onClick={() => editRow(row.original.Id)}
                      ></i>
                      <span className="mytext">Edit</span>
                    </li>
                  </>
                )
              }


              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => handleDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData, TotalRecords]
  )

  // redirect create product handler
  const handleUserClicks = () => {
    navigate(routes.createProduct)
  }

  function writeDataToExcel() {
    setIsLoading(true)
    let header = ["Sr. No.", "Code", "Product Name", "Category", "Rent", "MRP", "Color", "Size", "Create Date",];
    const mainTitle = "Product Stock";
    const widthObj = [
      {
        Key: "A",
        Value: 10,
      },
      {
        Key: "B",
        Value: 15,
      },
      {
        Key: "C",
        Value: 25,
      },
      {
        Key: "D",
        Value: 15,
      },
      {
        Key: "E",
        Value: 10,
      },
      {
        Key: "F",
        Value: 10,
      },
      {
        Key: "G",
        Value: 10,
      },
      {
        Key: "H",
        Value: 10,
      },
      {
        Key: "I",
        Value: 12,
      },
    ]

    const extention = "Product_Stock.xlsx"
    postApi("product/list", {
      DisplayStart: 1,
      PageSize: 1000000,
      SearchText: searchText,
      CategoryId: parseInt(categoryId),
      SortColumn: "",
      SortOrder: "",
      IsRetire: isRetire,
      IsNotInStock: isInStock,
      IsWashing: isWashing,
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTotalRecords(data.TotalRecords)
        let excelSheetData = []
        let SrNo = 0
        for (let index = 0; index < data.ProductList.length; index++) {
          SrNo = SrNo + 1
          let dataObj = {
            Sr: SrNo,
            Code: data.ProductList[index].Code,
            ProductName: data.ProductList[index].Name,
            Category: data.ProductList[index].CategoryName,
            Rent: data.ProductList[index].Rent,
            MRP: data.ProductList[index].MRP || 0,
            Color: data.ProductList[index].ColorName || "N/A",
            Size: data.ProductList[index].SizeName || "N/A",
            CreatedOn: dateFormat(data.ProductList[index].CreatedOn.split("T")[0],
              "dd/MM/yyyy"),
          }
          excelSheetData.push(dataObj)
        }
        var fields = Object.keys(excelSheetData[0]);
        var sheetData = excelSheetData.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName];
          });
        });
        sheetData.unshift(header);
        exportDataExcel(sheetData, mainTitle, widthObj, extention, 0, 0, 0, 0, 0, 0)

      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <ProductTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              isCreateProduct={true}
              handleUserClick={handleUserClicks}
              customPageSize={20}
              writeDataToExcel={writeDataToExcel}
              className="custom-header-css"
              filterData={filterData}
              pagePerSize={pagePerSize}
              isLoading={isLoading}
              TotalRecords={TotalRecords}
              categories={categories}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
Products.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Products
