import { Link, useLocation, useNavigate } from 'react-router-dom'
import Compressor from "compressorjs"
import breadcrumbs from 'common/Breadcrumbs'
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import useApi from 'common/ApiMiddlewere'
import noImage from "../../../../assets/images/no_image.jpg"
import { toast } from 'react-hot-toast'
import Slider from "react-slick";
import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dropzone from 'react-dropzone'
import DeleteModel from 'common/deleteModel/deleteModel'
import LightboxModal from "./LightboxModal"
import { routes } from 'routes/path'


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const ProductCatalogue = () => {
    const { getApi, postApi } = useApi()
    let { pathname } = useLocation()
    // const navigate = useNavigate()
    const [screeLoad, setscreeLoad] = useState(false)
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState(0)
    const [catalogueData, setCatalogueData] = useState([])
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const [openImage, setOpenImage] = useState()
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [pagesize, setPagesize] = useState(12)
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)
    const [isLoading, setIsLoading] = useState(false)
    const [totalRecords, setTotalRecords] = useState(0)
    const [searchText, setSearchText] = useState('')
    const [isIpad, setIsIpad] = useState(window.innerWidth < 924)
    const [zoomLevel, setZoomLevel] = useState(1);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const margin = {
        marginTop: isMobile ? "8px" : "21px",
    }

    const handleImageClick = (index, code) => {
        setActiveImageIndex(index);
        setIsSliderOpen(true);
        setOpenImage(index)
    };

    const handleSliderClose = () => {
        setIsSliderOpen(false);
    };

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (index) => setActiveImageIndex(index),
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const handlePagesize = () => {
        setPagesize(pagesize + 12)
    }

    useEffect(() => {
        setscreeLoad(true)
        getApi("Category/category-lookup").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    Key: 0,
                    Value: "All"
                });
                setCategories(data)
                setCategoryId(data[0]?.Key)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
        })
    }, [])

    const productCatalogueApi = () => {
        setIsLoading(true)
        setscreeLoad(true)
        postApi("Product/product-gallery", {
            categoryId: categoryId,
            displayStart: 1,
            pageSize: pagesize,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setCatalogueData(data.ProductList)
                setTotalRecords(data.TotalRecords)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setscreeLoad(false)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        productCatalogueApi()
    }, [categoryId, pagesize, searchText])

    useEffect(() => {
        if (activeImageIndex == (catalogueData.length - 1)) {
            setPagesize(pagesize + 12)
        }
    }, [activeImageIndex])

    const handleImageDoubleClick = () => {
        // Toggle fullscreen mode on double-click
        setZoomLevel(zoomLevel === 1 ? 1.7 : 1);
        setIsFullScreen(!isFullScreen);
    };

    // const images = catalogueData.map((data) => data.Image)
    const images = catalogueData.map((data) => {
        if (data.Image === '') {
            return noImage; // Replace empty image URLs with the noImage placeholder
        } else {
            return data.Image;
        }
    });

    const FullScreenImageModal = ({ image, onClose }) => {
        return ReactDOM.createPortal(
            <div className={`full-screen-modal-overlay ${isFullScreen ? 'fullscreen' : ''}`}
                onDoubleClick={handleImageDoubleClick}
            >
                <div className="full-screen-modal-content">
                    <button className="close-button" onClick={onClose}>
                        &#x2715;
                    </button>
                    <Slider {...sliderSettings} initialSlide={activeImageIndex}>
                        {catalogueData.map((data, key) =>
                        (
                            <>
                                <div key={`fullscreen-slider-item-${key}`} className={`d-flex justify-content-center ${isFullScreen ? 'fullscreen-image' : ''}`}>
                                    <img
                                        src={data?.Image ? data.Image : noImage}
                                        alt={`Product Image ${key}`}
                                        style={{
                                            width: isMobile ? "300px" : "550px",
                                            height: isMobile ? "300px" : "450px",
                                            alignItems: "center",
                                            objectFit: "contain",
                                            transform: `scale(${zoomLevel})`, // Apply zoom using CSS transform
                                        }}
                                    />
                                </div>
                                <div className="mt-3 text-center" style={{ color: "black", display: "flex", justifyContent: "center" }}>
                                    <h5 className="my-0" style={{ display: "flex", justifyContent: "center" }}>
                                        <span style={{ fontSize: "18px", backgroundColor: "white", width: "150px", padding: "8px" }}>{data?.Code}</span>
                                    </h5>
                                </div>

                            </>
                        ))}
                    </Slider>
                </div>
            </div>,
            document.body
        );
    };

    const toBase64 = file =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })

    function handleAcceptedFiles(acceptedFiles, productId) {
        const selectedFile = acceptedFiles[0];

        new Compressor(selectedFile, {
            quality: 0.4,
            success: async (compressedResult) => {

                const ImageBase64 = await toBase64(compressedResult);

                if (productId) {
                    catalogueData.forEach(function (data) {
                        if (data.ProductId === productId) {
                            data.Image = URL.createObjectURL(compressedResult)
                            setscreeLoad(false)
                        }

                        DeleteModel.confirmDeleteInquiry({
                            title: "Are you sure? ",
                            text: "You want to upload this image? ",
                            isMobile,
                        }).then(async response => {
                            if (response == true) {
                                setscreeLoad(true)
                                postApi("Product/add-update-productImage", {
                                    productId: productId,
                                    image: ImageBase64?.split(",")[1],
                                    oldImageUrl: data?.Image ? data.Image : '',
                                }).then(async ({ data, status }) => {
                                    if (status === 200) {
                                        toast.success("successfully Upload", {
                                            style: {
                                                fontSize: "15px",
                                            },
                                        })
                                    }
                                    else {
                                        toast.error(data, {
                                            style: {
                                                fontSize: "15px",
                                            },
                                        })
                                        productCatalogueApi()
                                    }
                                    setscreeLoad(false)
                                })
                            } else {
                                productCatalogueApi()
                            }
                        })
                    });
                }


            },
        });
    }

    document.title = `Product Catelogue | ${localStorage.getItem("ShopName")}`

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                    {screeLoad && (
                        <div className="loading-overlay is-active">
                            <span className="fas fa-spinner fa-3x fa-spin"></span>
                        </div>
                    )}
                    <Breadcrumbs
                        parent={breadcrumbs[pathname].parent}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                        parentLink={breadcrumbs[pathname].parentLink}
                    />

                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={isIpad ? "3" : "3"}>
                                    <div className="mb-2">
                                        <FormGroup className="mb-2">
                                            <Label
                                                htmlFor="validationCustom02"
                                                className="required"
                                            >
                                                Category
                                            </Label>
                                            <Input
                                                type="select"
                                                id="validationCustom02"
                                                className="form-control"
                                                name="Category"
                                                onChange={e => setCategoryId(e.target.value, setPagesize(12))}
                                                value={categoryId}
                                            >
                                                {categories.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.Key}>
                                                            {item.Value}
                                                        </option>
                                                    )
                                                })}
                                            </Input>
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={isIpad ? "4" : "3"}>
                                    <div className="mb-2" style={margin}>
                                        <FormGroup className="mb-2">
                                            <input
                                                onChange={e => {
                                                    setSearchText(e.target.value)
                                                }}
                                                id="search-bar-0"
                                                type="text"
                                                className="form-control"
                                                placeholder={"Search Code…"}
                                                value={searchText || ""}
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>

                            {catalogueData.length ?
                                <div>
                                    <Row>
                                        {catalogueData?.map((data, key) => (
                                            <Col xl={3} sm={6} key={"_col_" + key}>
                                                <Card>
                                                    <CardBody>
                                                        <div className="product-img position-relative"
                                                            onClick={() => handleImageClick(key)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src={data?.Image ? data.Image : noImage}
                                                                alt={noImage}
                                                                style={{ objectFit: "cover", height: "320px", width: "250px", textAlign: "center", alignItems: "center" }}
                                                                className="img-fluid d-block"
                                                            />
                                                        </div>
                                                        {/* <div className="mt-3 text-center d-flex justify-content-center gap-3">
                                                            <div style={{ marginTop: "5px" }}>
                                                                <h5 className="">
                                                                    <span className='mt-2'>  <b>{data?.Code}</b></span>
                                                                </h5>
                                                            </div>
                                                            <div>
                                                                <Dropzone
                                                                    onDrop={acceptedFiles => {
                                                                        handleAcceptedFiles(acceptedFiles, data.ProductId)
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="">
                                                                            <div
                                                                                className=""
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} />
                                                                                <div className="">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        className="btn btn-primary btn-sm"
                                                                                        type="button"
                                                                                    >
                                                                                        {data?.Image ? "Change" : "Upload"}
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>


                                                            </div>
                                                        </div> */}



                                                        <div className="mt-3 text-center d-flex justify-content-center gap-2">
                                                            <div style={{ marginTop: "3px" }}>
                                                                <h5 className="">
                                                                    <span className='mt-2'>  <b>{data?.Name}</b></span>
                                                                </h5>
                                                            </div>
                                                            <div style={{ marginTop: "3px" }}>
                                                                <h5 className="">
                                                                    <span className='mt-2'>  <b>({data?.Code})</b></span>
                                                                </h5>
                                                            </div>
                                                        </div>

                                                        <div className="mt-1 text-center d-flex justify-content-center gap-3">
                                                            <div>
                                                                <div className="">
                                                                    <div className="">
                                                                        <Link to={`${routes.productHistory}?productCode=${data?.Code}`}>
                                                                            <Button
                                                                                color="primary"
                                                                                className="btn btn-primary btn-sm"
                                                                                type="button"
                                                                            >
                                                                                <i className=" fs-6 fas fa-info-circle" style={{ marginRight: "3px", padding: "2px" }}></i>
                                                                                {"Detail"}
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <Dropzone
                                                                    onDrop={acceptedFiles => {
                                                                        handleAcceptedFiles(acceptedFiles, data.ProductId)
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div className="">
                                                                            <div
                                                                                className=""
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input {...getInputProps()} />
                                                                                <div className="">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        className="btn btn-primary btn-sm"
                                                                                        type="button"
                                                                                    >
                                                                                        <i className="fs-6  far fa-image" style={{ marginRight: "3px", padding: "2px" }}></i>
                                                                                        {data?.Image ? "Change" : "Upload"}
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>
                                                        </div>




                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                    {isSliderOpen && (
                                        <FullScreenImageModal image={openImage} onClose={handleSliderClose} />
                                    )}
                                    {catalogueData.length != totalRecords && <Row>
                                        <Col md="12" >
                                            {isLoading ?
                                                <button type="button" className="btn btn-primary" style={{ width: "100%" }}>
                                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                                    Loading
                                                </button>
                                                :
                                                <Button
                                                    color="primary"
                                                    className="btn btn-primary "
                                                    type="button"
                                                    onClick={handlePagesize}
                                                    style={{ width: "100%" }}
                                                >
                                                    Load more
                                                </Button>
                                            }
                                        </Col>
                                    </Row>}
                                </div>
                                :
                                <Row>
                                    <Col md="12">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {
                                                "No Record Found"
                                            }
                                        </div>

                                    </Col>
                                </Row>
                            }
                        </CardBody>


                        {/* <LightboxModal
                            images={images}
                            photoIndex={activeImageIndex}
                            setPhotoIndex={setActiveImageIndex}
                            isOpen={isSliderOpen}
                            onCloseRequest={() => setIsSliderOpen(false)}
                        /> */}

                    </Card>


                </Container>
            </div>
        </React.Fragment>
    )
}

export default ProductCatalogue