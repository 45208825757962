import React, { useEffect, useMemo, useState } from "react";
import ReactEcharts from "echarts-for-react";
import toast from "react-hot-toast"
import { Card, CardBody } from "reactstrap";
import useApi from "common/ApiMiddlewere";
import { dateFormat } from "pages/Common";
import ReminderTableDashboard from "./ReminderTableDashboard";
import DeleteModel from "common/deleteModel/deleteModel";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "routes/path";
import ErrorModel from "common/errorModel/errorModel";
// import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";

const Reminder = () => {

    const { postApi, deleteApi } = useApi()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [tableData, setTableData] = useState([])
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)

    useEffect(() => {
        postApi("Reminder/list", {
            displayStart: 1,
            pageSize: 5,
            searchText: '',
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setTableData(data.ReminderList)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }, [])

    const handleDelete = (reminderId) => {
        if (reminderId) {
            DeleteModel.confirmDeleteInquiry({
                title: "Are you sure? ",
                text: "You want to complete this reminder permanently?",
                isMobile,
            }).then(response => {
                if (response == true) {
                    deleteApi(`Reminder/delete?Id=${reminderId}`).then(
                        ({ data, status }) => {
                            if (status === 200) {
                                const updatedArray = tableData
                                    .map(obj => (obj.Id !== reminderId ? obj : null))
                                    .filter(Boolean)
                                setTableData(updatedArray)
                                toast.success("successfully Completed", {
                                    style: {
                                        fontSize: "15px",
                                    },
                                })
                            } else {
                                ErrorModel.errormodel({
                                    title: "Verify Reminder",
                                    text: data,
                                })
                            }
                        }
                    )
                } else {
                }
            })
        }
    }
    const handleReminder = () => {
        navigate(routes.reminder)
    }

    const columns = useMemo(
        () => [
            {
                Header: "Description",
                accessor: "Description",
            },
            {
                Header: "Assignee",
                accessor: "Assignee",
                width: "12%",
            },
            {
                Header: "Date",
                accessor: "ReminderDate",
                width: "10%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.ReminderDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : row.original.ReminderDate == ""
                                ? "N/A" : dateFormat(
                                    row.original.ReminderDate.split("T")[0],
                                    "dd/MM/yyyy"
                                )}
                    </p>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Complete
                    </div>
                ),
                width: "8%",
                accessor: "action",
                disableFilters: true,
                Cell: ({ row }) => {
                    return (
                        <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
                            <li className="mytooltip">
                                <i
                                    className=" far fa-check-circle btn-edit"
                                    id={`edittooltip${row.original.Id}`}
                                    style={{ cursor: "pointer", fontSize: "15px", fontWeight: "500" }}
                                    onClick={() => handleDelete(row.original.Id)}
                                ></i>
                                <span className="mytext">Complete</span>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [tableData]
    )



    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h3 onClick={handleReminder} style={{ cursor: "pointer" }} className="card-title mb-4">Reminders</h3>
                    <ReminderTableDashboard
                        columns={columns}
                        data={tableData}
                        customPageSize={5}
                        className="custom-header-css"
                        IsViewMore={true}
                        handleReminder={handleReminder}
                    />

                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default Reminder;
