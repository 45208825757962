import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import useApi from "common/ApiMiddlewere";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { dateFormat } from "pages/Common";

const CreateItemModel = ({
    modal,
    toggle,
    isEdit,
    ItemsApi,
    flag,
    itemEditData,
    setIsEdit,
    itemId,
    setCreateItemId,
}) => {
    const { postApi, putApi } = useApi()
    const [formSumitLoading, setformSumitLoading] = useState(false)
    const [billAmountData, setBillAmountData] = useState()
    const [discountAmount, setDiscountAmount] = useState(0)
    const [flagForAllDelivery, setflagForAllDelivery] = useState(false)
    const [isRefund, setIsRefund] = useState(false)
    const [validationFlag, setValidationFlag] = useState(false)
    const [paymentMethod, setpaymentMethod] = useState("1")
    const [isError, setIsError] = useState(false)
    const [screenLoad, setScreenLoad] = useState(false)

    // Items Validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            Name: isEdit ? itemEditData?.Name : "",
            ItemCode: isEdit ? itemEditData?.ItemCode : "",
            MRP: isEdit ? itemEditData?.MRP : 0,
            CGST: isEdit ? itemEditData?.CGST : 0,
            SGST: isEdit ? itemEditData?.SGST : 0,
            OpeningStock: isEdit ? itemEditData?.OpeningStock : 0,
            IGST: isEdit ? itemEditData?.IGST : 0,
        },
        validationSchema: Yup.object({
            Name: Yup.string().required("The name is required"),
            ItemCode: Yup.string().required("The item code is required"),
        }),
        onSubmit: values => {

            if (values.CGST && !values.SGST) {
                setIsError(true);
                return false
            }

            if (values.SGST && !values.CGST) {
                setIsError(true)
                return false
            }

            setformSumitLoading(true)
            if (values) {
                setIsError(false)
                isEdit
                    ? putApi("Items/update", {
                        id: itemId,
                        name: values.Name,
                        mrp: parseFloat(values.MRP) || 0,
                        itemCode: values.ItemCode || "",
                        cgst: parseFloat(values.CGST) || 0,
                        sgst: parseFloat(values.SGST) || 0,
                        igst: parseFloat(values.IGST) || 0,
                    }).then(({ data, status }) => {
                        setformSumitLoading(false)
                        if (status == 400) {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        } else {
                            toast.success("successfully updated", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            if (flag == "item") {
                                ItemsApi()
                            }
                            validation.handleReset()
                        }
                    })
                    :
                    postApi("Items/create", {
                        name: values.Name,
                        mrp: parseFloat(values.MRP) || 0,
                        openingStock: parseFloat(values.OpeningStock) || 0,
                        itemCode: values.ItemCode || "",
                        cgst: parseFloat(values.CGST) || 0,
                        sgst: parseFloat(values.SGST) || 0,
                        igst: parseFloat(values.IGST) || 0,
                    }
                    ).then(({ data, status }) => {
                        setformSumitLoading(false)
                        if (status == 200) {
                            if (flag == "sale") {
                                setCreateItemId(data)
                            }
                            toast.success("successfully created", {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                            toggle()
                            if (flag == "item") {
                                ItemsApi()
                            }
                            validation.handleReset()
                        } else {
                            toast.error(data, {
                                style: {
                                    fontSize: "15px",
                                },
                            })
                        }
                    })
            }
        },
    })

    const handleGstPer = (CGST, SGST, IGST) => {
        validation.setFieldValue("CGST", CGST)
        validation.setFieldValue("SGST", SGST)

        if (parseFloat(CGST) > 0 || parseFloat(SGST) > 0) {
            validation.setFieldValue("IGST", '')
        } else {
            validation.setFieldValue("CGST", CGST ? CGST : '')
            validation.setFieldValue("SGST", SGST ? SGST : '')
            validation.setFieldValue("IGST", IGST ? IGST : '')
        }
    }

    const handleIGSTPer = (CGST, SGST, IGST) => {
        validation.setFieldValue("IGST", IGST)
        if (parseFloat(IGST) > 0) {
            validation.setFieldValue("CGST", '')
            validation.setFieldValue("SGST", '')
        }
        else {
            validation.setFieldValue("CGST", CGST ? CGST : '')
            validation.setFieldValue("SGST", SGST ? SGST : '')
            validation.setFieldValue("IGST", IGST ? IGST : '')
        }
    }

    // cancle handler
    const handleCancle = () => {
        toggle()
        setIsEdit(false)
    }

    // allow only number key
    const handleKeyPress = event => {
        const charCode = event.which || event.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode != 46) {
            event.preventDefault();
        }
    };

    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered={true}
                size="small"
                style={{ width: "450px" }}
            >
                <div className="modal-content">
                    <ModalHeader toggle={toggle} tag="h5">

                        {isEdit ? "Update Items" : " Create Items"}
                    </ModalHeader>
                    <ModalBody>
                        <Form
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                            onReset={e => {
                                e.preventDefault
                                validation.handleReset()
                                return false
                            }}
                        >
                            <Row>
                                <Col md={12}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03" className="required">
                                            Name
                                        </Label>
                                        <Input
                                            name="Name"
                                            placeholder="Name"
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.Name || ""}
                                            invalid={
                                                validation.touched.Name && validation.errors.Name
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.Name && validation.errors.Name ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.Name}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom03"
                                            className="required"
                                        >
                                            Item Code
                                        </Label>
                                        <Input
                                            name="ItemCode"
                                            placeholder="Item Code"
                                            disabled={isEdit}
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.ItemCode || ""}
                                            invalid={
                                                validation.touched.ItemCode &&
                                                    validation.errors.ItemCode
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.ItemCode &&
                                            validation.errors.ItemCode ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.ItemCode}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom03"
                                        >
                                            MRP
                                        </Label>
                                        <Input
                                            name="MRP"
                                            placeholder="0.00"
                                            type="text"
                                            className="form-control-sm"
                                            onChange={validation.handleChange}
                                            onKeyPress={handleKeyPress}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.MRP}
                                            invalid={
                                                validation.touched.MRP &&
                                                    validation.errors.MRP
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.MRP &&
                                            validation.errors.MRP ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.MRP}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label
                                            htmlFor="validationCustom01"
                                            className="form-label"
                                        >
                                            Opening Stock
                                        </Label>
                                        <Input
                                            name="OpeningStock"
                                            className="form-control-sm"
                                            placeholder="0.00"
                                            type="text"
                                            disabled={isEdit}
                                            onChange={validation.handleChange}
                                            onKeyPress={handleKeyPress}
                                            onBlur={validation.handleBlur}
                                            onFocus={(e) => { e.target.select() }}
                                            value={validation.values.OpeningStock}
                                            invalid={
                                                validation.touched.OpeningStock &&
                                                    validation.errors.OpeningStock
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.OpeningStock &&
                                            validation.errors.OpeningStock ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.OpeningStock}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            CGST(%)
                                        </Label>
                                        <Input
                                            name="CGST"
                                            placeholder="0.00"
                                            type="text"
                                            className="form-control-sm"
                                            onKeyPress={handleKeyPress}
                                            onChange={e => handleGstPer(e.target.value, validation.values.SGST, validation.values.IGST)}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.CGST}
                                            invalid={
                                                isError && validation.values.SGST && !validation.values.CGST || validation.values.CGST > 100
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {isError && validation.values.SGST && !validation.values.CGST || validation.values.CGST > 100 && isError ? (
                                            <FormFeedback type="invalid">
                                                {'CGST required'}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            SGST(%)
                                        </Label>
                                        <Input
                                            name="SGST"
                                            placeholder="0.00"
                                            type="text"
                                            onKeyPress={handleKeyPress}
                                            className="form-control-sm"
                                            onChange={e => handleGstPer(validation.values.CGST, e.target.value, validation.values.IGST)}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.SGST}
                                            invalid={
                                                isError && validation.values.CGST && !validation.values.SGST || validation.values.SGST > 100
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {isError && validation.values.CGST && !validation.values.SGST || validation.values.SGST > 100 && isError ? (
                                            <FormFeedback type="invalid">
                                                {"SGST reuired"}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="mb-2">
                                        <Label htmlFor="validationCustom03">
                                            IGST(%)
                                        </Label>
                                        <Input
                                            name="IGST"
                                            placeholder="0.00"
                                            type="text"
                                            className="form-control-sm"
                                            onKeyPress={handleKeyPress}
                                            onChange={e => handleIGSTPer(validation.values.CGST, validation.values.SGST, e.target.value)}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.IGST}
                                            invalid={
                                                validation.touched.IGST && validation.errors.IGST || validation.values.IGST > 100
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.IGST && validation.errors.IGST || validation.values.IGST > 100 ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.IGST}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-end">
                                {isEdit ? (
                                    <Button
                                        color="secondary"
                                        className="btn btn-primary me-2 btn-sm"
                                        onClick={handleCancle}
                                    >
                                        Cancle
                                    </Button>
                                ) : (
                                    <Button
                                        color="secondary"
                                        className="btn btn-primary me-2 btn-sm "
                                        type="reset"
                                    >
                                        Reset
                                    </Button>
                                )}

                                <>
                                    {formSumitLoading ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary btn-sm"
                                        >
                                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                                            Loading
                                        </button>
                                    ) : (
                                        <Button
                                            color="primary"
                                            className="btn btn-primary btn-sm "
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </>
                            </div>
                        </Form>
                    </ModalBody>
                </div>
            </Modal>
        </div >
    )
}


export default CreateItemModel;
