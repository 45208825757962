// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//import components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate } from "react-router-dom"
import {
    Card,
    CardBody,
    Input,
    FormGroup,
} from "reactstrap"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import { routes } from "routes/path"
import WashingProductTable from "./WashingProductsTable"

function WashingProducts() {
    let { pathname } = useLocation()
    const { deleteApi, postApi, putApi, getApi } = useApi()
    document.title = `WashingProducts | ${localStorage.getItem("ShopName")}`

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [TotalRecords, setTotalRecords] = useState(0)
    const [categories, setCategories] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [displayStart, setDisplayStart] = useState(1)
    const [serchText, setSearchText] = useState('')
    const [categoryId, setCategoryId] = useState(0)

    useEffect(() => {
        setIsLoading(true)
        getApi("Category/category-lookup").then(({ data, status }) => {
            if (status === 200) {
                data.unshift({
                    Value: "All Category",
                    Key: 0
                });
                setCategories(data)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })
    }, [])

    // pagination
    const pagePerSize = (pageSize, pagePerIndex, serchValue, categoryId) => {
        setRowsPerPage(pageSize)
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setCategoryId(categoryId)
    }

    const ProductsListApi = () => {
        setIsLoading(true)
        postApi("product/list", {
            DisplayStart: displayStart,
            PageSize: rowsPerPage,
            SearchText: serchText,
            CategoryId: parseInt(categoryId),
            SortColumn: "",
            SortOrder: "",
            IsRetire: false,
            IsNotInStock: false,
            IsWashing: true,
        }).then(({ data, status }) => {
            if (status === 200) {
                setTableData(data.ProductList)
                setTotalRecords(data.TotalRecords)
                setIsLoading(false)
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        ProductsListApi()
    }, [categoryId, serchText, displayStart, rowsPerPage])

    const handleIsWashing = (id, IsActive, e) => {
        if (id) {
            putApi("Product/washing", { id: String(id) }).then(({ data, status }) => {
                e.preventDefault()
                if (status === 200) {
                    ProductsListApi()
                } else {
                    $("#IsActive_" + id + "").prop("checked", IsActive)
                    ErrorModel.errormodel({
                        title: "Verify Product",
                        text: data,
                    })
                }
            })
        }
    }

    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Code",
                accessor: "Code",
                width: "8%",
            },
            {
                Header: "Name",
                accessor: "Name",
                Cell: ({ row }) => (
                    <span>
                        {row.original.Name ? row.original.Name : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Category",
                accessor: "CategoryName",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.CategoryName ? row.original.CategoryName : "N/A"}
                    </span>
                ),
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Rent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        MRP
                    </div>
                ),
                accessor: "MRP",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.MRP || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Color",
                accessor: "ColorName",
                width: "6%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.ColorName ? row.original.ColorName : "N/A"}
                    </span>
                ),
            },
            {
                Header: "Size",
                accessor: "SizeName",
                width: "5%",
                Cell: ({ row }) => (
                    <span>{row.original.SizeName ? row.original.SizeName : "N/A"}</span>
                ),
            },
            {
                Header: "Date",
                accessor: "CreatedOn",
                width: "8%",
                Cell: ({ row }) => (
                    <span>
                        {row.original.CreatedOn.split("T")[0] == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(row.original.CreatedOn, "dd/MM/yyyy")}
                    </span>
                ),
            },
            {
                Header: () => (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        Is Washing
                    </div>
                ),
                width: "7%",
                accessor: "IsWashing",
                Cell: ({ row }) => (
                    <FormGroup switch className="d-flex justify-content-center">
                        <Input
                            type="switch"
                            id={`isWashing${row.original.Id}`}
                            disabled={row.original.IsRetire == true}
                            defaultChecked={row.original.IsWashing}
                            onClick={e => {
                                handleIsWashing(row.original.Id, row.original.IsWashing, e)
                            }}
                        />
                    </FormGroup>
                ),
            },
        ],
        [tableData, TotalRecords]
    )

    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <WashingProductTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            isCreateProduct={true}
                            customPageSize={20}
                            className="custom-header-css"
                            pagePerSize={pagePerSize}
                            isLoading={isLoading}
                            TotalRecords={TotalRecords}
                            categories={categories}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
WashingProducts.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default WashingProducts
