import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import BookedOrdersTable from "./BookedOrdersTable"
import { useSelector } from "react-redux"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import TransactionModel from "pages/Transiction/TransactionModel"
import { exportDataExcel } from "pages/ExportData"

function BookedOrders() {
  const { postApi } = useApi()
  let { pathname } = useLocation()
  // useStates
  const [tableData, setTableData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [TotalRecords, setTotalRecords] = useState(0)
  const [TotalDiscount, setTotalDiscount] = useState(0)
  const [TotalDeposit, setTotalDeposit] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [TotalAdvance, setTotalAdvance] = useState(0)
  const [totalPayableAmt, setTotalPayableAmt] = useState(0)
  const [totalCash, setTotalCash] = useState(0)
  const [totalBank, setTotalBank] = useState(0)
  const [TotalGST, setTotalGST] = useState(0)
  const [displayStart, setDisplayStart] = useState(1)
  const [screeLoad, setscreeLoad] = useState(false)
  const [transactionModelFlag, setTransactionModelFlag] = useState(false)
  const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", productId: "" })
  const [billNo, setBillNo] = useState()
  const [modal, setModal] = useState(false)
  const [date, setdate] = useState([new Date(), new Date()])
  const filterData = textFilter => { }
  const financeAmountShowHide = useSelector(state => state.ReduxData.finaceAmountShow);

  // sales list api useEffect
  useEffect(() => {
    if (date[1] != undefined) {
      bookedOredrsReportApi()
    }
  }, [date, rowsPerPage, searchText, displayStart])

  // sales list api
  const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
  }

  const bookedOredrsReportApi = () => {
    setscreeLoad(true)
    postApi("Report/booked-report", {
      fromDate: date[0] ? dateFormat(
        date[0],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      toDate: date[1] ? dateFormat(
        date[1],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setTableData(data.ListBookedReport)
        setTotalAmount(data.TotalBillAmount)
        setTotalRecords(data.TotalRecord)
        setTotalDiscount(data.TotalDiscount)
        setTotalAdvance(data.TotalAdvance)
        setTotalDeposit(data.TotalDeposit)
        setTotalGST(data.TotalGST)
        setTotalCash(data.TotalCash)
        setTotalBank(data.TotalBank)
        setTotalPayableAmt(data.TotalBillAmount - data.TotalAdvance)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
      setscreeLoad(false)
    })
  }

  function writeDataToExcel() {
    setIsLoading(true)
    let header = ["Bill No.", "Customer Name", "Bill Date", "Advance", "Taxable Amt", "CGST Amt.", "SGST Amt.", "IGST Amt.", "Total Amt.", "Payment Mode"];
    const mainTitle = "Booked Orders Report";
    const widthObj = [{
      Key: "A",
      Value: 12,
    },
    {
      Key: "B",
      Value: 25,
    },
    {
      Key: "C",
      Value: 11,
    },
    {
      Key: "D",
      Value: 12,
    },
    {
      Key: "E",
      Value: 12,
    },
    {
      Key: "F",
      Value: 12,
    },
    {
      Key: "G",
      Value: 12,
    },
    {
      Key: "H",
      Value: 12,
    },
    {
      Key: "I",
      Value: 12,
    },
    {
      Key: "J",
      Value: 15,
    },
    ]
    const extention = "Booked_Orders_Report.xlsx"
    postApi("Report/booked-report", {
      fromDate: date[0] ? dateFormat(
        date[0],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      toDate: date[1] ? dateFormat(
        date[1],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      displayStart: 1,
      pageSize: 10000,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        let excelSheetData = []
        let totalCGSTAmt = 0
        let totalSGSTAmt = 0
        let totalIGSTAmt = 0
        let totalTaxableAmt = 0
        for (let index = 0; index < data.ListBookedReport.length; index++) {
          totalCGSTAmt += parseFloat(data.ListBookedReport[index].CGSTAmount)
          totalSGSTAmt += parseFloat(data.ListBookedReport[index].SGSTAmount)
          totalIGSTAmt += parseFloat(data.ListBookedReport[index].IGSTAmount)
          totalTaxableAmt += parseFloat(data.ListBookedReport[index].Rent)
          let dataObj = {
            BillNo: data.ListBookedReport[index].BillNo,
            CustomerName: data.ListBookedReport[index].CustomerName,
            BillDate: dateFormat(data.ListBookedReport[index].BillDate.split("T")[0],
              "dd/MM/yyyy"),
            Advance: data.ListBookedReport[index].Advance,
            TaxableAmount: data.ListBookedReport[index].Rent,
            CGSTAmount: data.ListBookedReport[index].CGSTAmount || 0,
            SGSTAmount: data.ListBookedReport[index].SGSTAmount || 0,
            IGSTAmount: data.ListBookedReport[index].IGSTAmount || 0,
            TotalAmount: data.ListBookedReport[index].Amount,
            PaymentMode: data.ListBookedReport[index].PaymentMode == "1"
              ? "Cash" : "Bank",
          }
          excelSheetData.push(dataObj)
        }
        var fields = Object.keys(excelSheetData[0]);
        var sheetData = excelSheetData.map(function (row) {
          return fields.map(function (fieldName) {
            return row[fieldName];
          });
        });
        sheetData.unshift(header);
        exportDataExcel(sheetData, mainTitle, widthObj, extention, data.TotalAdvance, totalTaxableAmt, totalCGSTAmt, totalSGSTAmt, totalIGSTAmt, data.TotalBillAmount,)

      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  const toggle = () => {
    setModal(!modal)
  }

  const handleViewBooking = bookingId => {
    setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
    toggle()
  }

  const toggleTransaction = () => {
    setTransactionModelFlag(!transactionModelFlag)
  }

  // view all transaction handler
  const handleTransaction = (bookingId, billNo) => {
    if (bookingId) {
      setBillNo(billNo)
      setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
      toggleTransaction()
    }
  }

  // header & accessor
  const columns = useMemo(
    () => [
      {
        Header: "Bill No.",
        accessor: "BillNo",
        width: "4%",
        Cell: ({ row }) => {
          return <>
            {row.original.TotalProduct == 0 ? (
              <div style={{ color: "#E94560" }} >{row.original.BillNo}</div>
            ) : (
              <div
                style={{ cursor: "pointer", color: "#556ee6" }}
                onClick={() => {
                  handleViewBooking(row.original.Id)
                }}
              >
                {row.original.BillNo}
              </div>
            )}
          </>
        },
      },
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "No. of Product ",
        accessor: "NumberOfProduct",
        Cell: ({ row }) => (
          <p className=" mb-0" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          >
            {row.original.NumberOfProduct}
          </p>
        ),
        width: "5%",
      },
      {
        Header: "Bill Date",
        accessor: "BillDate",
        width: "8%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.BillDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(row.original.BillDate.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Advance
          </div>
        ),
        accessor: "Advance",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Advance || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Taxable Amt.
          </div>
        ),
        accessor: "Rent",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Rent || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            CGST Amt.
          </div>
        ),
        accessor: "CGSTAmount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.CGSTAmount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            SGST Amt.
          </div>
        ),
        accessor: "SGSTAmount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.SGSTAmount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            IGST Amt.
          </div>
        ),
        accessor: "IGSTAmount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.IGSTAmount || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}
          >
            Total Amt.
          </div>
        ),
        accessor: "Amount",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}
            >
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row?.original?.Amount || 0)}
            </div>
          )
        },
      },
      {
        Header: "Payment Mode",
        accessor: "PaymentMode",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.PaymentMode == "1"
              ? "Cash"
              : row.original.PaymentMode == "2"
                ? "Bank" : ''}
          </p>
        ),
      },

      // {
      //   Header: () => (
      //     <div style={{
      //       display: "flex",
      //       alignItems: "end",
      //       justifyContent: "end",
      //       textAlign: "end"
      //     }}
      //     >
      //       Discount
      //     </div>
      //   ),
      //   accessor: "Discount",
      //   width: "7%",
      //   Cell: ({ row }) => {
      //     return (
      //       <div style={{
      //         display: "flex",
      //         alignItems: "end",
      //         justifyContent: "end",
      //         textAlign: "end"
      //       }}
      //       >
      //         <i className="mdi mdi-currency-inr"></i>
      //         {new Intl.NumberFormat('en-IN', {
      //           currency: 'INR',
      //           minimumFractionDigits: 2,
      //         }).format(row?.original?.Discount || 0)}
      //       </div>
      //     )
      //   },
      // },
      // {
      //   Header: () => (
      //     <div style={{
      //       display: "flex",
      //       alignItems: "end",
      //       justifyContent: "end",
      //       textAlign: "end"
      //     }}
      //     >
      //       Deposit
      //     </div>
      //   ),
      //   accessor: "Deposit",
      //   width: "7%",
      //   Cell: ({ row }) => {
      //     return (
      //       <div style={{
      //         display: "flex",
      //         alignItems: "end",
      //         justifyContent: "end",
      //         textAlign: "end"
      //       }}
      //       >
      //         <i className="mdi mdi-currency-inr"></i>
      //         {new Intl.NumberFormat('en-IN', {
      //           currency: 'INR',
      //           minimumFractionDigits: 2,
      //         }).format(row?.original?.Deposit || 0)}

      //       </div>
      //     )
      //   },
      // },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              <li className="mytooltip">
                <i
                  className="fas fa-handshake"
                  style={{ color: "#556ee6", cursor: "pointer" }}
                  onClick={() => handleTransaction(row.original.Id, row.original.BillNo)}
                ></i>
                <span className="mytext">Transaction</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )


  document.title = `Booked Ordes | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {screeLoad && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <BookedOrdersTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              customPageSize={10}
              isLoading={isLoading}
              className="custom-header-css"
              pagePerSize={pagePerSize}
              filterData={filterData}
              totalAmount={totalAmount}
              TotalDiscount={TotalDiscount}
              TotalDeposit={TotalDeposit}
              TotalGST={TotalGST}
              totalBank={totalBank}
              totalCash={totalCash}
              totalPayableAmt={totalPayableAmt}
              TotalRecords={TotalRecords}
              financeAmountShowHide={financeAmountShowHide}
              TotalAdvance={TotalAdvance}
              setdate={setdate}
              date={date}
              writeDataToExcel={writeDataToExcel}
            />

            <InvoiceDetalis
              toggle={toggle}
              modal={modal}
              bookingId={BookingIdandStatusId.bookingId}
            />

            <TransactionModel
              modal={transactionModelFlag}
              toggle={toggleTransaction}
              BookingandStatus={BookingIdandStatusId}
              billNo={billNo}
            />

          </CardBody>
        </Card>
      </div>
    </div>
  )
}
BookedOrders.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default BookedOrders
