import React, { useEffect, useState } from 'react'
import breadcrumbs from "common/Breadcrumbs"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Card, CardBody } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import useApi from 'common/ApiMiddlewere'
import { dateFormat } from 'pages/Common'

function ReleseNote() {
    let { pathname } = useLocation()
    const { postApi } = useApi()
    const [releseNoteData, setreleseNoteData] = useState([])
    const [versionAndDate, setversionAndDate] = useState({ version: null, date: null })

    useEffect(() => {
        postApi("Setting/setting-by-keys", {
            key: ["RELEASE_NOTES"],
            userId: 0
        }).then(({ data, status }) => {
            const lines = data[0].Value.split('|');
            const sections = [];
            let currentSection = null;

            for (const line of lines) {
                if (line.startsWith('{') && line.endsWith('}')) {
                    currentSection = line.slice(1, -1);
                    sections.push({ [currentSection]: [] });
                } else if (currentSection) {
                    sections[sections.length - 1][currentSection].push(line);
                }
            }
            setreleseNoteData(sections);
        })
        postApi("Setting/setting-by-keys", {
            key: ["CURRENT_VERSION"],
            userId: 0
        }).then(({ data, status }) => {
            if (status == 200) {
                const [version, date] = data[0]?.Value.split('|');
                const [day, month, year] = date.split('-') || date.split('/');
                const formattedDate = `${month}-${day}-${year}`;
                setversionAndDate({ version, date: dateFormat(formattedDate, "dd MMM yyyy") })
            }
        })
    }, [])

    document.title = `Release Note | ${localStorage.getItem("ShopName")}`

    return (
        <div>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={breadcrumbs[pathname].title}
                        breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                    />
                    <Card>
                        <CardBody>
                            <div className="">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="mt-2">
                                            <div className="mb-4">
                                                <h5 className='mb-4'> <span className="p-2 bg-light shadow rounded text-success"> Version {versionAndDate.version}</span> - <span className='p-2 bg-light shadow rounded text-success'>{versionAndDate.date}</span> </h5>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12">
                                                {releseNoteData.map((section, index) => (
                                                    <div key={index} className="mt-4">
                                                        <h6 className="fw-bold">{Object.keys(section)[0]}</h6>
                                                        <div className="">
                                                            <ul className="pl-3">
                                                                {section[Object.keys(section)[0]].map((item, itemIndex) => (
                                                                    <li key={itemIndex} className="">
                                                                        {item}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                        </CardBody>
                    </Card>
                </div>
            </div>

        </div >
    )
}

export default ReleseNote