


export const Booking_status = [{
    Key: "Booked",
    Value: 1,
    DisplayName: "Booked",
},
{
    Key: "Delivered",
    Value: 2,
    DisplayName: "Delivered",
},
{
    Key: "Return",
    Value: 3,
    DisplayName: "Return",
},
{
    Key: "Cancel",
    Value: 4,
    DisplayName: "Cancel",
},
{
    Key: "Hold",
    Value: 5,
    DisplayName: "Hold",
},
]

export const Booking_Item_status = [{
    Key: "Booked",
    Value: 1,
    DisplayName: "Booked",
},
{
    Key: "Delivered",
    Value: 2,
    DisplayName: "Delivered",
},
{
    Key: "Return",
    Value: 3,
    DisplayName: "Return",
},
{
    Key: "Cancel",
    Value: 4,
    DisplayName: "Cancel",
},
]

export const Booking_status1 = [{
    Key: "Booked",
    Value: 1,
    DisplayName: "Booked",
},
{
    Key: "Delivered",
    Value: 2,
    DisplayName: "Delivered",
},
{
    Key: "Return",
    Value: 3,
    DisplayName: "Return",
},
]


function GetObjectsByValues(...values) {
    const array = [
        {
            Key: "Booked",
            Value: 1,
            DisplayName: "Booked",
        },
        {
            Key: "Delivered",
            Value: 2,
            DisplayName: "Delivered",
        },
        {
            Key: "Return",
            Value: 3,
            DisplayName: "Return",
        },
        {
            Key: "Cancel",
            Value: 4,
            DisplayName: "Cancel",
        },
        {
            Key: "Hold",
            Value: 5,
            DisplayName: "Hold",
        },
        {
            Key: "Settled",
            Value: 6,
            DisplayName: "Settled",
        },
    ];

    return array.filter(obj => values.includes(obj.Value));
}

export default GetObjectsByValues

export function GetObjectsByAccountGroups(...values) {
    const array = [
        {
            Key: "All Accounts",
            Value: 0,
            DisplayName: "All Accounts",
        },
        {
            Key: "Parties",
            Value: 1,
            DisplayName: "Parties",
        },
        {
            Key: "Sales",
            Value: 2,
            DisplayName: "Sales",
        },
        {
            Key: "Rent",
            Value: 3,
            DisplayName: "Rent",
        },
        {
            Key: "Vendors",
            Value: 4,
            DisplayName: "Vendors",
        },
        {
            Key: "Purchase",
            Value: 5,
            DisplayName: "Purchase",
        },
        {
            Key: "Income",
            Value: 6,
            DisplayName: "Income",
        },
        {
            Key: "Expenses",
            Value: 7,
            DisplayName: "Expenses",
        },
    ];

    return array.filter(obj => values.includes(obj.Value));
}
