import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import DeleteModel from "common/deleteModel/deleteModel"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TableContainer from "../../../components/Common/TableContainer"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap"
import InquiryModel from "./InquiryModel"
import FollowupDetails from "./FollowupDetails"
import toast from "react-hot-toast"
import { dateFormat } from "pages/Common"
import useApi from "common/ApiMiddlewere"
import ErrorModel from "common/errorModel/errorModel"
import breadcrumbs from "../../../common/Breadcrumbs"
import { routes } from "routes/path"
import InquiryTable from "./InquiryTable"

function Inquiries() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { deleteApi, postApi } = useApi()
  let { pathname } = useLocation()
  const [modal, setModal] = useState(false)
  const [tableData, setTableData] = useState([])
  const [FollowupModal, setFollowupModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userId, setuserId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [followDetailId, setfollowDetailId] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchText, setSearchText] = useState("")
  const [TotalRecords, setTotalRecords] = useState(0.0)
  const [displayStart, setDisplayStart] = useState(1)
  const [inquiryStatus, setInquiryStatus] = useState(state ? state?.status : 0)
  const [inquiryDateFilterType, setInquiryDateFilterType] = useState(1)
  const [dateDisble, setdateDisble] = useState(state ? false : true)
  const [date, setdate] = useState([new Date(), new Date()])
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  // filterData for inquiries
  const filterData = textFilter => {
    // setSearchText(textFilter)
  }

  // pageSize function
  const pagePerSize = (pageSize, pagePerIndex, serchValue, dateStatusChange) => {
    setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
    setSearchText(serchValue == undefined ? "" : serchValue)
    setRowsPerPage(pageSize)
    setInquiryDateFilterType(dateStatusChange)
  }

  const handleFollowUp = id => {
    setIsEdit(false)
    toggle()
    setuserId(id)
  }

  const toggle = () => {
    setModal(!modal)
  }

  const followModel = id => {
    setFollowupModal(!FollowupModal)
    setfollowDetailId(id)
  }

  const getTableData = () => {
    setIsLoading(true)
    postApi("Inquiry/list", {
      status: parseInt(inquiryStatus),
      fromDate: !dateDisble ? null : date[0] ? dateFormat(
        date[0],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      toDate: !dateDisble ? null : date[1] ? dateFormat(
        date[1],
        "yyyy-MM-dd"
      ) : new Date().toISOString().slice(0, 10),
      inquiryDateFilterType: parseInt(inquiryDateFilterType),
      displayStart: displayStart,
      pageSize: rowsPerPage,
      searchText: searchText.trim(),
      sortColumn: "",
      sortOrder: "",
    }).then(({ data, status }) => {
      if (status === 200) {
        setIsLoading(false)
        setTableData(data.InquiryList)
        setTotalRecords(data.TotalRecords)
      } else {
        setIsLoading(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })
  }

  useEffect(() => {
    getTableData()
  }, [rowsPerPage, searchText, displayStart, inquiryStatus, inquiryDateFilterType, dateDisble, date])

  const confirmDelete = id => {
    DeleteModel.confirmDeleteInquiry({
      title: "Are you sure? ",
      text: "You want to delete this inquiry permanently? ",
      isMobile,
    })
      .then(response => {
        setIsLoading(true)
        if (response == true) {
          deleteApi(`Inquiry/delete?id=${id}`).then(({ data, status }) => {
            if (status === 200) {
              const updatedArray = tableData
                .map(obj => (obj.Id !== id ? obj : null))
                .filter(Boolean)
              setTableData(updatedArray)
              toast.success("Sucessfully deleted", {
                style: {
                  fontSize: "15px",
                },
              })
              setIsLoading(false)
            } else {
              setIsLoading(false)
              ErrorModel.errormodel({
                title: "Verify Inquiry",
                text: data,
              })
            }
          })
        } else {
          setIsLoading(false)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        Cell: ({ value, row }) => (
          <a
            className="text-primary"
            onClick={() => followModel(row.original.Id)}
          >
            {value}
          </a>
        ),
      },
      {
        Header: "Shop Name",
        accessor: "ShopName",
        width: 250,
      },
      {
        Header: "Contact No.",
        accessor: "ContactNo",
        width: "7%",
      },
      {
        Header: "Address",
        accessor: "Address",
        Cell: ({ row }) => (
          <p
            className="address mb-0"
            // style={{width:"120px"}}
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Address}`}
          >
            {row.original.Address.length > 30
              ? `${row.original.Address.substr(0, 30)}...`
              : row.original.Address}
          </p>
        ),
      },
      {
        Header: "Description",
        accessor: "Description",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            // style={{width:"120px"}}
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Description}`}
          >
            {row.original.Description.length > 40
              ? `${row.original.Description.substr(0, 40)}...`
              : row.original.Description == ""
                ? "N/A"
                : row.original.Description}
          </p>
        ),
      },
      {
        Header: "Comment",
        accessor: "Comment",
        Cell: ({ row }) => (
          <p
            className=" mb-0"
            // style={{width:"120px"}}
            data-toggle="tooltip"
            data-placement="bottom"
            title={`${row.original.Comment}`}
          >
            {row.original.Comment?.length > 40
              ? `${row.original.Comment?.substr(0, 40)}...`
              : row.original.Comment == "" || row.original.Comment == null
                ? "N/A"
                : row.original.Comment}
          </p>
        ),
      },
      {
        Header: "Next Followup",
        accessor: "NextFollowup",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.NextFollowup == null
              ? "N/A"
              : dateFormat(
                row.original.NextFollowup.split("T")[0],
                "dd/MM/yyyy"
              )}
          </p>
        ),
      },

      {
        Header: "Date",
        accessor: "Date",
        width: "7%",
        Cell: ({ row }) => (
          <p className=" mb-0">
            {row.original.Date == null
              ? "N/A"
              : dateFormat(row.original.Date.split("T")[0], "dd/MM/yyyy")}
          </p>
        ),
      },
      {
        Header: "Status",
        accessor: "Status",
        width: "6%",
        Cell: ({ row }) => (
          <>
            {row.original.Status == 1
              ? "Lead"
              : row.original.Status == 2
                ? "Followup"
                : row.original.Status == 3
                  ? "Convert"
                  : "Fail"}
          </>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0 d-flex justify-content-center gap-2">
              {row.original.Status == 3 ? (
                <li>
                  <i
                    className=" fs-6 fas fa-bullhorn btn-edit"
                    id={`followuptooltip${row.original.Id}`}
                    style={{ cursor: "not-allowed", color: "#808080b3" }}
                  ></i>
                </li>
              ) : (
                <li className="mytooltip">
                  <i
                    className=" fs-6 fas fa-bullhorn btn-edit"
                    id={`followuptooltip${row.original.Id}`}
                    onClick={() => handleFollowUp(row.original.Id)}
                  ></i>
                  <span className="mytext">Followup</span>
                </li>
              )}
              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() => confirmDelete(row.original.Id)}
                ></i>
                <span className="mytext">Delete</span>
              </li>
              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-user-plus"
                  style={{ color: "rgba(80,165,241,.75)", cursor: "pointer" }}
                  id={`createtooltip${row.original.Id}`}
                  onClick={() => handleUserClicks(row.original)}
                ></i>
                <span className="mytext">Create User</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData]
  )

  const handleUserClicks = inquiryData => {
    if (inquiryData) {
      navigate(routes.userCreate, {
        state: {
          inquiryData,
        },
      })
    }
  }
  const handleCreateInquiry = () => {
    navigate("/inquiry")
  }

  document.title = `Inquiries | ${localStorage.getItem("ShopName")}`

  return (
    <div className="page-content">
      <div className="container-fluid">
        {isLoading && (
          <div className="loading-overlay is-active">
            <span className="fas fa-spinner fa-3x fa-spin"></span>
          </div>
        )}
        <Breadcrumbs
          title={breadcrumbs[pathname].title}
          breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
        />
        <Card>
          <CardBody>
            <InquiryTable
              columns={columns}
              data={tableData}
              isGlobalFilter={true}
              handleUserClick={handleUserClicks}
              handleCreateInquiry={handleCreateInquiry}
              isCreateInquiry={true}
              customPageSize={10}
              isLoading={isLoading}
              filterData={filterData}
              pagePerSize={pagePerSize}
              TotalRecords={TotalRecords}
              dateDisble={dateDisble}
              setdateDisble={setdateDisble}
              date={date}
              setInquiryStatus={setInquiryStatus}
              inquiryStatus={inquiryStatus}
              setdate={setdate}
              className="custom-header-css"
            />
          </CardBody>
        </Card>
        <InquiryModel
          modal={modal}
          toggle={toggle}
          userId={userId}
          getTableData={getTableData}
        />
        <Modal
          isOpen={FollowupModal}
          toggle={followModel}
          centered={true}
          size="small"
        >
          <div className="modal-content">
            <ModalHeader className="text-centre" toggle={followModel} tag="h5">
              Status
            </ModalHeader>
            <ModalBody>
              <FollowupDetails id={followDetailId} />
            </ModalBody>
          </div>
        </Modal>
      </div>
    </div>
  )
}
Inquiries.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Inquiries
