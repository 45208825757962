import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
} from "reactstrap"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import breadcrumbs from "../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import DBBackupDataTable from "./DBBackupDataTable"
import { dateFormat } from "pages/Common"
import axios from "axios"
import appConfig from "Api/app.config"

function DBBackup() {
    const { getApi, postApi } = useApi()
    document.title = `db-Backup | ${localStorage.getItem("ShopName")}`
    let { pathname } = useLocation()

    // useStates
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    // setting list api
    const dbBackupApi = () => {
        setIsLoading(true)
        getApi(`Setting/list-db-backup-logs`).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }

    // setting list api useEffect
    useEffect(() => {
        dbBackupApi()
    }, [])

    // table headers & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Id",
                accessor: "Id",
                width: "5%",
            },
            {
                Header: "File",
                accessor: "File",
            },
            {
                Header: "Date",
                accessor: "CreatedOn",
                width: "6%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original.CreatedOn == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original.CreatedOn.split("T")[0],
                                "dd/MM/yyyy"
                            )}{` (${(row?.original?.CreatedOn?.split("T")[1])?.split(":")[0]}:${(row?.original?.CreatedOn?.split("T")[1])?.split(":")[1]})`}
                    </p>
                ),
            },
        ],
        [tableData]
    )

    const handleBackupDatebase = () => {
        setIsLoading(true)
        axios.post(`${appConfig.BASE_URL}/setting/backup-database`, '', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 66E2E1D5220F4AAA91CF990C13D7C4E6'
            }
        }).then(({ data, status }) => {
            if (status == 200) {
                dbBackupApi()
                toast.success("Backup successfully", {
                    style: {
                        fontSize: "15px",
                    },
                })
            } else {
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
            setIsLoading(false)
        })
    }


    return (
        <div className="page-content">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>
                        <DBBackupDataTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={10}
                            isLoading={isLoading}
                            handleBackupDatebase={handleBackupDatebase}
                            className="custom-header-css"
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

DBBackup.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default DBBackup
