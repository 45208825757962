import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useGlobalFilter,
    useAsyncDebounce,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, FormGroup } from "reactstrap"
// import { Filter, DefaultColumnFilter } from "./filters"
import { DefaultColumnFilter } from "components/Common/filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { useEffect } from "react"
import { useState } from "react"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { dateFormat } from "pages/Common"

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    isJobListGlobalFilter,
    filterData,
    value,
    setValue,
}) {
    const count = preGlobalFilteredRows.length
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
        filterData(value)
    }, 200)

    return (
        <React.Fragment>
            <Col md={12}>
                <div className="search-box w-full d-inline-block">
                    <div className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={e => {
                                    setValue(e.target.value)
                                    onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                placeholder={"Search…"}
                                value={value || ""}
                            />
                        </label>
                        <i className="bx bx-search-alt search-icon"></i>
                    </div>
                </div>
            </Col>
            {isJobListGlobalFilter && <JobListGlobalFilter />}
        </React.Fragment>
    )
}

const SubscriptionTable = ({
    columns,
    data,
    isGlobalFilter,
    isJobListGlobalFilter,
    isCreateProduct,
    isAddUserList,
    handleUserClick,
    customPageSize,
    className,
    isCategoryList,
    handleCategoryClick,
    isCreateBooking,
    handleBookingClicks,
    isColorList,
    handleColorClick,
    isSubscriptionList,
    handleSubscriptionClick,
    filterData,
    pagePerSize,
    isLoading,
    TotalRecords,
    setdateDisble,
    dateDisble,
    date, setdate
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                // pageIndex: 0,
                pageSize: 20,
                sortBy: [
                    {
                        desc: true,
                    },
                ],
            },
        },
        useGlobalFilter,
        useExpanded,
        usePagination
    )
    const handlesort = column => { }
    const [pageIndex, setpageIndex] = useState(1)
    const [prevValue, setPrevValue] = useState();
    const [isMobile, setisMobile] = useState(window.innerWidth < 768)


    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0
        gotoPage(page)
    }

    const [value, setValue] = React.useState()


    useEffect(() => {
        pagePerSize(pageSize, pageIndex, value)
        setPrevValue(value);
    }, [pageSize, value, pageIndex])

    useEffect(() => {
        if (value !== prevValue) {
            setpageIndex(1); // Reset pageIndex to 1 when value changes
        }
    }, [value]);

    useEffect(() => {
        setpageIndex(1); // Reset pageIndex to 1 when value changes
    }, [pageSize]);

    const handleClick = () => {
        setdateDisble(!dateDisble);
    }
    const margin = {
        marginTop: isMobile ? "8px" : "10px",
    }
    const flatpickrRef = useRef(null);
    const checkbox = useRef();

    return (
        <Fragment>
            <Row >
                <div style={{ display: "flex", gap: "18px", justifyContent: "end" }}>
                    <div>
                        <div style={{ display: "flex", gap: "5px" }}>
                            <div style={{ width: '12px', height: '12px', backgroundColor: '#f46a6a40', marginTop: "3px", border: "1px solid #f46a6a0f" }}>   </div> <div>Expired </div>
                        </div>
                    </div>
                </div>
                <Col md="4">
                    <Row>
                        <Col md="6" className="table-header" style={margin}>
                            <select
                                className="form-select"
                                value={pageSize}
                                onChange={onChangeInSelect}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col md="6" className="table-header" style={margin}>
                            {isGlobalFilter && (
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                    isJobListGlobalFilter={isJobListGlobalFilter}
                                    filterData={filterData}
                                    value={value}
                                    setValue={setValue}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col md="5">
                    <Col md={8} className="table-header" style={margin}>
                        <div className="d-flex" >
                            <div className="form-control " onClick={() => handleClick()} style={{ width: "40px", borderRight: "none", backgroundColor: "#EFF2F7", display: "flex", alignItems: "center", cursor: "pointer" }}>
                                <input
                                    id="checkbox"
                                    type="checkbox"
                                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                                    checked={dateDisble}
                                    onChange={e => setdateDisble(e.target.checked)}
                                    ref={checkbox}
                                />
                            </div>
                            <Flatpickr
                                ref={flatpickrRef}
                                disabled={!dateDisble}
                                className="form-control d-block"
                                placeholder="dd M,yyyy"
                                options={{
                                    mode: "range",
                                    dateFormat: "d-m-Y"
                                }}
                                value={date}
                                onChange={(e) => { setdate(e) }}

                            />
                            <i
                                className="fas fa-calendar-alt calander-icon1"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    flatpickrRef.current.flatpickr.open();
                                }}
                            ></i>
                        </div>
                    </Col>
                </Col>
                <Col md="3" style={margin}>
                    {isSubscriptionList && (
                        <div className="text-sm-end">
                            <Button
                                type="button"
                                color="primary"
                                className="btn  mb-2 "
                                onClick={handleSubscriptionClick}
                            >
                                <i className="mdi mdi-plus-circle-outline me-1" />
                                Create Subscription
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
            <div className="table-responsive react-table">
                <Table bordered  {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => {
                                    return (
                                        <th
                                            key={column.id}
                                            onClick={() => {
                                                handlesort(column)
                                            }}
                                            {...column.getHeaderProps({
                                                style: {
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    width: column.width,
                                                },
                                            })}
                                        >
                                            <div className={`mb-0`}>
                                                {column.render("Header")}
                                                {generateSortingIndicator(column)}
                                            </div>
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>

                    {page.length ? (
                        <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row)
                                return (
                                    <Fragment key={row.getRowProps().key}>
                                        <tr>
                                            {row.cells.map(cell => {
                                                const { EndDate } = cell.row.original;
                                                let currentDate = new Date();
                                                let endDate = new Date(dateFormat(
                                                    EndDate.split("T")[0],
                                                    "yyyy-MM-dd"
                                                ))
                                                return (
                                                    <td
                                                        key={cell.id}
                                                        // className="text-nowrap"
                                                        className={`${currentDate >
                                                            endDate ? "Subscription_expired" : ""
                                                            } text-nowrap`}
                                                        {...cell.getCellProps({
                                                            style: {
                                                                minWidth: cell.minWidth,
                                                                maxWidth: cell.maxWidth,
                                                                width: cell.width,
                                                            },
                                                        })}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody
                            style={{
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "40px",
                            }}
                        >
                            <tr>
                                <td colSpan={headerGroups[0].headers.length}>
                                    {isLoading ? "Loading..." : "No Record Found"}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
            </div>
            <Row className="justify-content-md-end justify-content-center align-items-center">
                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        <Button
                            color="primary"
                            onClick={() => setpageIndex(pageIndex - 1)}
                            disabled={pageIndex == 1}
                        >
                            {"<<"}
                        </Button>
                        {/* <Button
              color="primary"
              onClick={previousPage}
              // disabled={!canPreviousPage}
            >
              {"<"}
            </Button> */}
                    </div>
                </Col>
                <Col className="col-md-auto d-none d-md-block">
                    Page
                    <strong>
                        {TotalRecords == 0 ? 1 : pageIndex} of {TotalRecords == 0 ? 0 : Math.ceil(TotalRecords / pageSize)}
                    </strong> | Total <strong>{TotalRecords}</strong>
                </Col>
                {/* <Col className="col-md-auto">
                    <Input
                        type="text"
                        min={1}
                        style={{ width: 70 }}
                        max={pageOptions.length}
                        defaultValue={pageIndex}
                        onChange={onChangeInInput}
                        value={pageIndex}
                        disabled
                    />
                </Col> */}

                <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                        {/* <Button color="primary" onClick={nextPage} disabled={!canNextPage}> */}
                        {/* <Button color="primary" onClick={pageIndex}>
              {">"}
            </Button> */}
                        <Button
                            color="primary"
                            onClick={() => setpageIndex(pageIndex + 1)}
                            disabled={pageIndex == (pageIndex == 1 && TotalRecords == 0 ? Math.ceil(TotalRecords / pageSize) + 1 : Math.ceil(TotalRecords / pageSize))}
                        >
                            {">>"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}

SubscriptionTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default SubscriptionTable
