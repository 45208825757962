/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  Container,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import TableListProduct from "./TableListBooking"
import { dateFormat } from "../Common"
import toast, { Toaster } from "react-hot-toast"
import useApi from "common/ApiMiddlewere"
import jsPDF from "jspdf"
import { font } from "pages/Reports/Booking/font"
import { InvoiceHtml } from "pages/Reports/Booking/invoicePDF"
import BookingBreadcrumb from "components/Common/BookingBreadcrumb"
import breadcrumbs from "common/Breadcrumbs"
import { routes } from "routes/path"
import TableList from "components/Common/TableList"
import appConfig from "Api/app.config"
import gstPercentage from "common/GetGSTper"

let advance = 0
let discount = 0
let CGSTPer = 0
let SGSTPer = 0
let IGSTPer = 0
let IGSTBillFlag = false
function CreateBooking() {
  document.title = `Booking | ${localStorage.getItem("ShopName")}`
  const { deleteApi, getApi, postApi, putApi } = useApi()
  const navigate = useNavigate()
  let { pathname } = useLocation() // get path name
  const { state } = useLocation() // navigate data
  // useStates
  const [flag, setFlag] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [allowPrintButton, setAllowPrintButton] = useState(false)
  const [allowSalesmanDisplay, setAllowSalesmanDisplay] = useState(false)
  const [screeLoad, setscreeLoad] = useState(true)
  const [loader, setLoader] = useState(true)
  const [ProductDetails, setProductDetails] = useState([]) // product lookup
  const [TimeSolteDetails, setTimeSolteDetails] = useState([]) // timeslote lookup
  const [totalTaxableRent, setTotalTaxableRent] = useState(0)
  const [remarkProductId, setRemarkProductId] = useState(0)
  const [fields, setFields] = useState([]);
  const [productNote, setProductNote] = useState("")
  const [cartList, setCartList] = useState([])
  const [cartConfirm, setCartConfirm] = useState(false)
  const [paymentMethod, setpaymentMethod] = useState("1")
  const [bookingAvailabilityList, setBookingAvailableList] = useState()
  const [availableListConfirm, setAvailableListConfirm] = useState(false)
  const [falgForTimeSlotevalidation, setfalgForTimeSlotevalidation] = useState(false)
  const [idList, setIdList] = useState([]);
  const [isMobile, setisMobile] = useState(window.innerWidth < 768)
  const [salemanList, setSalesmanList] = useState([])
  const [totalCGSTAmount, setTotalCGSTAmount] = useState(0)
  const [totalSGSTAmount, setTotalSGSTAmount] = useState(0)
  const [totalIGSTAmount, setTotalIGSTAmount] = useState(0)
  const [totalTexes, setTotalTexes] = useState(0)
  const [netAmount, setNetAmount] = useState(0)
  const [isIGSTBill, setIsIGSTBill] = useState(false)
  // table state management
  const [tableData, setTableData] = useState([])
  const [rentList, setRentList] = useState([])
  const [isCart, setIsCart] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [bookingData, setBookingData] = useState()
  const [bookingStatus, setBookingStatus] = useState(0)

  // APIs --> time-slot-lookup, vendor-salesman-lookup,setting-by-keys
  useEffect(() => {
    setscreeLoad(true)

    if (JSON.parse(localStorage.getItem("authUser")).RoleName != "Salesman") {
      postApi("Setting/setting-by-keys", {
        Key: ["GST_PERCENTAGE"],
        userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
      }).then(({ data, status }) => {
        setscreeLoad(false)
        if (status === 200) {
          CGSTPer = (parseFloat(data[0]?.Value.split("|")[0] || 0))
          SGSTPer = (parseFloat(data[0]?.Value.split("|")[1] || 0))
        } else {
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
    }

    getApi("TimeSlot/time-slot-lookup").then(({ data, status }) => {
      if (status === 200) {
        setscreeLoad(false)
        setTimeSolteDetails(data)
      } else {
        setscreeLoad(false)
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    getApi("User/vendor-salesman-lookup").then(({ data, status }) => {
      if (status === 200) {
        setSalesmanList(data)
      } else {
        toast.error(data, {
          style: {
            fontSize: "15px",
          },
        })
      }
    })

    postApi("Setting/setting-by-keys", {
      Key: ["SAVE_AND_PRINT_BOOKING", "TIME_SLOT_MANDATORY", "DISPLAY_SALESMAN_IN_CREATE_BOOKING"],
      userId: JSON.parse(localStorage.getItem("authUser")).ParentUserId > 0 ? JSON.parse(localStorage.getItem("authUser")).ParentUserId : JSON.parse(localStorage.getItem("authUser")).Id,
    })
      .then(({ data, status }) => {
        if (status === 200) {
          const settings = data.reduce((acc, item) => {
            const key = item.Key;
            acc[key] = item.Value;
            return acc;
          }, {});
          setAllowPrintButton(settings["SAVE_AND_PRINT_BOOKING"] === "1");
          setfalgForTimeSlotevalidation(settings["TIME_SLOT_MANDATORY"] === "1");
          setAllowSalesmanDisplay(settings["DISPLAY_SALESMAN_IN_CREATE_BOOKING"] === "1")
        } else {
          setAllowPrintButton(false);
          setfalgForTimeSlotevalidation(false);
        }
        setscreeLoad(false);
      })
    advance = validation.values.Advance
    discount = validation.values.discount
    bookingCalculation(null)
  }, [])


  useEffect(() => {
    if (isIGSTBill && !isEdit) {
      IGSTPer = CGSTPer + SGSTPer
      CGSTPer = 0
      SGSTPer = 0
    } else {
      CGSTPer = IGSTPer / 2
      SGSTPer = IGSTPer / 2
      IGSTPer = 0
    }
    tableData.forEach(function (value) {
      value.IGSTPer = IGSTPer
      value.CGSTPer = CGSTPer
      value.SGSTPer = SGSTPer
      value.Rent = ((value.NetRent * 100) / (100 + value.CGSTPer + value.SGSTPer + value.IGSTPer)).toFixed(2);
      value.CGSTAmount = ((value.Rent * value.CGSTPer) / 100).toFixed(2);
      value.SGSTAmount = ((value.Rent * value.SGSTPer) / 100).toFixed(2);
      value.IGSTAmount = ((value.Rent * value.IGSTPer) / 100).toFixed(2);
    });
    bookingCalculation(tableData)
  }, [isIGSTBill, isEdit])



  // booking details
  useEffect(() => {
    const fetchdata = async () => {
      try {
        setLoader(true);
        setIsCart(state?.cartName ? true : false);
        setIsEdit(state?.bookingEditId ? true : false);
        let endpointURL = state?.cartName
          ? `Cart/cart-by-name?name=${state.cartName}`
          : `Booking/details?id=${state.bookingEditId}`;
        const response = await getApi(endpointURL)
        let { data, status } = response
        if (status === 200) {
          IGSTBillFlag = data?.BookingProductList[0].IGSTPer > 0
          validation.setFieldValue("id", data?.Id || 1);
          validation.setFieldValue("IGSTBill", data?.Id || 1);
          validation.setFieldValue("BillNo", data?.BillNo || "");
          validation.setFieldValue("Name", data?.Name || "");
          validation.setFieldValue("remark", data?.Remarks || "");
          validation.setFieldValue("Advance", data?.Advance || 0);
          validation.setFieldValue("ContactNo1", data?.ContactNo1 || "");
          validation.setFieldValue("ContactNo2", data?.ContactNo2 || "");
          validation.setFieldValue("discount", data?.Discount || 0);
          validation.setFieldValue("displayDeposit", data?.DisplayDeposit || 0);
          validation.setFieldValue("Address", data?.Address || "");
          setTotalTaxableRent(data?.TotalRent)
          setBookingData({ data: data });
          // GST Amt. & Rent calculation
          data?.BookingProductList.forEach(function (item) {
            item.CGSTPer = state?.cartName ? CGSTPer : item.CGSTPer
            CGSTPer = state?.cartName ? CGSTPer : item.CGSTPer
            item.SGSTPer = state?.cartName ? SGSTPer : item.SGSTPer
            SGSTPer = state?.cartName ? SGSTPer : item.SGSTPer
            item.IGSTPer = state?.cartName ? 0 : item.IGSTPer
            IGSTPer = state?.cartName ? 0 : item.IGSTPer
            bookingItemCalculation(item)
          })
          setRentList(data.BookingProductList);
          setTableData(data.BookingProductList);
          setpaymentMethod(data?.PaymentMode == 2 ? "2" : "1");
          advance = data?.Advance || 0
          discount = data?.Discount || 0
          bookingCalculation(data.BookingProductList);
          setLoader(false)
          // setIsIGSTBill(data?.BookingProductList[0].IGSTPer > 0)
        }
        else {
          setLoader(false)
          toast.error(data, {
            style: {
              fontSize: "15px",
            },
          });
        }

      } catch (error) {
        console.log(error);
      }
    }
    fetchdata()
  }, []);



  const bookingItemCalculation = (itemData) => {
    itemData.Rent = (itemData.NetRent * 100) / (100 + itemData.CGSTPer + itemData.SGSTPer + itemData.IGSTPer);
    itemData.CGSTAmount = ((itemData.Rent * itemData.CGSTPer) / 100).toFixed(2);
    itemData.SGSTAmount = ((itemData.Rent * itemData.SGSTPer) / 100).toFixed(2);
    itemData.IGSTAmount = ((itemData.Rent * itemData.IGSTPer) / 100).toFixed(2);
  }

  // modal toggles
  const toggle = () => {
    setModal(!modal)
  }

  const toggle1 = () => {
    setModal1(!modal1)
  }

  const toggle3 = () => {
    setModal3(!modal3)
  }

  // cart confirm handler
  const handaleConfirm = () => {
    setCartConfirm(true)
    toggle1()
  }

  // add to cart product
  const addToCartProduct = (bookingItem, productData) => {
    setscreeLoad(true)
    postApi("Cart/add-to-cart", {
      name: bookingData?.data.Name,
      productCode: bookingItem ? bookingItem?.ProductCode : "",
      deliveryDate: bookingItem ? bookingItem?.DeliveryDate : "",
      deliveryTimeSlot: bookingItem?.DeliveryTimeSlot == undefined ? "" : bookingItem?.DeliveryTimeSlot,
      returnDate: bookingItem ? bookingItem?.ReturnDate : "",
      returnTimeSlot: bookingItem?.ReturnTimeSlot == undefined ? "" : bookingItem?.ReturnTimeSlot,
      confirmByVendor: cartConfirm,
      contactNo: validation?.values?.ContactNo1,
    }).then(({ data, status }) => {
      setscreeLoad(false)
      setAvailableListConfirm(false)
      if (status == 200 && data.CartId > 0 && data.Status == 1) {
        bookingItem.CartId = data.CartId

        // get dynamic-control data(sub categories)
        getApi(`Booking/listDynamicControl-by-productId?productId=${productData?.ProductId}`).then(({ data, status }) => {
          if (status === 200) {
            setCartConfirm(false)
            bookingItem.ProductName = productData?.ProductName
            bookingItem.ProductId = productData?.ProductId
            bookingItem.NetRent = productData?.Rent
            bookingItem.ProductCode = bookingItem?.ProductCode.toUpperCase();
            bookingItem.LstDynamicControl = data
            bookingItem.SalesmanId = parseInt(salemanList[0]?.Key)
            bookingItem.CGSTPer = CGSTPer
            bookingItem.SGSTPer = SGSTPer
            bookingItem.IGSTPer = IGSTPer
            bookingItemCalculation(bookingItem)
            var addTableBookingItem = [...tableData, bookingItem]
            setTableData(addTableBookingItem)
            setRentList(addTableBookingItem)
            bookingCalculation(addTableBookingItem)
            productDetailsValidation.handleReset()
            setdata({
              ProductId: 0,
              ProductCode: "",
              DeliveryDate: "",
              ReturnDate: "",
              Rent: 0,
            })
          } else {
            setscreeLoad(false)
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })


      } else if (status == 200 && data.Status == 2) {
        toast.error(data.ErrorMessage, {
          style: {
            fontSize: "15px",
          },
        })
      } else {
        setCartList(data.CartAvailabilityList)
        toggle1()
      }
    })
  }

  // available and cart confirm
  const handaleConfirmAddProduct = () => {
    setAvailableListConfirm(true)
    toggle3()
  }

  // Booking Validations
  const validation = useFormik({
    enableReinitialize: false,
    initialValues: {
      id: 0,
      BillNo: "",
      Name: "",
      remark: "",
      Rent: 0,
      Advance: '',
      ContactNo1: "",
      ContactNo2: "",
      discount: '',
      Address: "",
      Approved: false,
      displayDeposit: "",
    },
    validationSchema: Yup.object({
      ContactNo1: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
        .required("Contact Number is required"),
      ContactNo2: Yup.string()
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number")
        .required("Contact Number is required"),
      Name: Yup.string().required("The Name is required"),
      Address: Yup.string().required("The Address is required"),
    }),
    onSubmit: values => {
      if (Math.round(netAmount) < 0 || Math.round(netAmount) - Math.round(advance) < 0) {
        toast("Amount should not be nagative.", {
          icon: "⚠️",
          style: {
            fontSize: "15px",
          },
        })
        return false
      }
      let bookingItem = []
      rentList.map(item => {
        bookingItem.push({
          id: item.Id ? item.Id : 0,
          ProductId: parseInt(item.ProductId),
          Rent: parseFloat(item.Rent || 0),
          DeliveryDate: item.DeliveryDate,
          ReturnDate: item.ReturnDate,
          ReturnTimeSlot: item.ReturnTimeSlot ? item.ReturnTimeSlot : "",
          DeliveryTimeSlot: item.DeliveryTimeSlot ? item.DeliveryTimeSlot : "",
          CartId: item.CartId ? item.CartId : 0,
          Remarks: item.Remarks ? item.Remarks : "",
          SalesmanId: item.SalesmanId,
          CgstPer: parseFloat(item.CGSTPer),
          SgstPer: parseFloat(item.SGSTPer),
          IgstPer: parseFloat(item.IGSTPer),
          CgstAmount: parseFloat(item.CGSTAmount),
          SgstAmount: parseFloat(item.SGSTAmount),
          IgstAmount: parseFloat(item.IGSTAmount),
          NetRent: item.NetRent,
          DynamicControlValue: item.LstDynamicControl
            ? item.LstDynamicControl
              .filter(obj => (obj.Value !== '' && obj.Value !== undefined && obj.Value !== null && obj.Value !== "null") || obj.DynamicControlsDataId > 0)
              .map(({ DynamicControlId, DynamicControlsDataId, Value }) =>
                isEdit
                  ? `${DynamicControlId}|${DynamicControlsDataId}|${Value}`
                  : `${DynamicControlId}|${Value}`
              )
              .join('~')
            : ''
        })
      })

      if (isEdit) {
        setscreeLoad(true)
        putApi("Booking/update", {
          Id: bookingData?.data.Id,
          BillNo: bookingData ? bookingData?.data.BillNo : "",
          Name: values.Name,
          ContactNo1: values.ContactNo1,
          ContactNo2: values.ContactNo2,
          CgstAmount: parseFloat(totalCGSTAmount),
          SgstAmount: parseFloat(totalSGSTAmount),
          IgstAmount: parseFloat(totalIGSTAmount),
          TotalRent: parseFloat(totalTaxableRent),
          NetAmount: Math.round(netAmount) - values.discount || 0,
          Advance: parseFloat(values.Advance || 0),
          Discount: parseFloat(values.discount || 0),
          Remarks: values.remark,
          UpdateBookingItems: bookingItem,
          Address: values.Address,
          PaymentMode: parseInt(paymentMethod),
        }).then(({ data, status }) => {
          if (status === 200) {
            setscreeLoad(false)
            toast.success("Successfully Done", {
              style: {
                fontSize: "15px",
              },
            })
            advance = 0
            discount = 0
            navigate(routes.bookingList)
          } else {
            setscreeLoad(false)
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      } else {
        postApi("Booking/create", {
          BillNo: bookingData ? bookingData?.data.BillNo : "",
          Name: values.Name,
          ContactNo1: values.ContactNo1,
          ContactNo2: values.ContactNo2,
          CgstAmount: parseFloat(totalCGSTAmount),
          SgstAmount: parseFloat(totalSGSTAmount),
          IgstAmount: parseFloat(totalIGSTAmount),
          TotalRent: parseFloat(totalTaxableRent),
          NetAmount: Math.round(netAmount) - values.discount || 0,
          Advance: parseFloat(values.Advance || 0),
          Discount: parseFloat(values.discount || 0),
          DisplayDeposit: parseFloat(values.displayDeposit || 0),
          Remarks: values.remark,
          CreateBookingItems: bookingItem,
          Address: values.Address,
          PaymentMode: parseInt(paymentMethod),
          onApproved: values.Approved,
        }).then(({ data, status }) => {
          if (status === 200) {
            if (flag) {
              advance = 0
              discount = 0
              handleSaveprint(data)
            } else {
              navigate(routes.bookingList)
              toast.success("Booking successfully create", {
                style: {
                  fontSize: "15px",
                },
              })
            }
          } else {
            toast.error(data, {
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
    },
  })

  // calculation total Amt.
  function bookingCalculation(data) {
    const totalTablerent = data
      ? data.reduce((sum, item) => sum + parseFloat(item.Rent), 0)
      : tableData.reduce((sum, item) => sum + parseFloat(item.Rent), 0)
    setTotalTaxableRent(totalTablerent.toFixed(2))

    const totalCGSTAmt = data ? data.reduce((sum, item) => sum + parseFloat(item.CGSTAmount), 0)
      : tableData.reduce((sum, item) => sum + parseFloat(item.CGSTAmount), 0)
    setTotalCGSTAmount(totalCGSTAmt.toFixed(2))

    const totalSGSTAmt = data ? data.reduce((sum, item) => sum + parseFloat(item.SGSTAmount), 0)
      : tableData.reduce((sum, item) => sum + parseFloat(item.SGSTAmount), 0)
    setTotalSGSTAmount(totalSGSTAmt.toFixed(2))

    const totalIGSTAmt = data ? data.reduce((sum, item) => sum + parseFloat(item.IGSTAmount), 0)
      : tableData.reduce((sum, item) => sum + parseFloat(item.IGSTAmount), 0)
    setTotalIGSTAmount(totalIGSTAmt.toFixed(2))

    setTotalTexes((totalCGSTAmt + totalSGSTAmt + totalIGSTAmt).toFixed(2))
    advance = parseInt(advance || 0)
    discount = parseInt(discount || 0)
    setNetAmount((totalTablerent + totalCGSTAmt + totalSGSTAmt + totalIGSTAmt))
  }

  // Delete Booking handler
  const deleteHandle = (Id, CartId) => {
    if (Id != 0) {
      setIdList(prevIds => [...prevIds, Id]);
    }
    let updatedArray = []
    if (CartId) {
      deleteApi(`Cart/delete-cart?id=${CartId}`).then(({ data, status }) => {
        if (status === 200) {
          toast.success("Successfully deleted", {
            style: {
              fontSize: "15px",
            },
          })
        }
      })
      updatedArray = tableData
        .map(obj => (obj.CartId !== CartId ? obj : null))
        .filter(Boolean)
    } else {
      updatedArray = tableData
        .map(obj => (obj.Id !== Id ? obj : null))
        .filter(Boolean)
    }
    setTableData(updatedArray)
    setRentList(updatedArray)
    bookingCalculation(updatedArray)
  }

  // product Details initialValues
  const [data, setdata] = useState({
    ProductId: 0,
    ProductCode: "",
    DeliveryDate: "",
    ReturnDate: "",
    // Rent: 0,
    Rent: isEdit
      ? bookingData?.data.totalAmount - bookingData
        ? bookingData?.data.Discount
        : 0 - bookingData
          ? bookingData?.data.Advance
          : 0
      : 0,
    ReturnTimeSlot: "",
    DeliveryTimeSlot: "",
    ProductName: "",
    CartId: 0,
    mode: 1,
    Remarks: "",
  })

  // existing product validate function
  const exitInBookingItem = (arr, obj) => {
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i]
      if (item.ProductCode.toUpperCase() === obj.ProductCode.toUpperCase()) {
        return true
      }
    }
    return false
  }

  // Product Detilas validation
  const productDetailsValidation = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: Yup.object({
      ProductCode: Yup.string().required("The Product Code is required"),
      DeliveryDate: Yup.string().required("The Delivery date is required"),
      ReturnDate: Yup.string().required("The Return date is required"),
      ...(falgForTimeSlotevalidation && {
        ReturnTimeSlot: Yup.string().required("The Return time is required"),
        DeliveryTimeSlot: Yup.string().required("The Delivery time is required"),
      }),
    }),
    onSubmit: bookingItem => {
      let bookingItems = exitInBookingItem(tableData, bookingItem)
      if (!bookingItems) {
        setscreeLoad(true)
        postApi("Booking/check-availablility", {
          productCode: bookingItem.ProductCode.trim(),
          fromDate: bookingItem.DeliveryDate,
          toDate: bookingItem.ReturnDate,
          BookingItemIds: idList.join(",")
        }).then(({ data, status }) => {
          setscreeLoad(false)
          if (status === 200) {
            setBookingStatus(data?.Status)
            if (data?.BookingAvailabilityList?.length > 0 && (data.Status == 3 || data.Status == 2)) {
              if (availableListConfirm) {
                addToCartProduct(bookingItem, data)
              } else {
                toggle3()
              }
              if (data.Status === 2) {
                toast.error("Product not available!", {
                  // icon: "⚠️",
                  style: {
                    fontSize: "15px",
                  },
                })
              }
              setBookingAvailableList(data?.BookingAvailabilityList)
            } else if (data?.BookingAvailabilityList?.length === 0 &&
              data.Status === 1) {
              addToCartProduct(bookingItem, data)
            } else if (data?.BookingAvailabilityList?.length == 0 && data.Status == 2) {
              toast.error("Product not available!", {
                // icon: "⚠️",
                style: {
                  fontSize: "15px",
                },
              })
            }
            else {
              toast.error(data, {
                // icon: "⚠️",
                style: {
                  fontSize: "15px",
                },
              })
            }
          } else {
            toast(data, {
              icon: "⚠️",
              style: {
                fontSize: "15px",
              },
            })
          }
        })
      }
      else {
        toast.error("Product is already has been list", {
          style: {
            fontSize: "15px",
          },
        })
      }

    },
  })

  // change rent in perticular product
  const handleChangeNetRent = (ProductId, netRent, salesmanId) => {
    rentList.forEach(function (value) {
      if (value.ProductId === ProductId) {
        value.Rent = ((netRent * 100) / (100 + value.CGSTPer + value.SGSTPer + value.IGSTPer)).toFixed(2);
        value.NetRent = parseFloat(netRent || 0)
        value.SalesmanId = parseInt(salesmanId)
        value.CGSTAmount = ((value.Rent * value.CGSTPer) / 100).toFixed(2);
        value.SGSTAmount = ((value.Rent * value.SGSTPer) / 100).toFixed(2);
        value.IGSTAmount = ((value.Rent * value.IGSTPer) / 100).toFixed(2);
      }
    });
    bookingCalculation(rentList)
  }

  const handleChangeSubCategories = (index, e) => {
    const newFields = [...fields];
    newFields[index].Value = e.target.value;
    setFields(newFields);
  };

  // perticular product remarks add
  const handleRemarkAdd = () => {
    rentList.map(item => {
      if (item.ProductId == remarkProductId) {
        item.Remarks = productNote
        item.LstDynamicControl = fields
      }
    })
    toggle()
  }

  const remarkHandle = (bookingId, remark, LstDynamicControl) => {
    toggle()
    const updatedFields = LstDynamicControl.map((field, index) => {
      // Add the new key to the field object
      const updatedField = {
        ...field,
        Value: field.Value == "null" || field.Value == null ? "" : field.Value,
      };

      return updatedField;
    });
    setFields(updatedFields)
    setRemarkProductId(bookingId)
    setProductNote(remark)
  }

  // allow only number key
  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
    }
  };

  // multiple Booking Table
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Code
          </div>
        ),
        accessor: "ProductCode",
        width: "7%",
        Cell: ({ row }) => {
          return (
            <div
              style={{
                textAlign: "left",
              }}
            >
              {row?.original?.ProductCode}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Name
          </div>
        ),
        accessor: "ProductName",
        Cell: ({ row }) => {
          return <div
            style={{
              textAlign: "left",
            }}>{row.original.ProductName}</div>
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Salesman
          </div>
        ),
        accessor: "SalesmanId",
        width: "12%",
        alignItems: "start",
        Cell: ({ row }) => {
          return <>
            {((isEdit && row?.original?.CartId) || isCart) ? <div
              className="textWrap"
              style={{
                height: "8px",
                textAlign: "center",
                justifyContent: "center",
                width: "185px",
              }}
            >
              <Input
                type="select"
                onChange={e =>
                  handleChangeNetRent(row.original.ProductId, row.original.NetRent, e.target.value)
                }
                defaultValue={row?.original?.SalesmanId}
                bsSize="sm"
                className="form-control"
              >
                {salemanList.map((item, index) => {
                  return (
                    <option key={index} value={item.Key}>
                      {item.Value}
                    </option>
                  )
                })}
              </Input>
            </div> : <div style={{
              textAlign: "left",
            }}>{row?.original?.SalesmanName}</div>}
          </>
        }
        ,
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Delivery
          </div>
        ),
        accessor: "DeliveryDate",
        width: "7%",
        Cell: ({ row }) => (
          <span className=" mb-0">
            <> {row?.original?.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row?.original?.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}</>
            <>
              {row?.original?.DeliveryTimeSlot ? (
                <span> {` (${row?.original?.DeliveryTimeSlot})`}</span>
              ) : (
                ""
              )}</>
          </span>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "left",
            }}
          >
            Return
          </div>
        ),
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <span className=" mb-0" style={{ display: "flex" }}>
            {row?.original?.ReturnDate == null
              ? "N/A"
              : dateFormat(
                row?.original?.ReturnDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row?.original?.ReturnTimeSlot ? (
              <span> {` (${row?.original?.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            Rent
          </div>
        ),
        accessor: "Rent",
        width: "8%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}>
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                maximumFractionDigits: 2,
              }).format(row.original?.Rent || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            textAlign: "start"
          }}>
            Net Rent
          </div>
        ),
        accessor: "TotalPrice",
        width: "10%",
        Cell: ({ row }) => {
          return (
            <div className="table-display">
              <i className="mdi mdi-currency-inr"></i>
              <Input
                className="form-control-sm table-cell"
                placeholder="0.00"
                disabled={row.original.IsCancelled}
                type="text"
                onFocus={(e) => { e.target.select() }}
                defaultValue={row.original.NetRent}
                onKeyPress={handleKeyPress}
                onChange={e =>
                  handleChangeNetRent(row.original.ProductId, e.target.value, row.original.SalesmanId)
                }
              />
            </div>
          )
        },
      },
      {
        // Header: "CGST",
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}>
            CGST({CGSTPer}%)
          </div>
        ),
        accessor: "CGSTPer",
        width: "5%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}>
              <i className="mdi mdi-currency-inr"></i> {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(isIGSTBill ? 0 : row.original.CGSTAmount || 0)
              }
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}>
            SGST({SGSTPer}%)
          </div>
        ),
        accessor: "SGSTPer",
        width: "5%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}>
              <i className="mdi mdi-currency-inr"></i> {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(isIGSTBill ? 0 : row.original.SGSTAmount || 0)
              }
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}>
            IGST({IGSTPer}%)
          </div>
        ),
        accessor: "IGSTPer",
        width: "5%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}>
              <i className="mdi mdi-currency-inr"></i> {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row.original.IGSTAmount || 0)
              }
            </div>
          )
        },
      },
      {
        Header: () => (
          <div style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            textAlign: "end"
          }}>
            Total Amt.
          </div>
        ),
        accessor: "NetRent",
        width: "10%",
        Cell: ({ row }) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              textAlign: "end"
            }}>
              <i className="mdi mdi-currency-inr"></i>
              {new Intl.NumberFormat('en-IN', {
                currency: 'INR',
                minimumFractionDigits: 2,
              }).format(row.original.NetRent || 0)}
            </div>
          )
        },
      },
      {
        Header: () => (
          <div
            style={{
              textAlign: "center",
            }}
          >
            Action
          </div>
        ),
        width: "6%",
        accessor: "action",
        Cell: ({ row }) => {
          return (
            <ul className="list-unstyled hstack gap-2 mb-0 d-flex justify-content-center">
              {row.original.IsCancelled ? (
                <li>
                  <i
                    className="fs-6 far fa-list-alt  btn-edit"
                    style={{ cursor: "not-allowed", color: "#808080b3" }}
                  ></i>
                </li>
              ) : (
                <li className="mytooltip" >
                  <i
                    className="fs-6 far fa-list-alt  btn-edit"
                    id={`edittooltip${row.original.Id}`}
                    onClick={() =>
                      remarkHandle(row.original.ProductId, row.original.Remarks, row.original.LstDynamicControl)
                    }
                  ></i>
                  <span className="mytext">Note</span>
                </li>
              )}


              <li className="mytooltip">
                <i
                  className="fs-6 fas fa-trash-alt btn-delete"
                  id={`deletetooltip${row.original.Id}`}
                  onClick={() =>
                    deleteHandle(row.original.Id, row.original.CartId)
                  }
                ></i>
                <span className="mytext">Delete</span>
              </li>
            </ul>
          )
        },
      },
    ],
    [tableData, CGSTPer, SGSTPer, isIGSTBill]
  )

  const columns1 = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Salesman Name",
        accessor: "SalesmanName",
      },
      {
        Header: "Delivery Date",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <span className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: "Return Date",
        accessor: "ReturnDate,ReturnTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <span className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </span>
        ),
      },
    ],
    []
  )

  // table header & accessor
  const columns3 = useMemo(
    () => [
      {
        Header: "Customer Name",
        accessor: "CustomerName",
      },
      {
        Header: "Contact No.1",
        accessor: "ContactNo1",
        width: "8%",
      },
      {
        Header: "Contact No.2",
        accessor: "ContactNo2",
        width: "8%",
        Cell: ({ row }) => (
          <span className=" mb-0">
            {row.original.ContactNo2 == null ? "N/A" : row.original.ContactNo2}
          </span>
        ),
      },

      {
        Header: "Delivery",
        accessor: "DeliveryDate,DeliveryTimeSlot",
        width: "8%",
        Cell: ({ row }) => (
          <span className=" mb-0">
            {row.original.DeliveryDate == "0001-01-01T00:00:00"
              ? "N/A"
              : dateFormat(
                row.original.DeliveryDate.split("T")[0],
                "dd/MM/yyyy"
              )}
            {row.original.DeliveryTimeSlot ? (
              <span> {` (${row.original.DeliveryTimeSlot})`}</span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: "Return",
        accessor: "ReturnDate",
        width: "8%",
        Cell: ({ row }) => (
          <span className=" mb-0">
            {row.original.ReturnDate == null
              ? "N/A"
              : dateFormat(row.original.ReturnDate.split("T")[0], "dd/MM/yyyy")}
            {row.original.ReturnTimeSlot ? (
              <span> {` (${row.original.ReturnTimeSlot})`}</span>
            ) : (
              ""
            )}
          </span>
        ),
      },
    ],
    []
  )

  const handleDiscountChange = value => {
    discount = parseInt(value) || 0
    validation.setFieldValue("discount", discount || '')
    bookingCalculation()
  }

  const handleAdvanceChange = value => {
    advance = parseInt(value) || 0
    validation.setFieldValue("Advance", advance || '')
    bookingCalculation()
  }

  // Auto select feild Code Product Rent
  const autoSelect = (e, name) => {
    if (name == "ProductCode") {
      let TargetValue = e
      const filterData = ProductDetails.filter(item => item.Code == TargetValue)
      if (filterData.length) {
        setdata({
          ...data,
          ProductCode: TargetValue,
          ProductId: filterData[0].Id,
          Rent: filterData[0].Rent,
          ProductName: filterData[0]?.Name,
        })
      } else {
        setdata({
          ...data,
          ProductCode: TargetValue,
          ProductId: 0,
          Rent: 0,
          ProductName: filterData[0]?.Name,
        })
      }
    } else {
      let TargetValue = e
      const filterData = ProductDetails.filter(item => item.Id == TargetValue)
      setdata({
        ...data,
        ProductCode: filterData[0].Code,
        ProductId: TargetValue,
        Rent: filterData[0].Rent,
        ProductName: filterData[0]?.Name,
      })
    }
  }

  // handle Save and Print event
  const handleSaveprint = async id => {
    setscreeLoad(true)
    toast.success("Booking successfully create", {
      style: {
        fontSize: "15px",
      },
    })
    getApi(`Report/booking-invoice?bookingId=${id}`).then(({ data, status }) => {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url);

      newWindow.addEventListener('load', () => {
        newWindow.print();
      });
      setscreeLoad(false)
      navigate(routes.bookingList)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {(isEdit && loader) || screeLoad && (
            <div className="loading-overlay is-active">
              <span className="fas fa-spinner fa-3x fa-spin"></span>
            </div>
          )}
          <BookingBreadcrumb
            parent={breadcrumbs[pathname].parent}
            breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
            parentLink={breadcrumbs[pathname].parentLink}
            BillNo={bookingData?.data.BillNo}
            onApproved={validation.values.Approved}
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                    onReset={e => {
                      e.preventDefault
                      validation.handleReset()
                      return false
                    }}
                  >
                    <Row>
                      <Label className="mb-3">
                        Customer Details
                        <hr
                          style={{
                            color: "red",
                            background: "#00000036",
                            height: "0.5px",
                            margin: "0px",
                            marginBottom: "10px",
                          }}
                        />
                      </Label>
                    </Row>
                    <Row>
                      <Col md="4" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Name
                          </Label>
                          <Input
                            name="Name"
                            placeholder=" Name"
                            type="text"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Name || ""}
                            invalid={
                              validation.touched.Name && validation.errors.Name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.Name && validation.errors.Name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Name}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="3" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Contact No.1
                          </Label>
                          <Input
                            name="ContactNo1"
                            placeholder="Contact No."
                            type="text"
                            maxLength="10"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ContactNo1 || ""}
                            onKeyPress={handleKeyPress}
                            invalid={
                              validation.touched.ContactNo1 &&
                                validation.errors.ContactNo1
                                ? true
                                : false
                            }
                          />
                          {validation.touched.ContactNo1 &&
                            validation.errors.ContactNo1 ? (
                            <FormFeedback type="invalid">
                              {validation.errors.ContactNo1}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md="3" className="pe-0">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Contact No.2
                          </Label>
                          <Input
                            name="ContactNo2"
                            placeholder="customer no."
                            type="text"
                            maxLength="10"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ContactNo2 || ""}
                            onKeyPress={handleKeyPress}
                            invalid={
                              validation.touched.ContactNo2 &&
                                validation.errors.ContactNo2
                                ? true
                                : false
                            }
                          />
                          {validation.touched.ContactNo2 &&
                            validation.errors.ContactNo2 ? (
                            <FormFeedback type="invalid">
                              {validation.errors.ContactNo2}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <div className="mb-2">
                          <Label
                            htmlFor="validationCustom03"
                            className="required"
                          >
                            Address
                          </Label>
                          <Input
                            name="Address"
                            placeholder="Address"
                            type="textarea"
                            className="form-control-sm"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.Address || ""}
                            invalid={
                              validation.touched.Address &&
                                validation.errors.Address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.Address &&
                            validation.errors.Address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.Address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={2} style={{ marginTop: "28px" }}>
                        <div className="" style={{ display: "flex", alignItems: "end", marginLeft: "8px", justifyContent: "flex-start" }}>
                          <FormGroup check>

                            {isEdit ? <Input
                              disabled={isEdit}
                              name="IGSTBill"
                              id="IGSTBill"
                              type="checkbox"
                              onBlur={validation.handleBlur}
                              checked={tableData[0]?.IGSTPer > 0}
                            /> :
                              <Input
                                disabled={isEdit}
                                name="IGSTBill"
                                id="IGSTBill"
                                type="checkbox"
                                onBlur={validation.handleBlur}
                                defaultChecked={isIGSTBill}
                                onChange={e => setIsIGSTBill(e.target.checked)}
                              />
                            }
                          </FormGroup>
                          <Label style={{ marginTop: "5px" }} for="IGSTBill" check>
                            IGST Bill
                          </Label>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mb-2">
                      <Label className="my-3">
                        Product Details
                        <hr
                          style={{
                            background: "#00000036",
                            height: "0.5px",
                            margin: "0px",
                            // marginBottom: "2px",
                          }}
                        />
                      </Label>
                    </Row>

                    <Row className="mb-2">
                      <Col md="11">
                        <Row>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label
                                htmlFor="validationCustom02"
                                className="required"
                              >
                                Code
                              </Label>
                              <Input
                                name="ProductCode"
                                placeholder="ProductCode"
                                type="text"
                                className="form-control-sm"
                                id="validationCustom02"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation?.values
                                    ?.ProductCode || ""
                                }
                                invalid={
                                  productDetailsValidation.touched
                                    .ProductCode &&
                                    productDetailsValidation.errors.ProductCode
                                    ? true
                                    : false
                                }
                              />
                              {productDetailsValidation.touched.ProductCode &&
                                productDetailsValidation.errors.ProductCode ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.ProductCode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label
                                htmlFor="productDetailsValidationCustom01"
                                className="required"
                              >
                                Delivery Date
                              </Label>
                              <Input
                                name="DeliveryDate"
                                placeholder="date placeholder"
                                type="date"
                                // min={new Date().toISOString().slice(0, 10)}
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values
                                    .DeliveryDate || ""
                                }
                                invalid={
                                  productDetailsValidation.touched
                                    .DeliveryDate &&
                                    productDetailsValidation.errors.DeliveryDate
                                    ? true
                                    : false
                                }
                              />
                              {productDetailsValidation.touched.DeliveryDate &&
                                productDetailsValidation.errors.DeliveryDate ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.DeliveryDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label htmlFor="productDetailsValidationCustom01">
                                Time Slot
                              </Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                className="form-control-sm"
                                name="DeliveryTimeSlot"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values
                                    .DeliveryTimeSlot || ""
                                }
                                invalid={
                                  productDetailsValidation.touched
                                    .DeliveryTimeSlot &&
                                    productDetailsValidation.errors
                                      .DeliveryTimeSlot
                                    ? true
                                    : false
                                }
                              >
                                <option>Select</option>
                                {TimeSolteDetails.map(item => {
                                  return (
                                    <option key={item.Key} value={item.Value}>
                                      {item.Value}
                                    </option>
                                  )
                                })}
                              </Input>
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label
                                htmlFor="productDetailsValidationCustom01"
                                className="required"
                              >
                                Return Date
                              </Label>
                              <Input
                                name="ReturnDate"
                                placeholder="date placeholder"
                                type="date"
                                min={
                                  productDetailsValidation.values
                                    .DeliveryDate ||
                                  new Date().toISOString().slice(0, 10)
                                }
                                dateFormat="DD/M/YYYY"
                                className="form-control-sm"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values.ReturnDate ||
                                  ""
                                }
                                invalid={
                                  productDetailsValidation.touched.ReturnDate &&
                                    productDetailsValidation.errors.ReturnDate
                                    ? true
                                    : false
                                }
                              />
                              {productDetailsValidation.touched.ReturnDate &&
                                productDetailsValidation.errors.ReturnDate ? (
                                <FormFeedback type="invalid">
                                  {productDetailsValidation.errors.ReturnDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col md="3" className="pe-0" lg="2">
                            <div className="mb-2">
                              <Label htmlFor="productDetailsValidationCustom01">
                                Time Slot
                              </Label>
                              <Input
                                type="select"
                                bsSize="sm"
                                className="form-control-sm"
                                name="ReturnTimeSlot"
                                onChange={productDetailsValidation.handleChange}
                                onBlur={productDetailsValidation.handleBlur}
                                value={
                                  productDetailsValidation.values
                                    .ReturnTimeSlot || ""
                                }
                              >
                                <option>Select</option>
                                {TimeSolteDetails.map(item => {
                                  return (
                                    <option key={item.Key} value={item.Value}>
                                      {item.Value}
                                    </option>
                                  )
                                })}
                              </Input>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="1" className="text-sm-end">
                        <Button
                          color="primary"
                          className="btn btn-success btn-sm "
                          type="button"
                          onClick={e => {
                            e.preventDefault()
                            productDetailsValidation.handleSubmit()
                            return false
                          }}
                          style={{ marginTop: "21px", padding: "4px 14px" }}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>

                    <Row className="mt-2 mb-2">
                      <Col md="12" className="text-end ">
                        <TableListProduct
                          columns={columns}
                          data={tableData}
                          isGlobalFilter={false}
                          isCreateBooking={false}
                          customPageSize={10}
                          allowSalesmanDisplay={allowSalesmanDisplay == true ? true : false}
                          className="custom-header-css"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={9}>
                        <Row>
                          <Col md={7}>
                            <div className="mb-2">
                              <Label htmlFor="validationCustom05">Remark</Label>
                              <Input
                                name="remark"
                                rows="2"
                                placeholder="Type your remark here"
                                type="textarea"
                                className="form-control"
                                value={validation.values.remark || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                            </div>
                          </Col>

                          <Col md={5} style={{ fontSize: "14px", textAlign: "-webkit-right" }}>
                            <table className="">
                              <tr style={{ textAlign: "end" }}>
                                <th className="gst-amounts">
                                  <Label>
                                    CGST Amt.
                                  </Label>
                                </th>
                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                  {new Intl.NumberFormat('en-IN', {
                                    currency: 'INR',
                                    minimumFractionDigits: 2,
                                  }).format(totalCGSTAmount)}
                                </th>
                              </tr>
                              <tr style={{ textAlign: "end" }}>
                                <th className="gst-amounts"> <Label>
                                  SGST Amt.
                                </Label></th>
                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                  {new Intl.NumberFormat('en-IN', {
                                    currency: 'INR',
                                    minimumFractionDigits: 2,
                                  }).format(totalSGSTAmount)}
                                </th>
                              </tr>
                              <tr style={{ textAlign: "end" }}>
                                <th className="gst-amounts"> <Label>
                                  IGST Amt.
                                </Label></th>
                                <th className="gst-amounts3">  <i className="mdi mdi-currency-inr p-0"></i>
                                  {new Intl.NumberFormat('en-IN', {
                                    currency: 'INR',
                                    minimumFractionDigits: 2,
                                  }).format(totalIGSTAmount)}
                                </th>
                              </tr>
                            </table>
                            <Row style={{ display: "flex", alignContent: "end", justifyContent: "end", marginTop: "7px" }}>
                              <Col md={6}>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                  <div>
                                    <Label
                                      htmlFor="validationCustom01"
                                      className="form-label"
                                      style={{ display: "flex", alignItems: "center", textAlign: "center", marginBottom: "0px !important" }}
                                    >
                                      Advance
                                    </Label>
                                  </div>
                                  <div>
                                    <Input
                                      name="Advance"
                                      // disabled={isEdit}
                                      className="form-control-sm"
                                      placeholder="0.00"
                                      type="text"
                                      onChange={e => {
                                        let inputValue = e.target.value;
                                        if (isNaN(inputValue)) {
                                          inputValue = 0;
                                        } else if (inputValue < 0) {
                                          inputValue = 0;
                                        }
                                        handleAdvanceChange(inputValue)
                                      }}
                                      onBlur={validation.handleBlur}
                                      onFocus={(e) => { e.target.select() }}
                                      value={validation.values.Advance}
                                      onKeyPress={handleKeyPress}
                                      invalid={
                                        validation.touched.Advance &&
                                          validation.errors.Advance
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.Advance &&
                                      validation.errors.Advance ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.Advance}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>

                              </Col>
                            </Row>
                            <Row style={{ display: "flex", alignContent: "end", justifyContent: "end", marginTop: "7px" }}>
                              <Col md={6}>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                  <div>
                                    <Label
                                      htmlFor="validationCustom01"
                                      className="form-label"
                                      style={{ display: "flex", alignItems: "center", textAlign: "center", marginBottom: "0px !important" }}
                                    >
                                      Discount
                                    </Label>
                                  </div>
                                  <div>
                                    <Input
                                      name="discount"
                                      className="form-control-sm"
                                      placeholder="0.00"
                                      type="text"
                                      onFocus={(e) => { e.target.select() }}
                                      onChange={e => {
                                        let inputValue = e.target.value;
                                        if (isNaN(inputValue)) {
                                          inputValue = 0;
                                        } else if (inputValue < 0) {
                                          inputValue = 0;
                                        }
                                        handleDiscountChange(inputValue)
                                      }}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.discount}
                                      onKeyPress={handleKeyPress}
                                      invalid={
                                        validation.touched.discount &&
                                          validation.errors.discount
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.discount &&
                                      validation.errors.discount ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.discount}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row style={{ display: "flex", alignContent: "end", justifyContent: "end", marginTop: "7px" }}>
                              <Col md={6}>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                  <div>
                                    <Label
                                      htmlFor="validationCustom01"
                                      className="form-label"
                                      style={{ display: "flex", alignItems: "center", textAlign: "center", marginBottom: "0px !important" }}
                                    >
                                      Deposit
                                    </Label>
                                  </div>
                                  <div style={{ paddingLeft: "8px" }}>
                                    <Input
                                      name="displayDeposit"
                                      disabled={isEdit}
                                      className="form-control-sm"
                                      placeholder="0.00"
                                      type="text"
                                      onFocus={(e) => { e.target.select() }}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.displayDeposit}
                                      onKeyPress={handleKeyPress}
                                      invalid={
                                        validation.touched.displayDeposit &&
                                          validation.errors.displayDeposit
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.displayDeposit &&
                                      validation.errors.displayDeposit ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.displayDeposit}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                        </Row>
                      </Col>
                      <Col md={3} style={{ fontSize: "14px", textAlign: "-webkit-right" }} >
                        <table className="text-end">
                          <tr style={{ textAlign: "end" }}>
                            <th className="gst-amounts">
                              <Label>
                                Taxable Amt.
                              </Label>
                            </th>
                            <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                              {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                              }).format(totalTaxableRent)}
                            </th>
                          </tr>
                          <tr style={{ textAlign: "end" }}>
                            <th className="gst-amounts">
                              <Label>
                                Taxes(+)
                              </Label>
                            </th>
                            <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                              {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                              }).format(totalTexes)}
                            </th>
                          </tr>
                          <tr style={{ textAlign: "end" }}>
                            <th className="gst-amounts">
                              <Label>
                                Net Amt.
                              </Label></th>
                            <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                              {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                              }).format(Math.round(netAmount))}
                            </th>
                          </tr>
                          <tr style={{ textAlign: "end" }}>
                            <th className="gst-amounts">
                              <Label>
                                Discount(-)
                              </Label></th>
                            <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                              {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                              }).format(discount)}
                            </th>
                          </tr>

                          <tr style={{ textAlign: "end" }}>
                            <th className="gst-amounts">
                              <Label>
                                Advance(-)
                              </Label></th>
                            <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                              {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                              }).format(Math.round(advance))}
                            </th>
                          </tr>
                          <tr style={{ textAlign: "end" }}>
                            <th className="gst-amounts">
                              <Label>
                                Payable Amt.
                              </Label></th>
                            <th className="gst-amounts2">  <i className="mdi mdi-currency-inr p-0"></i>
                              {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                              }).format(Math.round(netAmount) - discount - advance)}
                            </th>
                          </tr>
                        </table>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="d-flex justify-content-end">
                        <Row>
                          <div md={12} style={{ alignItems: "center", display: "flex", gap: "18px" }} className="d-flex justify-content-end text-center">

                            {!isEdit && <div className="mt-2" style={{ display: "flex", gap: "1px", alignItems: "end", marginRight: "7px" }}>
                              <FormGroup check>
                                <Input
                                  disabled={isEdit}
                                  name="Approved"
                                  id="OnApproved"
                                  type="checkbox"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  defaultChecked={validation.values.Approved}
                                />
                              </FormGroup>
                              <Label style={{ marginTop: "5px" }} for="OnApproved" check>
                                On Approved
                              </Label>
                            </div>}

                            < div className="mt-3">
                              <div className=" d-flex gap-1">
                                <input
                                  type="radio"
                                  value="1"
                                  // disabled={isEdit}
                                  id="cash"
                                  name="mode"
                                  className="form-check-input"
                                  checked={paymentMethod === "1"}
                                  onClick={() => { setpaymentMethod("1") }}
                                />
                                <Label style={{ marginRight: "5px", marginTop: "1px" }} className="form-check-label font-size-13" htmlFor="cash">
                                  Cash
                                </Label>

                                <input
                                  type="radio"
                                  value="2"
                                  // disabled={isEdit}
                                  id="bank"
                                  name="mode"
                                  className="form-check-input"
                                  checked={paymentMethod === "2"}
                                  onClick={() => { setpaymentMethod("2") }}
                                />
                                <Label className="form-check-label font-size-13" htmlFor="bank">
                                  Bank
                                </Label>
                              </div>
                            </div>
                            <div className="" style={{ marginTop: "20px" }}>
                              <Button
                                color="secondary"
                                className="btn btn-primary me-2"
                                type="button"
                                onClick={() => {
                                  {
                                    isCart
                                      ? navigate(routes.cartlist)
                                      : navigate(routes.bookingList)
                                  }
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className="" style={{ marginTop: "20px" }}>
                              {allowPrintButton && isCart ? (
                                <Button
                                  color="primary"
                                  className="btn btn-primary"
                                  type="submit"
                                  onClick={() => {
                                    setFlag(true)
                                  }}
                                >
                                  Save & Print
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  className="btn btn-primary me-2"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              )
                              }
                            </div>
                          </div>

                        </Row>
                      </Col>
                    </Row>
                  </Form>

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    centered={true}
                    size="small"
                    style={{ width: isMobile ? "300px" : "500px" }}
                  >
                    <div className="modal-content">
                      <ModalHeader
                        className="text-centre"
                        toggle={toggle}
                        tag="h5"
                      >
                        Product Note
                      </ModalHeader>
                      <ModalBody>
                        <Row className="pe-0">
                          {fields.map(({ FieldName, Id, Value }, index) => (
                            <Col md={6} key={index}>
                              <div className="mb-2">
                                <Label>{FieldName}</Label>
                                <Input
                                  type="text"
                                  id="name"
                                  name="untyped-input"
                                  className="form-control-sm"
                                  placeholder={FieldName}
                                  value={Value}
                                  onChange={(e) => handleChangeSubCategories(index, e)}
                                />
                              </div>
                            </Col>
                          ))}
                        </Row>
                        <Row>
                          <div className="mb-2">
                            <Label htmlFor="validationCustom05">Remark</Label>
                            <Input
                              autoFocus
                              name="productRemark"
                              rows="2"
                              placeholder="Type your remark here"
                              type="textarea"
                              className="form-control"
                              defaultValue={productNote}
                              onChange={e => setProductNote(e.target.value)}
                            />
                          </div>
                          <div className="text-end">
                            <button
                              color="primary"
                              className="btn btn-primary btn-sm "
                              type="button"
                              onClick={() => handleRemarkAdd()}
                            >
                              Save
                            </button>
                          </div>
                        </Row>
                      </ModalBody>
                    </div>
                  </Modal>

                  <Modal isOpen={modal1} toggle={toggle1} centered={true}>
                    <div className="modal-content ">
                      <ModalHeader
                        className="text-centre"
                        toggle={toggle1}
                        tag="h5"
                      >
                        Selected Items for this date
                      </ModalHeader>
                      <ModalBody>
                        <TableList
                          columns={columns1}
                          data={cartList}
                          customPageSize={10}
                          className="custom-header-css"
                        />
                      </ModalBody>
                      <Row>
                        <Col
                          md="12"
                          className="text-end"
                          style={{ marginBottom: "5px" }}
                        >
                          <Button
                            color="secondary"
                            className="btn btn-primary btn-sm me-2"
                            type="button"
                            onClick={() => toggle1()}
                          >
                            Cancle
                          </Button>
                          <Button
                            color="primary"
                            className="btn btn-primary btn-sm me-2"
                            type="submit"
                            onClick={e => {
                              e.preventDefault()
                              handaleConfirm()
                              productDetailsValidation.handleSubmit()
                              return false
                            }}


                          // onClick={() => handaleConfirm()
                          //   addtoCartValidation.handleSubmit()}
                          >
                            Confirm
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Modal>

                  <Modal isOpen={modal3} toggle={toggle3} centered={true}>
                    <div className="modal-content ">
                      <ModalHeader className="text-centre" toggle={toggle3} tag="h5">
                        Product already in Booking
                      </ModalHeader>
                      <ModalBody>
                        <TableList
                          columns={columns3}
                          data={bookingAvailabilityList}
                          customPageSize={10}
                          className="custom-header-css"
                        />
                      </ModalBody>
                      {bookingStatus != 2 &&
                        <Row>
                          <Col
                            md="12"
                            className="text-end"
                            style={{ marginBottom: "5px" }}
                          >
                            <Button
                              color="secondary"
                              className="btn btn-primary btn-sm me-2"
                              type="button"
                              onClick={() => toggle3()}
                            >
                              Cancle
                            </Button>
                            <Button
                              color="primary"
                              className="btn btn-primary btn-sm me-2"
                              type="submit"
                              onClick={e => {
                                // e.preventDefault()
                                handaleConfirmAddProduct()
                                productDetailsValidation.handleSubmit()
                                return false
                              }}
                            >
                              Confirm
                            </Button>
                          </Col>
                        </Row>}

                    </div>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  )
}

export default CreateBooking
