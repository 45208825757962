import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Card,
    CardBody,
    Modal,
    ModalBody,
    Input,
    ModalHeader,
} from "reactstrap"
import { dateFormat } from "pages/Common"
import toast from "react-hot-toast"
import breadcrumbs from "../../../common/Breadcrumbs"
import { useLocation } from "react-router-dom"
import useApi from "common/ApiMiddlewere"
import InvoiceDetalis from "pages/Invoices/InvoiceDetalis"
import GetObjectsByValues from "_mock/Static_data"
import DepositProductView from "./DepositProductView"
import DepositTable from "./DepositTable"
import { useSelector } from "react-redux"


function DepositReport() {
    document.title = `Deposit | ${localStorage.getItem("ShopName")}`
    const { postApi } = useApi()
    let { pathname } = useLocation()

    GetObjectsByValues(1, 2, 3, 4, 5)

    // useStates
    const [tableData, setTableData] = useState([])
    const [modal, setModal] = useState(false)
    const [modal1, setModal1] = useState(false)
    const [productViewId, setProductViewId] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [TotalRecords, setTotalRecords] = useState(0)
    const [TotalReceive, setTotalReceive] = useState(0)
    const [TotalReturn, setTotalReturn] = useState(0)
    const [date, setdate] = useState([new Date(), new Date()])
    const [BookingIdandStatusId, setBookingIdandStatusId] = useState({ bookingId: "", statusId: "", productId: "" })
    const [pageSize, setpageSize] = useState()
    const [displayStart, setDisplayStart] = useState(1)
    const [searchText, setSearchText] = useState("")

    const pagePerSize = (pageSize, pagePerIndex, serchValue) => {
        setDisplayStart(pagePerIndex * pageSize - pageSize + 1)
        setSearchText(serchValue == undefined ? "" : serchValue)
        setRowsPerPage(pageSize)
    }


    // handlers
    const toggle = () => {
        setModal(!modal)
    }
    // handlers
    const toggle1 = () => {
        setModal1(!modal1)
    }
    const depositReportApi = () => {
        setIsLoading(true)
        postApi("Report/deposit-report", {
            fromDate: date[0] ? dateFormat(
                date[0],
                "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            toDate: date[1] ? dateFormat(
                date[1],
                "yyyy-MM-dd"
            ) : new Date().toISOString().slice(0, 10),
            displayStart: displayStart,
            pageSize: rowsPerPage,
            searchText: searchText.trim(),
            sortColumn: "",
            sortOrder: "",
        }).then(({ data, status }) => {
            if (status === 200) {
                setIsLoading(false)
                setTableData(data.DepositList)
                setTotalRecords(data.TotalRecords)
                setTotalReceive(data.TotalReceive)
                setTotalReturn(data.TotalReturn)
            } else {
                setIsLoading(false)
                toast.error(data, {
                    style: {
                        fontSize: "15px",
                    },
                })
            }
        })
    }
    // booking list API useEffect
    useEffect(() => {
        if (date[1] != undefined) {
            depositReportApi()
        }
    }, [
        date,
        rowsPerPage,
        searchText,
        displayStart,
        pageSize,
    ])
    // view booking
    const handleViewBooking = bookingId => {
        setBookingIdandStatusId({ ...BookingIdandStatusId, bookingId: bookingId })
        toggle1()
    }

    // Booking table header & accessor
    const columns = useMemo(
        () => [
            {
                Header: "Bill No.",
                accessor: "BillNo",
                width: "4%",
                Cell: ({ row }) => {

                    return <>
                        {row.original.TotalProduct == 0 ? (
                            <div style={{ color: "#E94560" }} >{row.original.BillNo}</div>
                        ) : (
                            <div
                                style={{ cursor: "pointer", color: "#556ee6" }}
                                onClick={() => {
                                    handleViewBooking(row.original.Id)
                                }}
                            >
                                {row.original.BillNo}
                            </div>
                        )}
                    </>
                },
            },
            {
                Header: "Name",
                accessor: "Name",
            },
            {
                Header: "Customer No.",
                accessor: "CustomerNo",
                width: "6%",
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Rent
                    </div>
                ),
                accessor: "Rent",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Rent || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Advance
                    </div>
                ),
                accessor: "Advance",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.Advance || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Receive Deposit
                    </div>
                ),
                accessor: "ReceiveDeposit",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.ReceiveDeposit || 0)}
                        </div>
                    )
                },
            },
            {
                Header: () => (
                    <div style={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                        textAlign: "end"
                    }}
                    >
                        Return Deposit
                    </div>
                ),
                accessor: "ReturnDeposit",
                width: "7%",
                Cell: ({ row }) => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            textAlign: "end"
                        }}
                        >
                            <i className="mdi mdi-currency-inr"></i>
                            {new Intl.NumberFormat('en-IN', {
                                currency: 'INR',
                                minimumFractionDigits: 2,
                            }).format(row?.original?.ReturnDeposit || 0)}
                        </div>
                    )
                },
            },
            {
                Header: "Receive Date",
                accessor: "ReceiveDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.ReceiveDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original?.ReceiveDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Return Date",
                accessor: "ReturnDate",
                width: "8%",
                Cell: ({ row }) => (
                    <p className=" mb-0">
                        {row.original?.ReturnDate == "0001-01-01T00:00:00"
                            ? "N/A"
                            : dateFormat(
                                row.original?.ReturnDate.split("T")[0],
                                "dd/MM/yyyy"
                            )}
                    </p>
                ),
            },
            {
                Header: "Status",
                width: "8%",
                // width: 100,
                accessor: "Status",
                Cell: ({ row }) => {
                    return (
                        <span>
                            {row.original.Status == 1 ? "Booked" : row.original.Status == 2 ? "Delivered" : row.original.Status == 3 ? "Return" : row.original.Status == 4 ? "Cancel" : row.original.Status == 5 ? "Hold" : "Settled"}
                        </span>
                    )
                },
            },
        ],
        [tableData]
    )


    return (
        <div className="page-content background">
            <div className="container-fluid">
                {isLoading && (
                    <div className="loading-overlay is-active">
                        <span className="fas fa-spinner fa-3x fa-spin"></span>
                    </div>
                )}
                <Breadcrumbs
                    title={breadcrumbs[pathname].title}
                    breadcrumbItem={breadcrumbs[pathname].breadcrumbItem}
                />
                <Card>
                    <CardBody>

                        <DepositTable
                            columns={columns}
                            data={tableData}
                            isGlobalFilter={true}
                            customPageSize={20}
                            isLoading={isLoading}
                            pagePerSize={pagePerSize}
                            TotalRecords={TotalRecords}
                            className="custom-header-css"
                            date={date}
                            setdate={setdate}
                            TotalReceive={TotalReceive}
                            TotalReturn={TotalReturn}
                        />

                        <Modal isOpen={modal} toggle={toggle} centered={true} size="lg">
                            <div className="modal-content">
                                <ModalHeader className="text-centre" toggle={toggle} tag="h5">
                                    Products
                                </ModalHeader>
                                <ModalBody>
                                    <DepositProductView
                                        productViewId={productViewId}
                                    />
                                </ModalBody>
                            </div>
                        </Modal>

                        <InvoiceDetalis
                            toggle={toggle1}
                            modal={modal1}
                            bookingId={BookingIdandStatusId.bookingId}
                        />

                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
DepositReport.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default DepositReport